export default defineAppConfig({
  netzo: {
    favicon: '/favicon.svg',
    title: 'ESGRAF',
    description: 'Software de gestión de empresas (ERP) de ESGRAF.',
    auth: {
      allowUserRegistration: false,
    },
    preferences: {
      text: 'upper',
    },
  },
  ui: {
    primary: 'blue',
    gray: 'neutral',
  },
})
