<script setup lang="ts">
const props = defineProps<{
  type: 'info' | 'warning' | 'success' | 'error'
}>()

const alert = optionsShared.alert.find(option => option.value === props.type)!
</script>

<template>
  <UAlert
    variant="subtle"
    :color="alert.color"
    :icon="alert.icon"
    :ui="{ gap: 'gap-1', description: 'text-xs', padding: 'p-2', icon: { base: 'w-4 h-4' } }"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </UAlert>
</template>
