import revive_payload_client_4sVQNw7RlN from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/Users/mrk/Documents/esgraf-app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_65xnz9UtDI from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt-zod-i18n/dist/runtime/plugin.js";
import pdfmake_client_BrmnZIv6wW from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt-pdfmake/dist/runtime/pdfmake.client.js";
import session_client_NxDDSatxox from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import pwa_icons_plugin_iRYm12t59B from "/Users/mrk/Documents/esgraf-app/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/Users/mrk/Documents/esgraf-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import slideovers_X7whXyrIWR from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import authorization_resolver_yQMwIr7LzX from "/Users/mrk/Documents/netzo/plugins/authorization-resolver.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_65xnz9UtDI,
  pdfmake_client_BrmnZIv6wW,
  session_client_NxDDSatxox,
  pwa_icons_plugin_iRYm12t59B,
  pwa_client_ZuYw5i1i7i,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  authorization_resolver_yQMwIr7LzX
]