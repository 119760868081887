<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Pricelistitem>
  onSubmit: (event: FormSubmitEvent<Partial<Pricelistitem>>) => void
  openNestedCallback: (data: Partial<Pricelistitem>) => void
  title?: string
  disabledFields?: (keyof Pricelistitem | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Pricelistitem>>(props.data)

const pricelistitems$ = usePricelistitems()

const $pricelists = await useFetch<PricelistWithRelations[]>('/api/db/pricelists', {
  query: { id: props.data.pricelistId!, $with: relationsPricelists },
  default: () => [],
})

const parentTable = computed(() => $pricelists.data.value?.[0]?.type)
const parentLabel = computed(() => parentTable.value === 'products' ? 'Producto' : 'Servicio')
const itemTable = computed(() => parentTable.value === 'products' ? 'productitems' : 'serviceitems')
const itemRelations = computed(() => parentTable.value === 'products' ? relationsProductitems : relationsServiceitems)
const itemIdField = computed(() => parentTable.value === 'products' ? 'productitemId' : 'serviceitemId')

const $items = await useFetch<(ProductitemWithRelations | ServiceitemWithRelations)[]>(`/api/db/${itemTable.value}`, {
  query: { $with: itemRelations },
  default: () => [],
})
const $pricelistitems = await useFetch<Pricelistitem[]>('/api/db/pricelistitems', {
  query: { pricelistId: props.data.pricelistId!, $columns: ['id', itemIdField.value] },
  default: () => [],
})

const utils = usePricelistitemsUtils({ $pricelists })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.pricelistitems,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const mapPricelistitems = computed(() => toMapByKey($pricelistitems.data.value, itemIdField.value))
const filteredItems = computed(() => {
  return $items?.data.value.filter(
    item => !mapPricelistitems.value.has(item.id) || item.id === props.data[itemIdField.value],
  ) ?? []
})

const options = {
  type: parentTable.value === 'products' ? optionsProducts.type : optionsServices.type,
  itemId: computed(() => $items?.data.value.map(toOption)),
}

const maps = {
  itemId: computed(() => toMapByKey($items?.data.value, 'id')),
  itemsByParentType: computed(() =>
    parentTable.value === 'products'
      ? toMapByNestedKey(filteredItems.value, 'product', 'type')
      : toMapByNestedKey(filteredItems.value, 'service', 'type'),
  ),
}

const parent = ref<{ id?: string, type?: string } | undefined>({
  type: parentTable.value === 'products' ? 'finished-goods' : 'core',
})

if (props.data[itemIdField.value]) {
  const item = maps.itemId.value.get(props.data[itemIdField.value]!)
  if (item) {
    parent.value = {
      type: parentTable.value === 'products' ? item.product!.type : item.service!.type,
    }
  }
}

const onUpdateType = (type: string) => {
  parent.value = { type }
  state.value[itemIdField.value] = undefined
}

const loading = ref(false)

const onSubmitPricelistitem = async (event: FormSubmitEvent<Partial<Pricelistitem>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}

const schema = computed(() => ['services'].includes(parentTable.value)
  ? pricelistitems$.schemaServices
  : pricelistitems$.schemaProducts,
)
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.pricelistitems"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitPricelistitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            :label="`Tipo de ${parentLabel}`"
            name="type"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseInfo
              v-model="parent.type"
              :options="options.type"
              :disabled="[itemIdField].includes(disabledFields)"
              @update:model-value="onUpdateType"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Item de ${parentLabel}`"
            :name="itemIdField"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: itemTable,
                idField: itemIdField,
                utils: maps.itemId,
                options: maps.itemsByParentType.value.get(parent.type)?.map(toOption) ?? [],
                disabled: [itemIdField].includes(disabledFields) || !parent.type,
              }"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Precio base (${state.currency})`"
            name="basePrice"
            :required="isRequired(schema, 'basePrice')"
          >
            <InputCurrency
              v-model="state.basePrice"
              icon
              :disabled="disabledFields?.includes('basePrice')"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            :label="`Tipo de ${parentLabel}`"
            name="type"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseInfo
              v-model="parent.type"
              :options="options.type"
              :disabled="[itemIdField].includes(disabledFields)"
              @update:model-value="onUpdateType"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Item de ${parentLabel}`"
            :name="itemIdField"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: itemTable,
                idField: itemIdField,
                utils: maps.itemId,
                options: maps.itemsByParentType?.value.get(parent.type)?.map(toOption) ?? [],
                disabled: [itemIdField].includes(disabledFields) || !parent.type,
              }"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Precio base (${state.currency})`"
            name="basePrice"
            :required="isRequired(schema, 'basePrice')"
          >
            <InputCurrency
              v-model="state.basePrice"
              icon
              :disabled="disabledFields?.includes('basePrice')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="pricelistitems"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.pricelistitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
