<script setup lang="ts">
import type { ButtonSize } from '#ui/types'
import type { File } from '#netzo/shared/types/db'

const props = defineProps<{
  file: File | null | undefined
  label?: string // overrides default label
  hideLabel?: boolean
  size?: ButtonSize
  onRemove?: (file: File) => void | Promise<void> // close button shown if props.onRemove is defined
}>()

const fileProps = computed(() => getFileProps(props.file))
</script>

<template>
  <UButton
    :size="size ?? '2xs'"
    v-bind="fileProps"
    color="gray"
    :label="hideLabel ? undefined : fileProps.label"
    :title="fileProps.label"
    class="w-fit max-w-full"
  >
    <template v-if="file && !hideLabel && onRemove" #trailing>
      <UButton
        color="red"
        variant="ghost"
        :size="size"
        icon="i-mdi-close"
        class="py-0 pr-0"
        @click.stop="onRemove(file)"
      />
    </template>
  </UButton>
</template>
