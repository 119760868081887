
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as auditlogszFeKlRYO06Meta } from "/Users/mrk/Documents/netzo/pages/admin/auditlogs.vue?macro=true";
import { default as apikeysPSQayHdhX6Meta } from "/Users/mrk/Documents/netzo/pages/admin/auth/apikeys.vue?macro=true";
import { default as userssNXjkYoqKnMeta } from "/Users/mrk/Documents/netzo/pages/admin/auth/users.vue?macro=true";
import { default as issuesE9jtmosw4PMeta } from "/Users/mrk/Documents/netzo/pages/admin/issues.vue?macro=true";
import { default as assistant4fZP2ZxFzsMeta } from "/Users/mrk/Documents/netzo/pages/assistant.vue?macro=true";
import { default as forgot_45passwordizsAVHVCr2Meta } from "/Users/mrk/Documents/netzo/pages/auth/forgot-password.vue?macro=true";
import { default as login9OlnghkkmMMeta } from "/Users/mrk/Documents/netzo/pages/auth/login.vue?macro=true";
import { default as registerP2VkdKylrrMeta } from "/Users/mrk/Documents/netzo/pages/auth/register.vue?macro=true";
import { default as reset_45password7QuTZKAC41Meta } from "/Users/mrk/Documents/netzo/pages/auth/reset-password.vue?macro=true";
import { default as verify_45otpSAdxZEhC4AMeta } from "/Users/mrk/Documents/netzo/pages/auth/verify-otp.vue?macro=true";
import { default as detailsofP0P7Hz09Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/details.vue?macro=true";
import { default as files1yuUSVtdIKMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/files.vue?macro=true";
import { default as indexHbZvBeapwdMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/index.vue?macro=true";
import { default as notesr61ReaVAHvMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/notes.vue?macro=true";
import { default as tasksTLpONDB3TlMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/tasks.vue?macro=true";
import { default as _91id_93BaIFAhrMn4Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/[id].vue?macro=true";
import { default as index6UGjSVDOokMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/applications/index.vue?macro=true";
import { default as details8dSAnma5QZMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/details.vue?macro=true";
import { default as filesDj99PVkfMLMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/files.vue?macro=true";
import { default as indexTHxKnRsJx1Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/index.vue?macro=true";
import { default as notesh06COVHQqEMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/notes.vue?macro=true";
import { default as products2Dh0YjwKcBMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/products.vue?macro=true";
import { default as serviceszglNOSMW7IMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/services.vue?macro=true";
import { default as tasks8TeiqqeYs0Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/tasks.vue?macro=true";
import { default as _91id_93OngkiTxDPuMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/[id].vue?macro=true";
import { default as indexH79MLI8PsnMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/categories/index.vue?macro=true";
import { default as detailsMxmVn7xH5YMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/details.vue?macro=true";
import { default as filesA4qtjM7PlWMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/files.vue?macro=true";
import { default as indexsvA54TMw17Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/index.vue?macro=true";
import { default as notesW9emI6nfTYMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/notes.vue?macro=true";
import { default as taskskUoee5OgsMMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/tasks.vue?macro=true";
import { default as _91id_93ZuPvc2LF7GMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/[id].vue?macro=true";
import { default as index1TYqXO2ng4Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/industries/index.vue?macro=true";
import { default as detailsH2R6IcCUzFMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/details.vue?macro=true";
import { default as filesXRboznIegvMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/files.vue?macro=true";
import { default as indexigX7hZSDygMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/index.vue?macro=true";
import { default as itemsBDzqdWI3jvMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/items.vue?macro=true";
import { default as notesGzncKO0HMHMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/notes.vue?macro=true";
import { default as tasksWxlSTlVDl8Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/tasks.vue?macro=true";
import { default as _91id_931eOBat1Pb1Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id].vue?macro=true";
import { default as index4V6hqMDI5uMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/pricelists/index.vue?macro=true";
import { default as detailsI2XrZVfZkFMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/details.vue?macro=true";
import { default as filesJV0M7QkEyKMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/files.vue?macro=true";
import { default as indexTtjjc4j95SMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/index.vue?macro=true";
import { default as itemsihEyvbqOMuMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/items.vue?macro=true";
import { default as notes1bYaVrOi04Meta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/notes.vue?macro=true";
import { default as tasks9dqucjJ5jsMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/tasks.vue?macro=true";
import { default as _91id_93MkKBvXCPxhMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/[id].vue?macro=true";
import { default as indexTcGudXWvIYMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/index.vue?macro=true";
import { default as itemsiim4MyNYaXMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/products/items.vue?macro=true";
import { default as detailsMjNlWZ5yGVMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/details.vue?macro=true";
import { default as filesfd9b5BDRgMMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/files.vue?macro=true";
import { default as indexfoN3mXmZfsMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/index.vue?macro=true";
import { default as itemsan2jC7xW2KMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/items.vue?macro=true";
import { default as notesyKk2dbeRGHMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/notes.vue?macro=true";
import { default as tasksEktj1oS2VTMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/tasks.vue?macro=true";
import { default as _91id_939RbUZQbnUjMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/[id].vue?macro=true";
import { default as indexDK0mSPelNCMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/index.vue?macro=true";
import { default as itemsVTNEJ09hNzMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/services/items.vue?macro=true";
import { default as suppliersj18Aa8x8ugMeta } from "/Users/mrk/Documents/netzo/pages/catalogs/suppliers.vue?macro=true";
import { default as bankskfxO0cxyxxMeta } from "/Users/mrk/Documents/netzo/pages/finances/banks.vue?macro=true";
import { default as cfdissEd8wAPNSlMeta } from "/Users/mrk/Documents/netzo/pages/finances/cfdis.vue?macro=true";
import { default as financialaccountsAPrhr8RPicMeta } from "/Users/mrk/Documents/netzo/pages/finances/financialaccounts.vue?macro=true";
import { default as reconciliationsyQy7W4aE5tMeta } from "/Users/mrk/Documents/netzo/pages/finances/reconciliations.vue?macro=true";
import { default as transactionsMZHxpYiMtCMeta } from "/Users/mrk/Documents/netzo/pages/finances/transactions.vue?macro=true";
import { default as uploadGU4F3DOzcbMeta } from "/Users/mrk/Documents/netzo/pages/finances/upload.vue?macro=true";
import { default as indexxTcn2FmFupMeta } from "/Users/mrk/Documents/netzo/pages/index.vue?macro=true";
import { default as catalogslIvQqNLUA1Meta } from "/Users/mrk/Documents/netzo/pages/management/dashboards/catalogs.vue?macro=true";
import { default as financesaHL4FKiRGFMeta } from "/Users/mrk/Documents/netzo/pages/management/dashboards/finances.vue?macro=true";
import { default as productioniyL4dLBV8wMeta } from "/Users/mrk/Documents/netzo/pages/management/dashboards/production.vue?macro=true";
import { default as salesur0w59GLdOMeta } from "/Users/mrk/Documents/netzo/pages/management/dashboards/sales.vue?macro=true";
import { default as notificationsTTYoHAG26JMeta } from "/Users/mrk/Documents/netzo/pages/notifications.vue?macro=true";
import { default as detailsQvgezyp12mMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/details.vue?macro=true";
import { default as filesZUEPs5LIjEMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/files.vue?macro=true";
import { default as indexE2cbYJvTwnMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/index.vue?macro=true";
import { default as itemsknwsz1kAyDMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/items.vue?macro=true";
import { default as notesJCcIZqbWC0Meta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/notes.vue?macro=true";
import { default as tasksyB2xsszLeYMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/tasks.vue?macro=true";
import { default as _91id_93XJDLKzSyzlMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/[id].vue?macro=true";
import { default as indextLsyNDPFTnMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/index.vue?macro=true";
import { default as itemsRDt0IShIInMeta } from "/Users/mrk/Documents/netzo/pages/production/productionorders/items.vue?macro=true";
import { default as activitieselk5P5v4OvMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/activities.vue?macro=true";
import { default as branchesMjqSdMoaLJMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/branches.vue?macro=true";
import { default as contactsIITClMuxVhMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/contacts.vue?macro=true";
import { default as deals5sRxDy1ObqMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/deals.vue?macro=true";
import { default as detailsm2V7ufG6D8Meta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/details.vue?macro=true";
import { default as filesrWRn2NhCAoMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/files.vue?macro=true";
import { default as indexPDPxcBHP9hMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/index.vue?macro=true";
import { default as notes95iGUc3nuqMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/notes.vue?macro=true";
import { default as productionordersNbyeAW2TywMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/productionorders.vue?macro=true";
import { default as quotespvK7PGZUtOMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/quotes.vue?macro=true";
import { default as salesordersZvEuhmy95KMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/salesorders.vue?macro=true";
import { default as tasksi2XjkwBPQKMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/tasks.vue?macro=true";
import { default as _91id_93615O1cu8VcMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/[id].vue?macro=true";
import { default as indexxYvg7wQHwkMeta } from "/Users/mrk/Documents/netzo/pages/sales/accounts/index.vue?macro=true";
import { default as activities8GvyMdEgDOMeta } from "/Users/mrk/Documents/netzo/pages/sales/activities.vue?macro=true";
import { default as branchesBKEL0bqxKaMeta } from "/Users/mrk/Documents/netzo/pages/sales/branches.vue?macro=true";
import { default as deals6KWP2milDyMeta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/deals.vue?macro=true";
import { default as detailsjksOFOcy33Meta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/details.vue?macro=true";
import { default as files60kSnUcROtMeta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/files.vue?macro=true";
import { default as index2jArJIrBcZMeta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/index.vue?macro=true";
import { default as notesLV2VslWWA9Meta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/notes.vue?macro=true";
import { default as tasksi6wRDyLSY7Meta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/tasks.vue?macro=true";
import { default as _91id_93E0h15bhyBNMeta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/[id].vue?macro=true";
import { default as indexSSQG6aKV54Meta } from "/Users/mrk/Documents/netzo/pages/sales/campaigns/index.vue?macro=true";
import { default as contacts8whgu9XjDFMeta } from "/Users/mrk/Documents/netzo/pages/sales/contacts.vue?macro=true";
import { default as activitiesKmlmM1QT9gMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/activities.vue?macro=true";
import { default as detailsRX2eqXxAPdMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/details.vue?macro=true";
import { default as filesd6bBfXalc8Meta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/files.vue?macro=true";
import { default as indexFzpCTP98reMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/index.vue?macro=true";
import { default as notes0fYbcmcibrMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/notes.vue?macro=true";
import { default as quotes20WT2HsNglMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/quotes.vue?macro=true";
import { default as salesorderswXi4ejR90sMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/salesorders.vue?macro=true";
import { default as tasksaqz5JIJwFdMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id]/tasks.vue?macro=true";
import { default as _91id_936Y5ktKRiNtMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/[id].vue?macro=true";
import { default as index7EECCX60gbMeta } from "/Users/mrk/Documents/netzo/pages/sales/deals/index.vue?macro=true";
import { default as detailsRlrbMs2dDEMeta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/details.vue?macro=true";
import { default as files20CQ6Ih16xMeta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/files.vue?macro=true";
import { default as indexPUuN3MQi39Meta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/index.vue?macro=true";
import { default as itemsCYrow49f4cMeta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/items.vue?macro=true";
import { default as notesq71wfh51L2Meta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/notes.vue?macro=true";
import { default as salesorders1I0CMIU1s1Meta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/salesorders.vue?macro=true";
import { default as tasksTYu1n7AuH5Meta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/tasks.vue?macro=true";
import { default as _91id_93N8eQk7yQL9Meta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/[id].vue?macro=true";
import { default as indexxadQ9QY5PKMeta } from "/Users/mrk/Documents/netzo/pages/sales/quotes/index.vue?macro=true";
import { default as detailsWKmLmH2vJzMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/details.vue?macro=true";
import { default as files2ZFdjJC4FJMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/files.vue?macro=true";
import { default as indexpCFNSLABwsMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/index.vue?macro=true";
import { default as itemsd1drt9069yMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/items.vue?macro=true";
import { default as notesZjWT7NDRh1Meta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/notes.vue?macro=true";
import { default as tasksi94ICL7aGCMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/tasks.vue?macro=true";
import { default as _91id_93iPT5rfC8NwMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/[id].vue?macro=true";
import { default as indexWwRhCOFHdiMeta } from "/Users/mrk/Documents/netzo/pages/sales/salesorders/index.vue?macro=true";
import { default as indexqDKeBIe86sMeta } from "/Users/mrk/Documents/netzo/pages/settings/index.vue?macro=true";
import { default as notificationsij8pe5gIkeMeta } from "/Users/mrk/Documents/netzo/pages/settings/notifications.vue?macro=true";
import { default as settings6CgcDuluJIMeta } from "/Users/mrk/Documents/netzo/pages/settings.vue?macro=true";
import { default as tasksTvPuUiLVMIMeta } from "/Users/mrk/Documents/netzo/pages/tasks.vue?macro=true";
import { default as component_45stubxZIXDbmezcMeta } from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubxZIXDbmezc } from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-auditlogs",
    path: "/admin/auditlogs",
    component: () => import("/Users/mrk/Documents/netzo/pages/admin/auditlogs.vue")
  },
  {
    name: "admin-auth-apikeys",
    path: "/admin/auth/apikeys",
    component: () => import("/Users/mrk/Documents/netzo/pages/admin/auth/apikeys.vue")
  },
  {
    name: "admin-auth-users",
    path: "/admin/auth/users",
    component: () => import("/Users/mrk/Documents/netzo/pages/admin/auth/users.vue")
  },
  {
    name: "admin-issues",
    path: "/admin/issues",
    component: () => import("/Users/mrk/Documents/netzo/pages/admin/issues.vue")
  },
  {
    name: "assistant",
    path: "/assistant",
    component: () => import("/Users/mrk/Documents/netzo/pages/assistant.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordizsAVHVCr2Meta || {},
    component: () => import("/Users/mrk/Documents/netzo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login9OlnghkkmMMeta || {},
    component: () => import("/Users/mrk/Documents/netzo/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerP2VkdKylrrMeta || {},
    component: () => import("/Users/mrk/Documents/netzo/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45password7QuTZKAC41Meta || {},
    component: () => import("/Users/mrk/Documents/netzo/pages/auth/reset-password.vue")
  },
  {
    name: "auth-verify-otp",
    path: "/auth/verify-otp",
    meta: verify_45otpSAdxZEhC4AMeta || {},
    component: () => import("/Users/mrk/Documents/netzo/pages/auth/verify-otp.vue")
  },
  {
    name: _91id_93BaIFAhrMn4Meta?.name,
    path: "/catalogs/applications/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id].vue"),
    children: [
  {
    name: "catalogs-applications-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/details.vue")
  },
  {
    name: "catalogs-applications-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/files.vue")
  },
  {
    name: "catalogs-applications-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/index.vue")
  },
  {
    name: "catalogs-applications-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/notes.vue")
  },
  {
    name: "catalogs-applications-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-applications",
    path: "/catalogs/applications",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/applications/index.vue")
  },
  {
    name: _91id_93OngkiTxDPuMeta?.name,
    path: "/catalogs/categories/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id].vue"),
    children: [
  {
    name: "catalogs-categories-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/details.vue")
  },
  {
    name: "catalogs-categories-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/files.vue")
  },
  {
    name: "catalogs-categories-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/index.vue")
  },
  {
    name: "catalogs-categories-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/notes.vue")
  },
  {
    name: "catalogs-categories-id-products",
    path: "products",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/products.vue")
  },
  {
    name: "catalogs-categories-id-services",
    path: "services",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/services.vue")
  },
  {
    name: "catalogs-categories-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-categories",
    path: "/catalogs/categories",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/categories/index.vue")
  },
  {
    name: _91id_93ZuPvc2LF7GMeta?.name,
    path: "/catalogs/industries/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id].vue"),
    children: [
  {
    name: "catalogs-industries-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/details.vue")
  },
  {
    name: "catalogs-industries-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/files.vue")
  },
  {
    name: "catalogs-industries-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/index.vue")
  },
  {
    name: "catalogs-industries-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/notes.vue")
  },
  {
    name: "catalogs-industries-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-industries",
    path: "/catalogs/industries",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/industries/index.vue")
  },
  {
    name: _91id_931eOBat1Pb1Meta?.name,
    path: "/catalogs/pricelists/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id].vue"),
    children: [
  {
    name: "catalogs-pricelists-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/details.vue")
  },
  {
    name: "catalogs-pricelists-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/files.vue")
  },
  {
    name: "catalogs-pricelists-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/index.vue")
  },
  {
    name: "catalogs-pricelists-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/items.vue")
  },
  {
    name: "catalogs-pricelists-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/notes.vue")
  },
  {
    name: "catalogs-pricelists-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-pricelists",
    path: "/catalogs/pricelists",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/pricelists/index.vue")
  },
  {
    name: _91id_93MkKBvXCPxhMeta?.name,
    path: "/catalogs/products/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id].vue"),
    children: [
  {
    name: "catalogs-products-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/details.vue")
  },
  {
    name: "catalogs-products-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/files.vue")
  },
  {
    name: "catalogs-products-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/index.vue")
  },
  {
    name: "catalogs-products-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/items.vue")
  },
  {
    name: "catalogs-products-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/notes.vue")
  },
  {
    name: "catalogs-products-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-products",
    path: "/catalogs/products",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/index.vue")
  },
  {
    name: "catalogs-products-items",
    path: "/catalogs/products/items",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/products/items.vue")
  },
  {
    name: _91id_939RbUZQbnUjMeta?.name,
    path: "/catalogs/services/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id].vue"),
    children: [
  {
    name: "catalogs-services-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/details.vue")
  },
  {
    name: "catalogs-services-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/files.vue")
  },
  {
    name: "catalogs-services-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/index.vue")
  },
  {
    name: "catalogs-services-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/items.vue")
  },
  {
    name: "catalogs-services-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/notes.vue")
  },
  {
    name: "catalogs-services-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-services",
    path: "/catalogs/services",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/index.vue")
  },
  {
    name: "catalogs-services-items",
    path: "/catalogs/services/items",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/services/items.vue")
  },
  {
    name: "catalogs-suppliers",
    path: "/catalogs/suppliers",
    component: () => import("/Users/mrk/Documents/netzo/pages/catalogs/suppliers.vue")
  },
  {
    name: "finances-banks",
    path: "/finances/banks",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/banks.vue")
  },
  {
    name: "finances-cfdis",
    path: "/finances/cfdis",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/cfdis.vue")
  },
  {
    name: "finances-financialaccounts",
    path: "/finances/financialaccounts",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/financialaccounts.vue")
  },
  {
    name: "finances-reconciliations",
    path: "/finances/reconciliations",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/reconciliations.vue")
  },
  {
    name: "finances-transactions",
    path: "/finances/transactions",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/transactions.vue")
  },
  {
    name: "finances-upload",
    path: "/finances/upload",
    component: () => import("/Users/mrk/Documents/netzo/pages/finances/upload.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/mrk/Documents/netzo/pages/index.vue")
  },
  {
    name: "management-dashboards-catalogs",
    path: "/management/dashboards/catalogs",
    component: () => import("/Users/mrk/Documents/netzo/pages/management/dashboards/catalogs.vue")
  },
  {
    name: "management-dashboards-finances",
    path: "/management/dashboards/finances",
    component: () => import("/Users/mrk/Documents/netzo/pages/management/dashboards/finances.vue")
  },
  {
    name: "management-dashboards-production",
    path: "/management/dashboards/production",
    component: () => import("/Users/mrk/Documents/netzo/pages/management/dashboards/production.vue")
  },
  {
    name: "management-dashboards-sales",
    path: "/management/dashboards/sales",
    component: () => import("/Users/mrk/Documents/netzo/pages/management/dashboards/sales.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/Users/mrk/Documents/netzo/pages/notifications.vue")
  },
  {
    name: _91id_93XJDLKzSyzlMeta?.name,
    path: "/production/productionorders/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id].vue"),
    children: [
  {
    name: "production-productionorders-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/details.vue")
  },
  {
    name: "production-productionorders-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/files.vue")
  },
  {
    name: "production-productionorders-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/index.vue")
  },
  {
    name: "production-productionorders-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/items.vue")
  },
  {
    name: "production-productionorders-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/notes.vue")
  },
  {
    name: "production-productionorders-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/[id]/tasks.vue")
  }
]
  },
  {
    name: "production-productionorders",
    path: "/production/productionorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/index.vue")
  },
  {
    name: "production-productionorders-items",
    path: "/production/productionorders/items",
    component: () => import("/Users/mrk/Documents/netzo/pages/production/productionorders/items.vue")
  },
  {
    name: _91id_93615O1cu8VcMeta?.name,
    path: "/sales/accounts/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id].vue"),
    children: [
  {
    name: "sales-accounts-id-activities",
    path: "activities",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/activities.vue")
  },
  {
    name: "sales-accounts-id-branches",
    path: "branches",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/branches.vue")
  },
  {
    name: "sales-accounts-id-contacts",
    path: "contacts",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/contacts.vue")
  },
  {
    name: "sales-accounts-id-deals",
    path: "deals",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/deals.vue")
  },
  {
    name: "sales-accounts-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/details.vue")
  },
  {
    name: "sales-accounts-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/files.vue")
  },
  {
    name: "sales-accounts-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/index.vue")
  },
  {
    name: "sales-accounts-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/notes.vue")
  },
  {
    name: "sales-accounts-id-productionorders",
    path: "productionorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/productionorders.vue")
  },
  {
    name: "sales-accounts-id-quotes",
    path: "quotes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/quotes.vue")
  },
  {
    name: "sales-accounts-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/salesorders.vue")
  },
  {
    name: "sales-accounts-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-accounts",
    path: "/sales/accounts",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/accounts/index.vue")
  },
  {
    name: "sales-activities",
    path: "/sales/activities",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/activities.vue")
  },
  {
    name: "sales-branches",
    path: "/sales/branches",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/branches.vue")
  },
  {
    name: _91id_93E0h15bhyBNMeta?.name,
    path: "/sales/campaigns/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id].vue"),
    children: [
  {
    name: "sales-campaigns-id-deals",
    path: "deals",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/deals.vue")
  },
  {
    name: "sales-campaigns-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/details.vue")
  },
  {
    name: "sales-campaigns-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/files.vue")
  },
  {
    name: "sales-campaigns-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/index.vue")
  },
  {
    name: "sales-campaigns-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/notes.vue")
  },
  {
    name: "sales-campaigns-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-campaigns",
    path: "/sales/campaigns",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/campaigns/index.vue")
  },
  {
    name: "sales-contacts",
    path: "/sales/contacts",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/contacts.vue")
  },
  {
    name: _91id_936Y5ktKRiNtMeta?.name,
    path: "/sales/deals/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id].vue"),
    children: [
  {
    name: "sales-deals-id-activities",
    path: "activities",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/activities.vue")
  },
  {
    name: "sales-deals-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/details.vue")
  },
  {
    name: "sales-deals-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/files.vue")
  },
  {
    name: "sales-deals-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/index.vue")
  },
  {
    name: "sales-deals-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/notes.vue")
  },
  {
    name: "sales-deals-id-quotes",
    path: "quotes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/quotes.vue")
  },
  {
    name: "sales-deals-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/salesorders.vue")
  },
  {
    name: "sales-deals-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-deals",
    path: "/sales/deals",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/deals/index.vue")
  },
  {
    name: _91id_93N8eQk7yQL9Meta?.name,
    path: "/sales/quotes/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id].vue"),
    children: [
  {
    name: "sales-quotes-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/details.vue")
  },
  {
    name: "sales-quotes-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/files.vue")
  },
  {
    name: "sales-quotes-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/index.vue")
  },
  {
    name: "sales-quotes-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/items.vue")
  },
  {
    name: "sales-quotes-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/notes.vue")
  },
  {
    name: "sales-quotes-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/salesorders.vue")
  },
  {
    name: "sales-quotes-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-quotes",
    path: "/sales/quotes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/quotes/index.vue")
  },
  {
    name: _91id_93iPT5rfC8NwMeta?.name,
    path: "/sales/salesorders/:id()",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id].vue"),
    children: [
  {
    name: "sales-salesorders-id-details",
    path: "details",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/details.vue")
  },
  {
    name: "sales-salesorders-id-files",
    path: "files",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/files.vue")
  },
  {
    name: "sales-salesorders-id",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/index.vue")
  },
  {
    name: "sales-salesorders-id-items",
    path: "items",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/items.vue")
  },
  {
    name: "sales-salesorders-id-notes",
    path: "notes",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/notes.vue")
  },
  {
    name: "sales-salesorders-id-tasks",
    path: "tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-salesorders",
    path: "/sales/salesorders",
    component: () => import("/Users/mrk/Documents/netzo/pages/sales/salesorders/index.vue")
  },
  {
    name: settings6CgcDuluJIMeta?.name,
    path: "/settings",
    component: () => import("/Users/mrk/Documents/netzo/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/Users/mrk/Documents/netzo/pages/settings/index.vue")
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/Users/mrk/Documents/netzo/pages/settings/notifications.vue")
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/Users/mrk/Documents/netzo/pages/tasks.vue")
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/auth",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/admin",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/management",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/catalogs",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/finances",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/production",
    component: component_45stubxZIXDbmezc
  },
  {
    name: component_45stubxZIXDbmezcMeta?.name,
    path: "/sales",
    component: component_45stubxZIXDbmezc
  }
]