import type { FormErrorEvent } from '#ui/types'
import { useClipboard } from '@vueuse/core'

export const open = (url: string, target = '_blank') => window?.open(url, target)

/**
 * WORKAROUND: this is a hack to close the modal and slideover before
 * opening another one since nested modals/slideovers are not supported
 * in @nuxt/ui v2 due to a current limitation with HeadlessUI Dialog,
 * TODO: remove this utility once nested modals/slideovers supported in v3
 */
export const openNested = async (callback: () => void) => {
  useModal().close() // either
  useSlideover().close() // or
  await new Promise(res => setTimeout(res, 900)) // wait at least closing transition duration (not more, 900ms works)
  callback()
}

export const copyToClipboard = (text: string) => {
  const { copy } = useClipboard()

  copy(text).then(() => {
    useToastAlert().success(`Copiado al portapapeles`)
  }).catch((err) => {
    useToastAlert().error(`Error al copiar al portapapeles`)
    console.error('Error copying text to clipboard:', err)
  })
}

// forms:

export const onFormError = (event: FormErrorEvent) => {
  const element = document.getElementById(event.errors[0]!.id)
  element?.focus()
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  useToastAlert().error({
    title: `${event.errors.length} errores de validación`,
    description: 'Revisa los campos marcados en rojo y vuelve a intentarlo.',
  })
  event.errors.forEach((error) => {
    console.warn(`${error.message} '${error.path}'`)
  })
}

// Fieldset 1 column with padding and border
export const FIELDSET = 'w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg'
// Fieldset 1 column without padding nor border
export const FIELDSET_FULL = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2` // without padding nor border
export const FIELDSET_FULL_BORDER = `w-full gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
// Fieldset 2 columns with padding and border
export const FIELDSET_FULL_2 = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
// Fieldset 12 columns with padding and border
export const FIELDSET_FULL_12 = `w-full grid grid-cols-1 md:grid-cols-12 gap-2 !mt-4 !mb-2 p-4 border rounded-lg` // for 12 columns
export const UI_ACCORDION_ASIDE = {
  wrapper: 'h-full overflow-y-auto pb-4',
  // container: 'h-full overflow-y-auto',
  item: { base: 'grid grid-cols-[2fr,4fr] gap-1 items-start text-xs p-4' },
  default: { class: 'rounded-none bg-transparent border-t border-gray-200 dark:border-gray-800' },
}
export const UI_HORIZONTAL_NAVIGATION = {
  // base: 'text-xs',
  badge: { size: 'xs', base: 'text-[11px] !py-[0px] !px-[5px]' },
}
// a squre button with an icon for toolbars
export const UI_BUTTON_TOOLBAR = {
  variant: {
    outline: 'ring-1 ring-inset ring-gray-200 dark:ring-gray-700',
    soft: 'ring-1 ring-inset ring-primary-300 dark:ring-primary-800',
  },
}
export const UI_DASHBOARD_CARD = {
  header: { inner: 'items-center' },
  icon: { base: 'w-4 h-4' },
}
export const UI_DASHBOARD_CARD_CONTAINED = {
  header: { inner: 'items-center', padding: '!pt-3 !pb-2' },
  body: { base: 'h-full overflow-y-auto' },
}
export const UI_DASHBOARD_CARD_TABLE_RECENT = {
  header: { inner: 'items-center', padding: '!pt-3 !pb-2' },
  body: { padding: '!px-0 !py-0 !pt-3' },
}
export const UI_TABLE_RECENT = {
  base: 'border-none',
  tr: { base: 'divide-none' },
}
export const UI_CARD_SLOT = {
  label: {
    base: 'ml-auto max-w-20 flex items-center justify-end gap-1',
  },
}
