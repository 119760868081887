<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Transaction>
  onSubmit: (event: FormSubmitEvent<Partial<Transaction>>) => void
  openNestedCallback: (data: Partial<Transaction>) => void
  title?: string
  disabledFields?: (keyof Transaction | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Transaction>>(props.data)

const transactions$ = useTransactions()

const $financialaccounts = await useFetch<Financialaccount[]>('/api/db/financialaccounts', {
  query: { $columns: ['id', 'name', 'image', 'currency'] },
  default: () => [],
})

const utils = useTransactionsUtils({ $financialaccounts })

const financialaccounts$ = useFinancialaccounts()

const items = computed<AccordionItem[]>(() => {
  // if (['create'].includes(props.action) && simple.value) {
  //   return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  // }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

watch(() => [state.value.amount, state.value.transactionFee], ([amount, transactionFee]) => {
  state.value.netAmount = (amount || 0) - (transactionFee || 0)
}, { immediate: true })

const currency = computed(() => utils.maps.financialaccountId.value.get(state.value.financialaccountId)?.currency)
const loading = ref(false)

const onSubmitTransaction = async (event: FormSubmitEvent<Partial<Transaction>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.transactions"
      :validate-on="['submit']"
      :schema="transactions$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitTransaction"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(transactions$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Cuenta financiera"
            name="financialaccountId"
            :required="isRequired(transactions$.schema, 'financialaccountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.financialaccountId"
                :options="utils.options.financialaccountId.value"
                :disabled="disabledFields?.includes('financialaccountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('financialaccountId')"
                @click="openNested(() => financialaccounts$.slideoverOpenCreate({
                  data: {
                    type: 'cash',
                    currency: 'MXN',
                    balanceMin: 0,
                    balanceMax: 0,
                  },
                  onSubmit: async (event) => {
                    const financialaccount = await financialaccounts$.onSubmitCreate(event.data)
                    state.financialaccountId = financialaccount!.id
                    await $financialaccounts.refresh()
                    await openNested(() => transactions$.slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Referencia"
            name="reference"
            :required="isRequired(transactions$.schema, 'reference')"
          >
            <InputText
              v-model.defaultcase="state.reference"
              :disabled="disabledFields?.includes('reference')"
            />
          </UFormGroup>

          <div class="grid grid-cols-2 gap-2">
            <UFormGroup
              label="Monto"
              name="amount"
              :required="isRequired(transactions$.schema, 'amount')"
            >
              <InputCurrency
                v-model="state.amount"
                :currency="currency ?? 'USD'"
                :disabled="disabledFields?.includes('amount')"
              />
            </UFormGroup>

            <UFormGroup
              label="Comisión"
              name="transactionFee"
              :required="isRequired(transactions$.schema, 'transactionFee')"
            >
              <InputCurrency
                v-model="state.transactionFee"
                :currency="currency ?? 'USD'"
                :disabled="disabledFields?.includes('transactionFee')"
              />
            </UFormGroup>

            <UFormGroup
              label="Monto neto"
              name="netAmount"
            >
              <InputReadOnly
                :model-value="toCurrency(Number(isNaN(state.netAmount)) ? 0 : (state.netAmount ?? 0), currency ?? 'USD')"
              />
            </UFormGroup>
            <UFormGroup
              label="Moneda"
            >
              <InputReadOnly :model-value="currency" />
            </UFormGroup>
          </div>

          <AppAlert
            type="info"
            description="Monto neto calculado restando comisión bancaria al monto total."
          />

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.accountingDate"
            v-model:date-end="state.valueDate"
            :is-required-start-date="isRequired(transactions$.schema, 'accountingDate')"
            :is-required-end-date="isRequired(transactions$.schema, 'valueDate')"
            :date-start-label="'Fecha contable'"
            :date-end-label="'Fecha valor'"
            :disabled="disabledFields"
          />

          <AppAlert
            v-if="state.type === 'input'"
            type="info"
            description="Fecha contable es cuando se registra la transacción, y fecha de valor es cuando los fondos están disponibles"
          />

          <UFormGroup
            v-if="state.type !== 'input'"
            label="Fecha"
            name="accountingDate"
            :required="isRequired(transactions$.schema, 'accountingDate')"
          >
            <InputDatetime
              v-model:date="state.accountingDate"
              v-model:is-datetime="state.isDatetime"
              :disabled="disabledFields?.includes('accountingDate')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="transactions"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.transactions"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
