import { z } from '#netzo/shared/utils/zod'

export const addressSchema = z.object({
  street: z.string(),
  numberExterior: z.string(),
  numberInterior: z.string().nullable().optional(),
  neighborhood: z.string().nullable().optional(),
  city: z.string(),
  district: z.string().nullable().optional(),
  state: z.string(),
  country: z.string(),
  postalCode: z.string(),
})
