<script setup lang="ts">
import { financialAccountToPaymentAccount } from '#imports'
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Quote>
  onSubmit: (event: FormSubmitEvent<Partial<Quote>>) => void
  openNestedCallback: (data: Partial<Quote>) => void
  title?: string
  disabledFields?: (keyof Quote | string)[]
  readonly?: boolean
  navigateToSubpage?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Quote>>(getDefaultQuote(props.data))

const quotes$ = useQuotes()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: {},
  default: () => [],
})
const $branches = useFetch<Branch[]>('/api/db/branches', {
  query: () => ({ $columns: ['id', 'image', 'name', 'address'] }),
  default: () => [],
})
const $contacts = useFetch<Contact[]>('/api/db/contacts', {
  query: () => ({ type: 'sales', $columns: ['id', 'name', 'accountId', 'title', 'image', 'email', 'phone'] }),
  default: () => [],
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $with: { account: true } },
  default: () => [],
})
const $financialaccounts = await useFetch<Financialaccount[]>('/api/db/financialaccounts', {
  query: { type: ['bank'], $with: { bank: true } },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useQuotesUtils({ $accounts, $branches, $contacts, $deals, $financialaccounts, $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.quotes,
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y condiciones de pago',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'delivery',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateAccountId = async (id: string) => {
  const account = utils.maps.accountId.value.get(id) as Account
  if (!account) return
  state.value.receiver = { ...state.value.receiver, ...account.billing } as Quote['receiver']
  state.value.payment = { ...state.value.payment, ...account.payment } as Quote['payment']
  if (state.value.delivery!.useAccountAddress) {
    state.value.delivery!.address = account.billing?.address as Address
  }
}

const onUpdateDealId = async (dealId: string) => {
  const deal = utils.maps.dealId.value.get(dealId) as Deal
  if (!deal) return
  state.value.accountId = deal.accountId
  state.value.branchId = deal.branchId ?? null
  state.value.contactId = deal.contactId ?? null
  state.value.userId = deal.userId as string
  const account = utils.maps.accountId.value.get(deal.accountId) as Account

  if (!deal.branchId) {
    state.value.delivery!.address = account.billing?.address as Address
  }
  else {
    const branch = utils.maps.branchId.value.get(deal.branchId) as Branch
    state.value.delivery!.useAccountAddress = false
    state.value.delivery!.address = branch.address as Address
  }

  await onUpdateAccountId(state.value.accountId)
}

if (['create'].includes(props.action) && props.data.dealId) {
  onUpdateDealId(props.data.dealId)
}

const accountAlert = computed(() => {
  if (!state.value.accountId) return false
  const deal = utils.maps.dealId.value.get(state.value.dealId) as Deal
  return deal?.accountId !== state.value.accountId
})

const loading = ref(false)

const onSubmitQuote = async (event: FormSubmitEvent<Partial<Quote>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true

  if (['pending', 'EXW'].includes(event.data?.delivery!.incoterm)) {
    delete event.data.branchId
    event.data.delivery!.address = {} as Address
  }
  if (event.data?.delivery?.useAccountAddress) delete event.data.branchId

  await props.onSubmit(event)
  props.navigateToSubpage && router.push(`/sales/quotes/${event.data.id}`)
}

const schema = computed(() => ['pending', 'EXW'].includes(state.value.delivery.incoterm)
  ? quotes$.schemaPendingDelivery
  : quotes$.schemaWithDelivery,
)
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.quotes"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitQuote"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.options.dealId.value,
                disabled: disabledFields?.includes('dealId'),
                onClickUpdate: onUpdateDealId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId, userId: state.userId },
                  disabledFields: [],
                },
                refresh: $deals.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.options.dealId.value,
                disabled: disabledFields?.includes('dealId'),
                onClickUpdate: onUpdateDealId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId, userId: state.userId },
                  disabledFields: [],
                },
                refresh: $deals.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
                onClickUpdate: onUpdateAccountId,
                slideoverOpenCreateProps: {},
                refresh: $accounts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <AppAlert
            v-if="accountAlert"
            type="warning"
            description="La cuenta seleccionada no coincide con la cuenta del negocio."
          />

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('contactId') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Dedicatoria"
            class="w-full"
            name="message.subject"
            :required="state.message!.enabled"
          >
            <UButtonGroup class="flex">
              <UButton
                :icon="`${state.message!.enabled ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'}`"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                @click="() => state.message!.enabled = !state.message!.enabled"
              />
              <UInput
                v-model="state.message!.subject"
                placeholder="Estimado cliente"
                class="flex-1"
                :required="state.message!.enabled"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Mensaje"
            name="message.body"
            :required="state.message!.enabled"
          >
            <UTextarea
              v-model="state.message!.body"
              autoresize
              :required="state.message!.enabled"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.issueDate"
            v-model:date-end="state.expirationDate"
            :is-required-start-date="isRequired(schema, 'issueDate')"
            :is-required-end-date="isRequired(schema, 'expirationDate')"
            :date-start-label="'Fecha de emisión'"
            :date-end-label="'Fecha de vencimiento'"
            :disabled-fields="['isDatetime']"
          />

          <UFormGroup
            label="Observaciones"
            name="notes"
            :required="isRequired(schema, 'notes')"
            hint="(Observaciones se reflejarán en cotización)"
          >
            <InputStrings v-model="state.notes" placeholder="Ingresa una nueva observación" />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="quotes"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Comentarios"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #payment>
          <FieldsetPayment
            v-model="state.payment"
            :schema="schema"
            :disabled-fields="disabledFields"
          />

          <UFormGroup
            label="Cuentas financieras"
            name="paymentAccounts"
            :required="isRequired(schema, 'paymentAccounts')"
          >
            <SelectMenuBase
              :model-value="state.paymentAccounts?.map(item => item.id) ?? []"
              :options="utils.options.financialaccountId.value"
              :disabled="disabledFields?.includes('paymentAccounts')"
              multiple
              @update:model-value="value => state.paymentAccounts = value.map(id => financialAccountToPaymentAccount(utils.maps.financialaccountId.value.get(id)!))"
            />
          </UFormGroup>
        </template>

        <template #delivery>
          <FieldsetDelivery
            v-model="state"
            :disabled="!state.accountId"
            v-bind="{
              utils,
              disabledFields,
              schema,
              refresh: $branches.refresh,
              openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
            }"
          />
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings v-model="state!.pdfSettings" :cols="2" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.quotes"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
