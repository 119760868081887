<script setup lang="ts" generic="T">
const props = defineProps<{
  // data:
  row?: T
  tooltip?: string
  label?: string
  // action:
  href?: string
  target?: string
  click?: () => void
  disabled?: boolean
  // display:
  hideAvatar?: boolean
  avatarSize?: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  textSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}>()

const label = computed(() => (props.label ?? props.row?.name ?? props.row?.uid) as string)
const image = computed(() => {
  const { image, account, contact } = props.row
  return (image || account?.image || contact?.image) as string
})
const buttonOrLinkProps = computed(() => {
  const { disabled, click, href, target } = props
  if (disabled) return
  return {
    ...(click ? { onClick: click } : href ? { href, target } : {}),
    class: click || href ? 'cursor-pointer hover:ring-primary' : '',
  }
})
</script>

<template>
  <NuxtLink
    v-if="row"
    class="flex items-center gap-2 truncate"
    v-bind="buttonOrLinkProps"
    :title="tooltip ?? label"
  >
    <slot name="left">
      <UAvatar
        v-if="!hideAvatar"
        :title="label"
        :src="image"
        :alt="label"
        :size="avatarSize ?? '2xs'"
      />
    </slot>
    <slot>
      <div
        class="flex-1 truncate"
        :class="[
          textSize ? `text-${textSize}` : 'text-xs',
          (click ?? href) ? 'cursor-pointer text-primary decoration-1 decoration-current' : '',
          href ? 'hover:underline' : '',
        ]"
      >
        {{ label }}
        <UTooltip
          v-if="row?.immutable"
          text="Modificaciones deshabilitadas para este registro"
        >
          <UIcon
            name="i-mdi-lock"
            size="xs"
            class="text-gray-400 dark:text-gray-500 ml-1"
          />
        </UTooltip>
      </div>
    </slot>
  </NuxtLink>
</template>
