import type { Options } from '#netzo/shared/types/core'

export const getDealInformation = (data: Partial<DealWithRelations>) => {
  if (!data) return []
  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    subtype: toMapByKey(optionsDeals.subtype, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
  }
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Subtipo', maps.subtype.get(data.subtype!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    data.account && ['Cliente', data.account.name],
  ].filter(Boolean).map(([key, value]) => [key, value || '-'])
}

export const optionsDeals = {
  type: [
    { value: 'inbound', label: 'Entrante', description: 'Negocios iniciados por el cliente, generalmente al contactarnos para solicitar información o cotización. Necesitan ser asociados a una solicitud.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: 'outbound', label: 'Saliente', description: 'Negocios iniciados por nosotros, generalmente a través de actividades de prospección o ventas activas.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
  ],
  subtype: [
    { value: 'new', label: 'Cliente nuevo', description: 'Negocios con clientes nuevos que no han comprado antes.', icon: 'i-mdi-account-plus', color: 'green' },
    { value: 'existing', label: 'Cliente existente', description: 'Negocios con clientes que ya han comprado antes.', icon: 'i-mdi-account-check', color: 'blue' },
  ],
  status: [
    { value: 'contact-pending', label: 'Contacto: Pendiente', description: 'El prospecto aún no ha sido contactado.', icon: 'i-mdi-circle-slice-1', color: 'purple' },
    { value: 'contact-waiting', label: 'Contacto: En Espera', description: 'El prospecto fue contactado, pero no ha respondido.', icon: 'i-mdi-circle-slice-1', color: 'purple' },
    { value: 'contact-review', label: 'Contacto: Requiere Revisión', description: 'No se pudo contactar debido a información incorrecta o incompleta.', icon: 'i-mdi-circle-slice-1', color: 'purple' },
    { value: 'qualification', label: 'Cualificación: Evaluando', description: 'Se está recopilando información sobre el prospecto para determinar viabilidad.', icon: 'i-mdi-circle-slice-3', color: 'yellow' },
    { value: 'proposal-pending', label: 'Propuesta: Pendiente', description: 'Se identificó la necesidad, pero no se ha comenzado a preparar la propuesta.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'proposal-preparing', label: 'Propuesta: En Preparación', description: 'Se está elaborando la propuesta o cotización para el prospecto.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'proposal-sent', label: 'Propuesta: Enviada', description: 'La propuesta ha sido enviada y se espera respuesta.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'proposal-negotiating', label: 'Propuesta: Negociación', description: 'El prospecto respondió y está negociando términos o condiciones.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'won-proof', label: 'Ganado: Esperando Comprobante', description: 'El cliente aceptó la propuesta, pero aún no ha enviado el comprobante o la orden de compra.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'won-sales-order', label: 'Ganado: Preparando Orden de Venta', description: 'Se recibió el comprobante o la orden de compra, y se está generando la orden de venta.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'won-completed', label: 'Ganado: Completado', description: 'El negocio ha sido cerrado exitosamente.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'lost', label: 'Perdido', description: 'El negocio no avanzará más en el proceso por diversas razones.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  priority: optionsShared.priority,
  metrics: {
    frequency: [
      { value: 'one-time', label: 'Una vez', description: 'Valor único que no se repetirá en el tiempo.', color: 'blue', icon: 'i-mdi-repeat-off' },
      { value: 'monthly', label: 'Mensual', description: 'Valor que se repite cada mes.', color: 'green', icon: 'i-mdi-repeat' },
      { value: 'yearly', label: 'Anual', description: 'Valor que se repite cada año.', color: 'orange', icon: 'i-mdi-calendar' },
    ],
    lossReason: [
      { value: 'price', label: 'Precio', description: 'El cliente percibió el precio como demasiado alto.', color: 'red', icon: 'i-mdi-currency-usd' },
      { value: 'competition', label: 'Competencia', description: 'El cliente eligió a un competidor.', color: 'orange', icon: 'i-mdi-account-group' },
      { value: 'features', label: 'Características', description: 'Faltaban características que el cliente necesitaba.', color: 'yellow', icon: 'i-mdi-feature-search' },
      { value: 'timing', label: 'Tiempo', description: 'El momento no era el adecuado para el cliente.', color: 'blue', icon: 'i-mdi-clock-time-four' },
      { value: 'budget', label: 'Presupuesto', description: 'El cliente no tenía presupuesto disponible.', color: 'purple', icon: 'i-mdi-cash' },
      { value: 'support', label: 'Atención', description: 'El cliente no estaba satisfecho con la atención o soporte.', color: 'green', icon: 'i-mdi-account-heart' },
      { value: 'unclear-value', label: 'Valor poco claro', description: 'El cliente no entendió claramente el valor del producto.', color: 'gray', icon: 'i-mdi-help-circle' },
      { value: 'other', label: 'Otro', description: 'Razón no especificada o diferente.', color: 'black', icon: 'i-mdi-asterisk' },
    ],
  },
} satisfies Options

export const optionsDealsMap = {
  Tipo: optionsDeals.type,
  Subtipo: optionsDeals.subtype,
  Estado: optionsDeals.status,
  Prioridad: optionsDeals.priority,
  Métricas: {
    'Frecuencia de Negocio': optionsDeals.metrics.frequency,
    'Razón de pérdida': optionsDeals.metrics.lossReason,
  },
}

export const viewSettingsDeals: ViewSettings = {
  tableName: 'deals',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'account.name',
      label: 'Cliente',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contact.name',
      label: 'Contact',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'metrics.frequency',
      label: 'Frecuencia',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.value',
      label: 'Valor del Negocio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.probability',
      label: 'Probabilidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.valueReal',
      label: 'Valor Real',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.lossReason',
      label: 'Razón de Pérdida',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branch.name',
      label: 'Sucursal',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'campaign.name',
      label: 'Campaña',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsDeals.type },
    { label: 'Subtipo', value: 'subtype', options: optionsDeals.subtype },
    { label: 'Estado', value: 'status', options: optionsDeals.status },
    { label: 'Prioridad', value: 'priority', options: optionsDeals.priority },
  ],
}
