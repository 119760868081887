<script setup lang="ts" generic="T">
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<T>({ required: true })

const props = defineProps<{
  tableName: TableName
  idField: string
  options: Option[]
  utils?: RowUtils
  disabled?: boolean
  multiple?: boolean
  hiddenFields?: string[]
  clearable?: boolean
  onClickUpdate?: (id: string) => Promise<void>
  slideoverOpenCreateProps?: {
    data?: Record<string, unknown>
    disabledFields?: string[]
  }
  refresh?: () => Promise<void>
  openNestedCallback?: (data: T) => Promise<void> // slideoverOpenCreate().then(refresh) | slideoverOpenEdit().then(refresh)
}>()

const onClickCreate = () => {
  const {
    tableName,
    idField,
    multiple = false,
    onClickUpdate,
    slideoverOpenCreateProps,
    refresh,
    openNestedCallback,
  } = props
  openNested(() => {
    const table$ = useTable(tableName)
    table$.slideoverOpenCreate({
      ...slideoverOpenCreateProps,
      onSubmit: async (event: FormSubmitEvent<Partial<T>>) => {
        const row = await table$.onSubmitCreate(event.data)
        model.value[idField] = multiple ? [...model.value[idField] as string[], row?.id] : row?.id
        await refresh?.()!
        await onClickUpdate?.(row?.id)! // update must be called after refresh
        await openNested?.(() => openNestedCallback?.(model.value)) // FIXME: this is a hack
      },
    })
  })
}

const onClickEdit = () => {
  const {
    tableName,
    idField,
    onClickUpdate,
    slideoverOpenCreateProps,
    refresh,
    openNestedCallback,
  } = props
  openNested(async () => {
    const table$ = useTable(tableName)
    const data = await $fetch(`/api/db/${tableName}/${model.value[idField]}`)
    table$.slideoverOpenEdit({
      ...slideoverOpenCreateProps,
      title: `Editar Registro`,
      data,
      readonly: data.immutable === true,
      onSubmit: async (event: FormSubmitEvent<Partial<T>>) => {
        const row = await table$.onSubmitUpdate(event.data)
        await refresh?.()!
        await onClickUpdate?.(row?.id)! // update must be called after refresh
        await openNested?.(() => openNestedCallback?.(model.value)) // FIXME: this is a hack
      },
    })
  })
}

const createOrEditButtonProps = computed(() => {
  const { idField, disabled = false } = props
  return model.value?.[idField]
    ? { icon: 'i-mdi-pencil', disabled, onClick: onClickEdit }
    : { icon: 'i-mdi-plus', disabled, onClick: onClickCreate }
})

const information = computed(() => {
  const { tableName, idField, utils } = props
  if (!model.value[idField] || !utils?.maps) return null
  const map = utils.maps?.[idField]?.value ?? utils.maps?.[idField]
  const selected = map?.get?.(model.value[idField] as string)
  if (!selected) return null
  try {
    const entries = useTableInformation(tableName, selected)
    return entries ?? []
  }
  catch (error) {
    console.error('Error fetching table information:', error)
    return null
  }
})
</script>

<template>
  <div class="flex gap-1">
    <slot v-if="$slots.leading" name="leading" />

    <UButtonGroup class="flex-1 flex">
      <USelectMenu
        v-model="model[idField]"
        :options="options"
        trailing
        searchable
        searchable-placeholder="Filtrar..."
        :disabled="disabled"
        :multiple="multiple"
        option-attribute="label"
        value-attribute="value"
        class="min-w-0 flex-1"
        :ui-menu="{
          ...$attrs?.uiMenu ?? {},
          width: `min-w-max ${$attrs?.uiMenu?.width}`,
          label: 'text-clip',
        }"
        v-bind="$attrs"
        @update:model-value="onClickUpdate"
      >
        <template v-for="(_, slot) of $slots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
        <template #label="slotProps">
          <slot name="label" v-bind="slotProps">
            <template v-if="Array.isArray(model[idField]) ? model[idField].length : model[idField]">
              <SelectMenuSlotLabel
                v-model="model[idField]"
                v-bind="{ slotProps, disabled, multiple, clearable }"
              />
            </template>
          </slot>
        </template>
      </USelectMenu>
      <UButton
        v-if="slideoverOpenCreateProps"
        variant="outline"
        v-bind="createOrEditButtonProps"
        :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
      />
    </UButtonGroup>

    <ButtonReferenceInfo
      v-if="information"
      :disabled="!model?.[idField] || !information.length"
      :entries="information"
    />

    <ButtonRefresh
      v-if="onClickUpdate"
      :disabled="!model?.[idField]"
      @click="onClickUpdate(model[idField]! as string)"
    />

    <slot v-if="$slots.trailing" name="trailing" />
  </div>
</template>

<style scoped>
:deep([data-headlessui-state]) {
  min-width: 0;
}
</style>
