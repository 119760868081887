import { AppIndustriesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppIndustriesSlideoverProps = ComponentProps<typeof AppIndustriesSlideover>

export type IndustriesUtils = ReturnType<typeof useIndustriesUtils>

export const useIndustries = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Industry>('industries')

  const slideoverOpenCreate = (props: AppIndustriesSlideoverProps) => {
    return new Promise<Industry>((resolve, reject) => {
      const data = getDefaultIndustry(props.data)
      slideover.open(AppIndustriesSlideover, {
        title: 'Crear industria',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppIndustriesSlideoverProps) => {
    return new Promise<Industry>((resolve, reject) => {
      const data = getDefaultIndustry(props.data)
      slideover.open(AppIndustriesSlideover, {
        title: 'Editar industria',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    name: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Industry, refresh: () => Promise<void>, utils: IndustriesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'industries', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'industries', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'industries', row, refresh, utils),
      getDropdownItem('files', 'industries', row, refresh, utils),
    ],
    [
      {
        label: 'Crear cuenta',
        icon: ICONS.accounts,
        click: () => {
          const accounts$ = useAccounts()
          accounts$.slideoverOpenCreate({
            data: { industryId: row.id },
            disabledFields: ['industryId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'industries', row, refresh, utils),
      getDropdownItem('immutable', 'industries', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'industries', row, refresh, utils),
      getDropdownItem('delete', 'industries', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useIndustriesUtils = () => {
  const options = {
    ...optionsIndustries,
  }

  const maps = {}

  return { options, maps }
}
