<script setup lang="ts">
const appConfig = useAppConfig()
const { user } = useUserSession()
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel grow>
      <UDashboardNavbar title="La Tinta" class="z-10 bg-background">
        <template #left>
          <NuxtLink
            :to="user ? '/portals' : undefined"
            class="flex items-center gap-3"
          >
            <img
              :src="appConfig.netzo.favicon"
              :alt="appConfig.netzo.title"
              class="h-10 w-auto"
            >
            <h1 class="text-gray-900 dark:text-white text-2xl font-bold truncate">
              {{ appConfig.netzo.title }}
            </h1>
          </NuxtLink>
        </template>
        <template #right>
          <UserDropdownPortal v-if="user" />
        </template>
      </UDashboardNavbar>

      <slot />

      <UDivider />

      <footer class="px-4 py-4 flex items-center justify-between">
        <span class="text-sm text-gray-500 dark:text-gray-400">
          <strong>Netzo</strong> - tu aliado en tecnología
        </span>
        <NetzoLogo class="h-10 w-auto" />
        <a
          href="mailto:hello@netzo.io"
          class="hidden sm:flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400"
        >
          <UIcon name="i-mdi-email" class="h-5 w-5" />
          hello@netzo.io
        </a>
      </footer>
    </UDashboardPanel>
  </UDashboardLayout>
</template>

<style scoped>
/* hide UDashboardNavbarToggle from toolbar */
:deep([aria-label="Open sidebar"]) {
  display: none;
}
</style>
