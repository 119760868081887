<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const model = defineModel<string | number | boolean | object | any[] | undefined>({ required: true })

type Selected = {
  icon?: string
  image?: string
  label: string
  color?: string
}

type SlotProps = {
  selected: Selected | Selected[]
}

const props = defineProps<{
  slotProps: SlotProps // slotProps of #label slot of USelectMenu
  disabled?: boolean
  multiple?: boolean
  clearable?: boolean
}>()

const getLabel = (selected: SlotProps['selected']) => {
  const value = Array.isArray(selected) ? selected : [selected]
  const string = value.map(item => item.label).join(', ')
  return string.length > 75 ? `${string.slice(0, 75)}...` : string
}
</script>

<template>
  <div v-if="slotProps.selected" class="flex items-center justify-between gap-2 w-full h-[20px]">
    <template v-if="!multiple">
      <UIcon
        v-if="slotProps?.selected?.icon"
        :name="slotProps?.selected?.icon"
        :title="slotProps?.selected?.label"
        tabindex="0"
        class="w-4 h-4"
        :ui="slotProps?.selected?.icon && slotProps?.selected?.color
          ? { icon: { base: `text-${slotProps?.selected?.color}` } }
          : {}
        "
      />
      <UAvatar
        v-else-if="slotProps?.selected?.image || slotProps?.selected?.label"
        size="2xs"
        :src="slotProps?.selected?.image"
        :title="slotProps?.selected?.label"
        :alt="slotProps?.selected?.label"
        :ui="slotProps?.selected?.icon && slotProps?.selected?.color
          ? { icon: { base: `text-${slotProps?.selected?.color}` } }
          : {}
        "
      />
    </template>
    <span class="truncate mr-auto">
      {{ getLabel(slotProps.selected) }}
    </span>
    <UTooltip
      v-if="(clearable ?? true) && model"
      text="Quitar selección"
      class="ml-auto"
      :popper="{ placement: 'top' }"
    >
      <UButton
        variant="ghost"
        size="2xs"
        icon="i-mdi-close"
        :disabled="disabled"
        color="red"
        class="clear-button p-0"
        @click.stop="model = undefined"
      />
    </UTooltip>
  </div>
</template>
