<script setup lang="ts">
const model = defineModel<boolean>()

const props = defineProps<{
  data: Auditlog
  utils: AuditlogsUtils
  title?: string
  description?: string
}>()

const {
  getAuditlogDescription,
} = useAuditlogs()

const columns = [
  { key: 'column', label: 'Columna' },
  ...(props.data.dataPrevious ? [{ key: 'dataPrevious', label: 'Valor anterior' }] : []),
  { key: 'data', label: 'Valor' },
]

const rows = Object.entries(props.data?.data ?? {}).map(([key, data]) => {
  const dataPrevious = props.data?.dataPrevious?.[key]
  return { column: key, dataPrevious, data }
})

const jsonStringify = (value: string | object) => {
  try {
    return JSON.stringify(value, null, 2)
  }
  catch {
    return value
  }
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title
  >
    <p
      class="py-2 px-1 text-sm"
      v-html="getAuditlogDescription(data, utils)"
    />

    <UTable
      v-if="['create', 'update'].includes(data.action)"
      :columns
      :rows
      :ui="{ wrapper: 'max-h-[50vh]' }"
      v-bind="$attrs"
    >
      <template #column-data="{ row }">
        <code>{{ row.column }}</code>
      </template>
      <template #dataPrevious-data="{ row }">
        <pre class="font-mono whitespace-pre">{{ jsonStringify(row.dataPrevious) }}</pre>
      </template>
      <template #data-data="{ row }">
        <pre class="font-mono whitespace-pre">{{ jsonStringify(row.data) }}</pre>
      </template>
    </UTable>
  </UDashboardModal>
</template>

<style scoped>
:deep(code),
:deep(kbd) {
  font-family: "Source Code Pro",monospace;
  font-variant-ligatures: none !important;
  border: 1px solid rgb(var(--ui-foreground) / 0.3);
  border-radius: 4px;
  /* background-color: rgba(var(--background));
  color: rgba(var(--on-background)); */
  padding: 1px 4px;
  margin: auto 1px;
  /* box-shadow: 0px 1px 4px rgb(0 0 0 / 10%); */
  vertical-align: baseline;
}
</style>
