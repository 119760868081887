export const getDefaultAccountgroupData = (data: Partial<Accountgroup['data']>) => null
export const getDefaultAccountData = (data: Partial<Account['data']>) => null
export const getDefaultActivityData = (data: Partial<Activity['data']>) => null
export const getDefaultApplicationData = (data: Partial<Application['data']>) => null
export const getDefaultBankData = (data: Partial<Bank['data']>) => null
export const getDefaultBranchData = (data: Partial<Branch['data']>) => null
export const getDefaultCampaignData = (data: Partial<Campaign['data']>) => null
export const getDefaultCategoryData = (data: Partial<Category['data']>) => null
export const getDefaultContactData = (data: Partial<Contact['data']>) => null
export const getDefaultDealData = (data: Partial<Deal['data']>) => null
export const getDefaultFinancialaccountData = (data: Partial<Financialaccount['data']>) => null
export const getDefaultIndustryData = (data: Partial<Industry['data']>) => null
export const getDefaultProductionorderData = (data: Partial<Productionorder['data']>) => null
export const getDefaultProductionorderitemData = (data: Partial<Productionorderitem['data']>) => null
export const getDefaultProductData = (data: Partial<Product['data']>) => null
export const getDefaultProductitemData = (data: Partial<Productitem['data']>) => null
export const getDefaultPricelistData = (data: Partial<Pricelist['data']>) => null
export const getDefaultPricelistitemData = (data: Partial<Pricelistitem['data']>) => null
export const getDefaultQuoteData = (data: Partial<Quote['data']>) => null
export const getDefaultQuoteitemData = (data: Partial<Quoteitem['data']>) => null
export const getDefaultSalesorderData = (data: Partial<Salesorder['data']>) => null
export const getDefaultSalesorderitemData = (data: Partial<Salesorderitem['data']>) => null
export const getDefaultServiceData = (data: Partial<Service['data']>) => null
export const getDefaultServiceitemData = (data: Partial<Serviceitem['data']>) => null
export const getDefaultTransactionData = (data: Partial<Transaction['data']>) => null
