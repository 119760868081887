<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<boolean>()

const props = defineProps<{
  data: Partial<User>
  onSubmit: (event: FormSubmitEvent<Partial<User>>) => void
  openNestedCallback: (data: Partial<User>) => void
  title?: string
  description?: string
  disabledFields?: (keyof User | string)[]
  readonly?: boolean
}>()

const state = ref<Partial<User>>(props.data)

const { schema } = useUsers()

const onSubmitUser = async (event: FormSubmitEvent<Partial<User>>) => {
  loading.value = true
  // IMPORTANT: ensure all non-external modules are deleted from
  // event.data.modules[moduleId] if type is external for extra safety
  if (['external'].includes(state.value.type)) {
    const nonExternalModules = optionsUsers.modules.filter(mod => !['external'].includes(mod.type))
    for (const mod of nonExternalModules) {
      delete event.data.modules[mod.value]
    }
  }
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title
    :description
  >
    <UForm
      id="form.users"
      :validate-on="['input', 'submit']"
      :schema="schema"
      :state="state"
      class="space-y-4"
      @error="onFormError"
      @submit="onSubmitUser"
    >
      <UFormGroup
        label="Nombre"
        name="name"
        :required="isRequired(schema, 'name')"
      >
        <InputText
          v-model.defaultcase="state.name"
          autofocus
          :disabled="disabledFields?.includes('name')"
        />
      </UFormGroup>

      <UFormGroup
        label="Email"
        name="email"
        :required="isRequired(schema, 'email')"
      >
        <InputText
          v-model.lowercase="state.email"
          type="email"
          autocomplete="email"
          :disabled="disabledFields?.includes('email')"
        />
      </UFormGroup>
    </UForm>

    <template #footer>
      <UButton
        form="form.users-invite"
        type="submit"
        label="Confirmar"
        color="primary"
      />
    </template>
  </UDashboardModal>
</template>
