<script setup lang="ts">
defineOptions({ inheritAttrs: false })

const props = defineProps<{
  loading?: boolean
  data?: Row | Row[]
  icon?: string
  label?: string
}>()

const status = computed(() => {
  if (props.loading && !props.data) return 'loading'
  else if (props.data) {
    if (Array.isArray(props.data)) return props.data.length === 0 ? 'empty' : 'data'
    else return Object.keys(props.data).length === 0 ? 'empty' : 'data'
  }
  else return 'empty'
})
</script>

<template>
  <!-- IMPORTANT: breaks UDashboardPanel/UDashboardPanelContent when used as wrapper,
   so instead we use it with a v-else statement (not wrapping UDashboardPanel components -->
  <template v-if="['loading'].includes(status)">
    <slot name="loading">
      <UIcon
        class="w-6 h-6 mx-auto text-gray-400 dark:text-gray-500"
        name="i-mdi-loading"
      />
      <div class="flex items-center justify-center h-32">
        <i class="loader --6" />
      </div>
    </slot>
  </template>

  <!-- copied from rendered result of UTable empty state slot -->
  <template v-else-if="['empty'].includes(status)">
    <slot name="empty">
      <div
        class="h-full flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14"
        v-bind="$attrs"
      >
        <UIcon
          class="w-6 h-6 mx-auto text-gray-400 dark:text-gray-500 mb-4"
          :name="icon ?? 'i-mdi-border-none-variant'"
        />
        <p class="text-sm text-center text-balance text-gray-900 dark:text-white">
          {{ label || 'No hay datos para mostrar.' }}
        </p>
        <slot name="bottom" />
      </div>
    </slot>
  </template>

  <slot v-else />
</template>

<style scoped>
/* https://codepen.io/jenning/pen/YzNmzaV */

.loader {
    --color: rgb(var(--color-primary-400));
    --size-mid: 6vmin;
    --size-dot: 1.5vmin;
    --size-bar: 0.4vmin;
    --size-square: 3vmin;

    display: block;
    position: relative;
    width: 50%;
    display: grid;
    place-items: center;
}

.loader::before,
.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
}

/**
    loader --6
**/
.loader.--6::before {
    width: var(--size-square);
    height: var(--size-square);
    background-color: var(--color);
    top: calc(50% - var(--size-square));
    left: calc(50% - var(--size-square));
    animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-6 {
    0%, 100% {
        transform: none;
    }

    25% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(100%) translateY(100%);
    }

    75% {
        transform: translateY(100%);
    }
}
</style>
