<script setup lang="ts">
import { camel, capital, constant, header, kebab, lower, pascal, sentence, snake, title, upper } from 'case'

const appConfig = useAppConfig()

// see https://vuejs.org/guide/components/v-model#handling-v-model-modifiers
const [model, modifiers] = defineModel<string | undefined | null>({
  set(value) {
    if (modifiers.uppercase) return String(value)?.toUpperCase()
    if (modifiers.lowercase) return String(value)?.toLowerCase()
    if (modifiers.defaultcase) {
      switch (appConfig.netzo.preferences?.text) {
        case 'upper': return upper(value!)
        case 'lower': return lower(value!)
        case 'capital': return capital(value!)
        case 'snake': return snake(value!)
        case 'pascal': return pascal(value!)
        case 'camel': return camel(value!)
        case 'kebab': return kebab(value!)
        case 'header': return header(value!)
        case 'constant': return constant(value!)
        case 'title': return title(value!)
        case 'sentence': return sentence(value!)
        default: return value
      }
    }
    if (modifiers.trim) return String(value)?.trim()
    if (modifiers.number) return Number(value)
    if (modifiers.json) return safeJsonParse(value!)
    return value
  },
})

// requires manually handling cursor position so edits not at the end of the line don't jump around
const onInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  // save current cursor position
  const cursorPosition = input.selectionStart
  // sync input value with model (transformation happens in the `set` method)
  model.value = input.value
  // restore cursor position to avoid jumping
  requestAnimationFrame(() => {
    if (cursorPosition !== null) {
      input.setSelectionRange(cursorPosition, cursorPosition)
    }
  })
}

function safeJsonParse(value: string): unknown {
  try {
    return JSON.parse(value)
  }
  catch (error) {
    return value
  }
}
</script>

<template>
  <UInput v-model="model" @input="onInput">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </UInput>
</template>
