const PUBLIC = ['/portals'] // handle auth routes separately
const PROTECTED_ROOT = ['/', '/tasks', '/notifications', '/assistant', '/settings']

// NOTE: this middleware authenticates client (nuxt) routes
export default defineNuxtRouteMiddleware((to, _from) => {
  const isAuthRoute = to.path.startsWith('/auth')
  const isPublicRoute = PUBLIC.some(path => to.path.startsWith(path))
  const isRootRoute = PROTECTED_ROOT.some(path => to.path.startsWith(path))

  const { loggedIn, user } = useUserSession()
  const moduleId = to.path.split('/')[1] as keyof User['modules']
  const hasAccessToModule = isRootRoute || (moduleId && user.value?.modules?.[moduleId]?.enabled)

  if (loggedIn.value) {
    if (isPublicRoute) return
    if (isAuthRoute) return navigateTo('/')
    if (!hasAccessToModule) return navigateTo('/')
  }
  else {
    if (isPublicRoute) return
    if (!isAuthRoute) return navigateTo('/auth/login')
  }
})
