import routerOptions0 from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/Users/mrk/Documents/esgraf-app/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}