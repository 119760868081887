import { AppDealsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppDealsSlideoverProps = ComponentProps<typeof AppDealsSlideover>

export type DealsUtils = ReturnType<typeof useDealsUtils>

export const useDeals = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Deal>('deals')

  const slideoverOpenCreate = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultDeal({userId: user.value.id, ...props.data})
      slideover.open(AppDealsSlideover, {
        title: 'Crear negocio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const data = getDefaultDeal(props.data)

      slideover.open(AppDealsSlideover, {
        title: 'Editar negocio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    subtype: z.string(),
    priority: z.string(),
    status: z.string(),
    name: z.string(),
    accountId: z.string(),
    campaignId: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Deal, refresh: () => Promise<void>, utils: DealsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'deals', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'deals', row, refresh, utils),
      getDropdownItem('copyUid', 'deals', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'deals', row, refresh, utils),
      getDropdownItem('files', 'deals', row, refresh, utils),
    ],
    [
      {
        label: 'Registrar actividad',
        icon: ICONS.activities,
        click: () => {
          const activities$ = useActivities()
          activities$.slideoverOpenCreate({
            data: {
              dealId: row.id,
              accountId: row.accountId,
              branchId: row.branchId,
            },
            disabledFields: ['dealId', 'accountId'],
          }).then(refresh)
        },
      },
      {
        label: 'Crear cotización',
        icon: ICONS.quotes,
        click: () => {
          const quotes$ = useQuotes()
          quotes$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      {
        label: 'Registrar orden de venta',
        icon: ICONS.salesorders,
        click: () => {
          const salesorders$ = useSalesorders()
          salesorders$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'deals', row, refresh, utils),
      getDropdownItem('immutable', 'deals', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'deals', row, refresh, utils),
      getDropdownItem('delete', 'deals', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useDealsUtils = ({ $accounts, $branches, $campaigns, $contacts, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $campaigns?: Awaited<ReturnType<typeof useFetch<Campaign[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsDeals,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    campaignId: computed(() => $campaigns?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    subtype: toMapByKey(optionsDeals.subtype, 'value'),
    priority: toMapByKey(optionsDeals.priority, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
    metrics: {
      frequency: toMapByKey(optionsDeals.metrics.frequency, 'value'),
      lossReason: toMapByKey(optionsDeals.metrics.lossReason, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    campaignId: computed(() => toMapByKey($campaigns?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
