import { computed, navigateTo, useRoute, useUserSession, type User, type ViewSettings } from '#imports'
import type { ModuleOptions } from '#netzo/app.config'

export type Module = {
  id: keyof User['modules']
  name: string
  description: string
  color: string
  icon: `i-mdi-${string}`
  disabled: boolean
}

export const MODULES: Module[] = [
  {
    id: 'admin',
    name: 'Administración',
    description: 'Gestión del sistema, administración de roles y configuraciones.',
    color: 'gray',
    icon: 'i-mdi-cog',
    disabled: false,
  },
  {
    id: 'management',
    name: 'Dirección',
    description: 'Gestión empresarial, indicadores de gestión y reportes.',
    color: 'emerald',
    icon: 'i-mdi-finance',
    disabled: false,
  },
  {
    id: 'catalogs',
    name: 'Catalogos',
    description: 'Gestión de productos y servicios, sus industrias y listas de precios.',
    color: 'blue',
    icon: 'i-mdi-barcode',
    disabled: false,
  },
  // {
  //   id: 'fulfillment',
  //   name: 'Cumplimiento',
  //   description: 'Gestión de trabajos a realizar para el cumplimiento de ordenes.',
  //   color: 'amber',
  //   icon: 'i-mdi-package',
  //   disabled: false,
  // },
  {
    id: 'finances',
    name: 'Finanzas',
    description: 'Gestión de caja, presupuestos y reportes financieros.',
    color: 'green',
    icon: 'i-mdi-bank',
    disabled: false,
  },
  {
    id: 'production',
    name: 'Producción',
    description: 'Gestión de trabajos a realizar para la producción de ordenes.',
    color: 'black',
    icon: 'i-mdi-factory',
    disabled: false,
  },
  {
    id: 'sales',
    name: 'Ventas',
    description: 'Gestión de clientes, proceso de venta y cotizaciones.',
    color: 'purple',
    icon: 'i-mdi-tag',
    disabled: false,
  },
]

const isAtRoot = (moduleId: string | undefined) => !moduleId || !MODULES.map(mod => mod.id).includes(moduleId)

export const viewSettingsModules: ViewSettings = {
  tableName: 'modules',
  typeOptions: [{ value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' }],
  type: 'cards',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'disabled',
      label: 'Deshabilitado',
      sortable: true,
    },
  ],
}

export const useModules = () => {
  const route = useRoute()
  const { user } = useUserSession()

  // filter out modules without user.modules[<moduleId>].enabled === true
  const modules = computed(() => MODULES.filter(mod => user.value?.modules?.[mod.id]?.enabled))

  const moduleId = computed({
    get: () => {
      const moduleId = route.path.split('/')?.[1]
      return isAtRoot(moduleId) ? undefined : moduleId
    },
    set: (value: string) => navigateTo(`/${value}`),
  })

  const tableName = computed(() => route.path?.split('/')?.[2])
  const tableNameTab = computed(() => route.path?.split('/')?.[4])

  const userModule = computed<ModuleOptions>(() => {
    // (virtual) module for application root with 'admin' role for all users
    if (!moduleId.value) return { enabled: true, role: 'admin' }
    return user.value?.modules?.[moduleId.value]
  })

  return {
    modules,
    moduleId,
    tableName,
    tableNameTab,
    userModule,
  }
}
