import { merge } from 'es-toolkit/compat'

export const getDefaultNote = (data: Partial<Note>) => {
  return merge({
    text: '',
  }, data)
}

export const optionsNotes = {
  tableName: optionsShared.tableName,
} satisfies Options

export const optionsNotesMap = {}

export const relationsNotes: Record<string, boolean> = {
  user: true,
}

export type NoteWithRelations = Note & {
  user: User
}

export const viewSettingsNotes: ViewSettings = {
  tableName: 'notes',
  typeOptions: optionsShared.views.filter(view => ['list', 'grid'].includes(view.value)),
  type: 'list',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    // {
    //   key: 'tableName',
    //   label: 'Registro',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    // {
    //   key: 'recordId',
    //   label: 'ID del Registro',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    {
      key: 'text',
      label: 'Nota',
      class: 'max-w-[300px]',
    },
    {
      key: 'user.name',
      label: 'Usuario',
      sortable: true,
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    ...COLUMNS_METADATA,
  ],
}
