<script setup lang="ts">
const props = defineProps<{
  tableName: TableName
  recordId: string
  type: 'files'
  title?: string
}>()

const query = ref<ViewQuery>({
  // tableName: [props.tableName],
  // recordId: [props.recordId],
  // type: ['files'],
  userId: [],
  archivedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: view.value.pagination,
  $with: relationsFiles,
})
const $files = await useFetch<FileWithRelations[]>('/api/db/files', {
  query: computed(() => {
    const { tableName, recordId, type = 'files' } = query.value
    return { ...query.value, tableName, recordId, type }
  }),
  default: () => [],
})

const files$ = useFiles()

const utils = useFilesUtils()

const { downloadAll } = useBlobFiles()
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ body: { base: 'px-0' }, footer: { padding: 'pt-0' } }"
  >
    <div class="flex flex-col h-full gap-3">
      <ViewPlaceholder
        :data="$files.data.value"
        icon="i-mdi-file"
        label="No hay archivos para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3 px-4">
          <AppFilesViewListItem
            v-for="(row, i) in $files.data.value"
            :key="`file-${row.pathname}-${i}`"
            :row="row"
            :dropdown-items="files$.getDropdownItems(row, $files.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </div>

    <template #footer>
      <UButton block @click="downloadAll($files.data.value)">
        Descargar {{ $files.data.value?.length }} {{ $files.data.value?.length > 1 ? 'archivos' : 'archivo' }}
      </UButton>
    </template>
  </UDashboardSlideover>
</template>
