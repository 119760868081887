import type { File as DBFile } from '#netzo/shared/types/db'
import { getDefaultFile } from '#netzo/utils/app/files'
import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

export const useApiBlob = (tableName: TableName, recordId: string) => {
  const $fetch = useRequestFetch() // IMPORTANT: send cookies along on SSR (pending merge https://github.com/nuxt/nuxt/issues/24813)

  const { upsertFileInDB, removeFileFromDB } = useServerFunctions()

  /**
   * returns a copy of the file with a new name
   * NOTE: file is an instance of the File constructor (e.g. new File())
   */
  const createRenamedFile = (file: File, name: string) => new File([file], name, { type: file.type })

  /**
   * uploads file to blob and creates an associated record in the database
   * NOTE: file is an instance of the File constructor (e.g. new File())
   */
  const uploadFile = async (
    type: string,
    file: File,
    options: BlobPutOptions = {},
    data: Partial<DBFile> = {},
  ) => {
    const formData = new FormData()

    const defaultFile = getDefaultFile({ tableName, recordId, type, name: file.name })
    options.prefix = `${tableName}/${recordId}`
    formData.append('file', createRenamedFile(file, defaultFile.id)) // rename for blob storage

    const blobObject = await $fetch<BlobObject>('/api/blob', {
      method: 'POST',
      query: { ...options },
      body: formData,
    })
    // @ts-expect-error - type for 'updatedAt' is outdated (set to Date but returns string)
    return upsertFileInDB({ ...defaultFile, ...blobObject, ...data })
  }

  /**
   * removes file from blob as well as its associated record in the database
  */
  const removeFile = async (file: DBFile) => {
    if (!file?.pathname) return
    await $fetch(`/api/blob/${file.pathname}`, { method: 'DELETE' })
    return removeFileFromDB(file.pathname)
  }

  return {
    createRenamedFile,
    uploadFile,
    removeFile,
  }
}
