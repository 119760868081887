<script setup lang="ts">
const model = defineModel<number>()

const props = defineProps<{
  icon?: boolean
}>()
</script>

<template>
  <UInput
    :model-value="model * 100"
    type="number"
    :icon="icon ? 'i-mdi-percent' : undefined"
    inputmode="decimal"
    :min="0"
    :max="100"
    :step="1"
    @blur="model = $event.target.value > 100 ? 1 : Math.max(0, $event.target.value)"
  />
</template>
