import { appendResponseHeader } from "h3";
import { useState, computed, useRequestFetch, useRequestEvent } from "#imports";
export function useUserSession() {
  const serverEvent = import.meta.server ? useRequestEvent() : null;
  const sessionState = useState("nuxt-session", () => ({}));
  const authReadyState = useState("nuxt-auth-ready", () => false);
  const clear = async () => {
    await useRequestFetch()("/api/_auth/session", {
      method: "DELETE",
      onResponse({ response: { headers } }) {
        if (import.meta.server && serverEvent) {
          for (const setCookie of headers.getSetCookie()) {
            appendResponseHeader(serverEvent, "Set-Cookie", setCookie);
          }
        }
      }
    });
    sessionState.value = {};
  };
  const fetch = async () => {
    sessionState.value = await useRequestFetch()("/api/_auth/session", {
      headers: {
        accept: "application/json"
      },
      retry: false
    }).catch(() => ({}));
    if (!authReadyState.value) {
      authReadyState.value = true;
    }
  };
  return {
    ready: computed(() => authReadyState.value),
    loggedIn: computed(() => Boolean(sessionState.value.user)),
    user: computed(() => sessionState.value.user || null),
    session: sessionState,
    fetch,
    clear
  };
}
