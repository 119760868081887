import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId, createUid } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'es-toolkit/compat'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultPricelist = (data: Partial<Pricelist>) => {
  const uid = createUid(new Date(), 'LP') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'draft',
    uid: uid,
    name: uid,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    filePdf: {},
    pdfSettings: defaultPdfSettings,
    tags: [],
    data: getDefaultPricelistData(data?.data ?? {}),
  }, data)
}

export const getPricelistInformation = (data: Partial<Pricelist>) => {
  const maps = {
    type: toMapByKey(optionsPricelists.type, 'value'),
    status: toMapByKey(optionsPricelists.status, 'value'),
  }
  if (!data) return []
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Fecha de entrada en vigor', data.date ? new Date(data.date).toLocaleDateString() : 'Por definir'],
  ].map(([key, value]) => [key, value || '-'])
}

// Make sure that function only receives pricelists with status 'completed'
export function getPriceForItem(pricelists: PricelistWithRelations[], type: 'products' | 'services', itemId: string) {
  let basePrice: number | undefined
  for (const pricelist of pricelists) {
    if (pricelist.status === 'completed') {
      const itemIdField = type === 'products' ? 'productitemId' : 'serviceitemId'
      const matchingItem = pricelist.pricelistitems.find(plItem => plItem[`${itemIdField}`] === itemId)
      if (matchingItem) {
        basePrice = matchingItem.basePrice as number
        break
      }
    }
  }
  return basePrice
}

export const optionsPricelists = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'sky', description: 'Esta lista de precios incluye bienes tangibles que pueden adquirirse, como mercancías o materiales.' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'orange', description: 'Esta lista de precios incluye actividades intangibles ofrecidas para satisfacer necesidades específicas, como consultoría o mantenimiento.' },
  ],
  status: [
    { value: 'draft', label: 'Borrador', description: 'La lista de precios está en proceso de creación. Aún no está publicada y los precios no pueden para ser utilizada en cotizaciones.', icon: 'i-mdi-file-outline', color: 'blue' },
    { value: 'approved', label: 'Aprobacion', description: 'La lista de precios necesita ser revisada y aprobada, antes de publicarse.', icon: 'i-mdi-check-outline', color: 'teal' },
    { value: 'completed', label: 'Completado', description: 'La lista de precios está finalizada y sus precios serán tomados en cuenta desde la fecha de entrada en vigor en el sistema.', icon: 'i-mdi-check-circle-outline', color: 'green' },
  ],
} satisfies Options

export const optionsPricelistsMap = {
  Tipo: optionsPricelists.type,
  Estado: optionsPricelists.status,
}

export const relationsPricelists: Record<string, boolean> = {
  pricelistitems: true,
  filePdf: true,
  files: true,
  // options: true,
}

export type PricelistWithRelations = Pricelist & {
  pricelistitems: Pricelistitem[]
  files: File[]
  options: Option[]
}

export const pricelistToViewCalendarEvent = (calendarId: keyof Pricelist, utils: PricelistsUtils) => {
  return (row: Pricelist): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      start: format(new Date(row.date), dateFormat),
      end: format(new Date(row.date), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionPricelists = async (
  data: PricelistWithRelations,
  categories: Partial<Category>[],
  items: Array<(Productitem & { product: Product }) | (Serviceitem & { service: Service })>,
  utils: PricelistsUtils,
): Promise<TDocumentDefinitions> => {
  data.data ??= {}
  data.pdfSettings ??= defaultPdfSettings
  data.pricelistitems ??= []

  const maps = {
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    itemId: toMapByKey(items, 'id'),
    categoryId: toMapByKey(categories, 'id'),
  }

  // sections:

  const info: TDocumentInformation = {
    title: `${data.name && data.uid && (data.name !== data.uid) ? `${data.name} (${data.uid})` : data.uid}`,
    subject: 'Lista de precios La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Lista de Precios', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Nombre: ', bold: true }, data.name], alignment: 'right' },
          { text: [{ text: 'Fecha de documento: ', bold: true }, new Date().toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Fecha de entrada en vigor: ', bold: true }, new Date(data.date).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Tipo: ', bold: true }, utils.maps.type.get(data.type)?.label!], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label!], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionPricelistitems: Content = {
    table: {
      dontBreakRows: true,
      headerRows: 1,
      widths: data.pdfSettings?.images
        ? ['auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
        : ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          { text: '#', style: 'tableHeader' },
          { text: 'SKU', style: 'tableHeader' },
          ...data.pdfSettings?.images ? [{ text: 'Imagen', style: 'tableHeader' }] : [],
          { text: 'Categoria', style: 'tableHeader' },
          { text: 'Producto', style: 'tableHeader' },
          { text: 'Item', style: 'tableHeader' },
          { text: 'Clave (SAT)', style: 'tableHeader' },
          { text: 'Unidad (SAT)', style: 'tableHeader' },
          { text: 'Moneda', style: 'tableHeader' },
          { text: 'Precio U', style: 'tableHeader' },
        ],
        // Pre-fetch `maps.itemId.get(item[itemString])` to avoid redundant calls
        ...(await Promise.all(
          data!.pricelistitems!.map(async (item: Pricelistitem, index: number) => {
            const type = data.type
            const resolvedItem = type === 'products'
              ? (maps.itemId.get(item.productitemId) as Productitem & { product: Product })
              : (maps.itemId.get(item.serviceitemId) as Serviceitem & { service: Service })
            const categoryName = type === 'products'
              ? maps.categoryId.get(resolvedItem?.product?.categoryId)?.name
              : maps.categoryId.get(resolvedItem?.service?.categoryId)?.name
            const parentName = type === 'products'
              ? resolvedItem?.product?.name
              : resolvedItem?.service?.name
            const satProductServiceKey = type === 'products'
              ? resolvedItem?.product?.fiscalData?.satProductServiceKey
              : resolvedItem?.service?.fiscalData?.satProductServiceKey
            const satUnitKey = resolvedItem?.fiscalData?.satUnitKey

            return [
              { text: index + 1, alignment: 'center', style: 'tableRow' },
              { text: resolvedItem?.sku, alignment: 'center', style: 'tableRow' },
              ...(data.pdfSettings?.images
                ? [
                    resolvedItem?.image
                      ? { image: await getBase64ImageFromURL(resolvedItem.image), width: 40, height: 40, margin: [5, 5, 5, 5] }
                      : { text: '' },
                  ]
                : []),
              { text: categoryName, alignment: 'left', style: 'tableRow' },
              { text: parentName, alignment: 'left', style: 'tableRow' },
              { text: resolvedItem?.name, alignment: 'left', style: 'tableRow' },
              { text: satProductServiceKey, alignment: 'center', style: 'tableRow' },
              { text: satUnitKey, alignment: 'center', style: 'tableRow' },
              { text: item.currency, alignment: 'center', style: 'tableRow' },
              { text: toCurrency(Number(item.basePrice), defaultAppVariables.currency as 'MXN' | 'USD'), alignment: 'right', style: 'tableRow' },
            ]
          }),
        )),

      ].filter(Boolean),
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0 : 0.1
      },
      vLineWidth: function (i) { return 0 },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? 'white' : 'gray'
      },
      vLineColor: function (i) { return 'white' },
    },
  }

  return {
    language: 'es-MX',
    compress: true,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionPricelistitems,
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            { text: [
              { text: 'Generado con ' },
              { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              { text: ' ' },
              { text: `el ${new Date().toLocaleDateString()}` }],
            }],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: data.pdfSettings?.pageSize || 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsPricelists: ViewSettings = {
  tableName: 'pricelists',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Fecha entrada en vigor',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsPricelists.type },
    { label: 'Estado', value: 'status', options: optionsPricelists.status },
  ],
}
