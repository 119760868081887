import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultCategory = (data: Partial<Category>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    tags: [],
    data: getDefaultCategoryData(data?.data ?? {}),
  }, data)
}

export const getCategoryInformation = (data: Partial<Category>) => {
  if (!data) return []
  return [
    ['Nombre', data?.name],
    ['Descripción', data?.description],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsCategories = {} satisfies Options

export const optionsCategoriesMap = {}

export const relationsCategories: Record<string, boolean> = {
  products: true,
  services: true,
  files: true,
  // options: true,
}

export type CategoryWithRelations = Category & {
  products: Product[]
  services: Service[]
  files: File[]
  options: Option[]
}

export const viewSettingsCategories: ViewSettings = {
  tableName: 'categories',
  typeOptions: optionsShared.views.filter(view => ['grid', 'gallery'].includes(view.value)),
  type: 'gallery',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
