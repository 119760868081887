<script setup lang="ts">
const model = defineModel<string | number>()

const props = defineProps<{
  currency?: string
  icon?: boolean
  min?: number
  max?: number
}>()

const displayModel = computed({
  get: () => model.value,
  set: (value: number) => model.value = Math.round(value * 100) / 100,
})
</script>

<template>
  <UInput
    v-model="displayModel"
    type="number"
    inputmode="decimal"
    icon="i-mdi-currency-usd"
    :min="min ?? 0.00"
    :max="props.max"
    :step="0.01"
    v-bind="$attrs"
  >
    <!-- Not Workign if text is not right aligned -->
    <!-- <template #leading>
      <UIcon
        v-if="icon"
        name="i-mdi-currency-usd"
        class="w-4 h-4"
      />
      {{ currency }}
    </template> -->
  </UInput>
</template>
