<script setup lang="ts">
import type { Productitem } from '#netzo/shared/types/db'
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Productitem>
  onSubmit: (event: FormSubmitEvent<Partial<Productitem>>) => void
  openNestedCallback: (data: Partial<Productitem>) => void
  title?: string
  disabledFields?: (keyof Productitem | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Productitem>>(props.data)

const productitems$ = useProductitems()

const $products = await useFetch<ProductWithRelations[]>('/api/db/products', {
  query: { $with: relationsProducts },
  default: () => [],
})

const utils = useProductitemsUtils({ $products })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.productitems,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
    {
      slot: 'fiscalData',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateProductId = async (productId: string) => {
  const product = utils.maps.productId.value.get(productId)
  state.value.image = product?.image
  state.value.description = product?.description
}

if (['create'].includes(props.action) && props.data.productId) {
  onUpdateProductId(props.data.productId)
  state.value.name = undefined
}

const loading = ref(false)

const onSubmitProductitem = async (event: FormSubmitEvent<Partial<Productitem>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.productitems"
      :validate-on="['submit']"
      :schema="productitems$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProductitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Producto"
            name="productId"
            :required="isRequired(productitems$.schema, 'productId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'products',
                idField: 'productId',
                utils: utils,
                options: utils.options.productId.value,
                disabled: disabledFields?.includes('productId'),
                onClickUpdate: onUpdateProductId,
                slideoverOpenCreateProps: {},
                refresh: $products.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(productitems$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Unidad (SAT)"
            name="fiscalData.satUnitKey"
            :required="isRequired(productitems$.schema, 'fiscalData.satUnitKey')"
          >
            <SelectMenuBase
              v-model="state.fiscalData.satUnitKey"
              :options="optionsSAT.productUnitId"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Producto"
            name="productId"
            :required="isRequired(productitems$.schema, 'productId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'products',
                idField: 'productId',
                utils: utils,
                options: utils.options.productId.value,
                disabled: disabledFields?.includes('productId'),
                onClickUpdate: onUpdateProductId,
                slideoverOpenCreateProps: {},
                refresh: $products.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(productitems$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
              class="flex-1"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(productitems$.schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              table-name="productitems"
              :record-id="state.id"
            />
          </UFormGroup>

          <UFormGroup
            label="SKU"
            name="sku"
            :required="isRequired(productitems$.schema, 'sku')"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.sku"
                :disabled="disabledFields?.includes('sku')"
                class="flex-1"
              />
              <ButtonGenerateString disabled />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="productitems"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(productitems$.schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              resize
              :disabled="disabledFields?.includes('description')"
            />
          </UFormGroup>
        </template>

        <template #fiscalData>
          <UFormGroup
            label="Unidad (SAT)"
            name="fiscalData.satUnitKey"
            :required="isRequired(productitems$.schema, 'fiscalData.satUnitKey')"
          >
            <SelectMenuBase
              v-model="state.fiscalData.satUnitKey"
              :options="optionsSAT.productUnitId"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.productitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
