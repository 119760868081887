<script setup lang="ts">
const props = defineProps<{
  tableName?: string
}>()

const tableNameSelected = ref<string>(props.tableName)
const optionsMap = computed(() => useTableOptionsMap(tableNameSelected.value))

const query = ref('')

// TODO: filter links to only modules available to user
const LINKS = optionsShared.tableName.map(link => ({
  ...link,
  label: link.labelFull ?? link.label,
  onClick: () => tableNameSelected.value = link.value,
}))

const links = computed(() => LINKS
  .filter(link => link.label.toLowerCase().includes(query.value.toLowerCase())),
)

const option = computed<Option>(() => optionsShared.tableName.find(option => option.value === tableNameSelected.value)!)

// options:

const isNested = (item: string) => {
  const option = optionsMap.value[item]
  return typeof option === 'object' && !Array.isArray(option)
}

const getNestedOptions = (item: string) => {
  return optionsMap.value[item] as Record<string, Option[]> | undefined
}
</script>

<template>
  <UDashboardSlideover>
    <template #title>
      <UButton
        v-if="tableNameSelected"
        color="gray"
        variant="ghost"
        size="xs"
        icon="i-mdi-arrow-left"
        @click="tableNameSelected = undefined"
      />

      {{ tableNameSelected ? option?.labelFull ?? option?.label : 'Documentación' }}
    </template>

    <div v-if="tableNameSelected" class="space-y-6 text-sm">
      <video
        v-if="option?.video"
        class="w-full"
        controls
        :src="option?.video"
      />

      <p class="text-gray-600" v-html="option?.description" />

      <div
        v-for="(options, label) in optionsMap"
        :key="`docs-option-${label}`"
        class="space-y-2"
      >
        <details open class="space-y-2">
          <summary class="cursor-pointer font-bold text-gray-700">
            {{ label }}
          </summary>
          <div v-if="!isNested(label)">
            <UTable
              :rows="options"
              :columns="[
                { key: 'label', label: 'Nombre' },
                { key: 'description', label: 'Descripción' },
              ]"
              :ui="{
                th: { base: 'hidden' },
                td: { padding: 'py-2 px-3 text-wrap text-pretty text-xs' },
              }"
            >
              <template #label-data="{ row }">
                <div class="w-full contents mx-4 text-xs">
                  <UBadge
                    variant="subtle"
                    :icon="row.icon"
                    :label="row.label"
                    :color="row.color ? row.color : 'gray'"
                    size="xs"
                    class="mx-auto text-xs"
                  />
                </div>
              </template>
            </UTable>
          </div>
          <div v-else-if="isNested(label)" class="space-y-4">
            <details
              v-for="(nestedOptions, nestedLabel) in getNestedOptions(label)"
              :key="nestedLabel"
              class="space-y-2"
            >
              <summary class="cursor-pointer font-semibold text-gray-600">
                {{ nestedLabel }}
              </summary>
              <div class="">
                <UTable
                  :rows="nestedOptions"
                  :columns="[
                    { key: 'label', label: 'Nombre' },
                    { key: 'description', label: 'Descripción' },
                  ]"
                  :ui="{
                    th: { base: 'hidden' },
                    td: { padding: 'py-2 px-3 text-wrap text-pretty text-xs' },
                  }"
                >
                  <template #label-data="{ row }">
                    <div class="w-full contents mx-4 text-xs">
                      <UBadge
                        variant="subtle"
                        :label="row.label"
                        :color="row.color ? row.color : 'gray'"
                        size="xs"
                        class="mx-auto text-xs"
                      />
                    </div>
                  </template>
                </UTable>
              </div>
            </details>
          </div>
        </details>
      </div>
    </div>

    <div v-else class="flex flex-col gap-y-3">
      <UInput
        v-model="query"
        icon="i-mdi-magnify"
        placeholder="Search..."
        autofocus
        color="gray"
      />

      <UButton
        v-for="(link, index) in links"
        :key="index"
        color="white"
        v-bind="link"
      />
    </div>
  </UDashboardSlideover>
</template>
