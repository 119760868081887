import { get } from 'es-toolkit/compat'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { flatten } from 'flat'

export const COLUMNS_METADATA: Column[] = [
  {
    key: 'immutable',
    label: 'Bloqueado',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'createdBy',
    label: 'Creado por',
  },
  {
    key: 'createdAt',
    label: 'Fecha de creación',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'updatedBy',
    label: 'Actualizado por',
  },
  {
    key: 'updatedAt',
    label: 'Fecha de actualización',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'archivedBy',
    label: 'Archivado por',
  },
  {
    key: 'archivedAt',
    label: 'Fecha de archivado',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
]

// IMPORTANT: useAsyncData/useFetch cannot return null or undefined
export const getUseAsyncDataArgsViewSettings = <T>(key: string, viewSettings: ViewSettings) => [
  key,
  async () => {
    const $fetch = useRequestFetch() // IMPORTANT: send cookies along on SSR (pending merge https://github.com/nuxt/nuxt/issues/24813)
    return await $fetch(`/api/kv/${key}`) ?? viewSettings // no need to persist until updated for first time
  },
  {
    key,
    shallow: false,
    default: () => viewSettings,
  },
]

export function useView<T>(key: string, view: Ref<ViewSettings>) {
  view.value.visibleColumns ||= view.value.columns.map(c => c.key).filter(key => !COLUMNS_METADATA.map(c => c.key).includes(key))

  // NOTE: expose a readonly `columns` built from `view.visibleColumns` to ensure
  // proper column order when toggling columns on/off (otherwise columns lose order)
  const columns = computed<Column[]>(() => {
    const metadataColumns = COLUMNS_METADATA.map(c => c.key)
    const {
      columns,
      visibleColumns = view.value.columns.map(c => c.key),
    } = view.value
    return columns.filter(column => visibleColumns.includes(column.key)) ?? []
  })

  const selected = ref<T[]>([] as T[])

  async function onUpdateView(value: ViewSettings) {
    view.value = value
    await $fetch(`/api/kv/${key}`, { method: 'PUT', body: value })
  }

  return {
    columns,
    selected,
    onUpdateView,
  }
}

// query:

// filters: capture as ...filters using rest operator (object required for using ...rest operator)
export type ViewQuery = object & {
  archivedAt?: '$isNull' | '$isNotNull'
  $search?: { columns: string[], value: string }
  $sort?: { column: string, direction: 'asc' | 'desc' }
  $pagination?: { page: number, pageSize: number }
  $with?: Record<string, boolean>
} & {
  [k: `${string}:$gte`]: string
  [k: `${string}:$lte`]: string
  [k: `${string}:$gt`]: string
  [k: `${string}:$lt`]: string
  [k: string]: string | string[] | unknown // requires unknown
}

export function useViewQuery(initialQuery: ViewQuery) {
  const query = ref<ViewQuery>({ ...initialQuery })
  const isFiltering = computed(() => {
    const { $search, $sort, $pagination, $with, ...allFilters } = query.value
    const { archivedAt, ...filters } = allFilters // exclude special filters
    return Object.entries(filters).some(([key, value]) => {
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'string') return ![null, undefined, ''].includes(value)
      return value !== undefined
    })
  })
  const clearFilters = () => query.value = { ...initialQuery }
  return { query, isFiltering, clearFilters }
}

// pagination:

export function useViewPagination<T>(view: Ref<ViewSettings>, allRows: Ref<T[]>) {
  const { page = 1, pageSize = 25 } = unref(view).pagination ?? {}

  const total = computed(() => unref(allRows)?.length ?? 0)

  const rows = computed(() => {
    return unref(allRows)?.slice((page - 1) * pageSize, page * pageSize) ?? []
  })

  return { rows, total }
}

// server-pagination:

export type Paginated<T> = {
  total: number // <total number of items>
  limit: number // <max number of items per page>
  offset: number // <number of skipped items (offset)>
  data: T[]
} // inspired by https://feathersjs.com/api/databases/common#pagination

export function useViewServerPagination<T>(view: Ref<ViewSettings>, result: Ref<Paginated<T>>) {
  const data = computed<T[]>(() => unref(result)?.data ?? [] as T[])

  const total = computed(() => unref(result)?.total ?? 0)

  const rows = computed(() => unref(result)?.data ?? [])

  return { data, rows, total }
}

// download:

export function useViewDownload() {
  // reduce all rows into a rows with column.label as key, and only keep visibleColumns
  const getCsvRows = <T>(columns: Column[], rows: T[]) => {
    const flatData = rows.map(row => flatten(row))
    const cols = columns.filter(column => !['select', 'actions', 'tags', 'files'].includes(column.key))
    const extendedCols = [{ key: 'id', label: 'ID' }, ...cols]
    return flatData.map((originalRow, index) => {
      return extendedCols.reduce((row, col) => {
        let value = get(originalRow, col.key)
        value ??= '' // cast null/undefined values to empty strings "" for export
        row[col.label ?? col.key] = typeof value === 'string' ? value : JSON.stringify(value)
        return row
      }, {})
    })
  }

  // see https://medium.com/@j.lilian/how-to-export-react-tanstack-table-to-csv-file-722a22ccd9c5
  function downloadDataAsCsv<T>(
    flatData: T[],
    filename: string,
    columnHeaders?: Array<string | { key: string, displayLabel: string }>,
  ) {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename, // export file name (without .csv)
      decimalSeparator: '.',
      ...(columnHeaders ? { columnHeaders } : { useKeysAsHeaders: true }),
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csv = generateCsv(csvConfig)<any>(flatData)
    download(csvConfig)(csv)
  }

  return {
    getCsvRows,
    downloadDataAsCsv,
  }
}
