import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultContact = (data: Partial<Contact>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    tags: [],
    data: getDefaultContactData(data?.data ?? {}),
  }, data)
}

export const getContactInformation = (data: Partial<Contact>) => {
  if (!data) return []
  return [
    ['Titulo', data.title],
    ['Nombre', data.name],
    ['Correo', data.email],
    ['Teléfono', toPhoneString(data.phone)],
  ].map(([key, value]) => [key, value || '-'])
}
export const optionsContacts = {} satisfies Options

export const optionsContactsMap = {
}

export const relationsContacts: Record<string, boolean> = {
  account: true,
  branch: true,
  supplier: true,
  user: true,
  files: true,
  // options: true,
}

export type ContactWithRelations = Contact & {
  account: Account
  branch: Branch
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsContacts: ViewSettings = {
  tableName: 'contacts',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'title',
      label: 'Titulo',
      sortable: true,
    },
    {
      key: 'phone',
      label: 'Teléfono',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Correo',
      sortable: true,
    },
    // {
    //   key: 'image',
    //   label: 'Imágen',
    //   class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    // },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'branch.name',
      label: 'Sucursal',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
