<script setup lang="ts">
import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

const model = defineModel<BlobObject[]>()

const props = defineProps<{
  tableName: TableName
  recordId: string
  type?: string
  accept?: string // comma-separated list of MIME types e.g. 'image/svg+xml, image/png'
  icon?: string
  options?: BlobPutOptions
  data?: Partial<File>
}>()

const { tableName, recordId, type = 'files' } = props

const { createRenamedFile, uploadFile, removeFile } = useApiBlob(tableName, recordId)

const id = useId()

const loading = ref(false)

const onChange = async (fileList: FileList) => {
  loading.value = true
  const files = Array.from(fileList)

  // DISABLED: do not enforce file maxSize limit
  // const filesIncluded = files.filter(file => file.size <= 1e6)
  // const filesExcluded = files.filter(file => file.size > 1e6)

  // const data = await Promise.all(filesIncluded.map(file => uploadFile(type, file, props.options, props.data)))
  // model.value = [...(model.value ?? []), ...data]

  // if (filesExcluded.length) {
  //   alert(filesExcluded.length > 1
  //     ? `${filesExcluded.length} archivos exceden el tamaño máximo permitido de 1MB`
  //     : 'El archivo excede el tamaño máximo permitido de 1MB',
  //   )
  // }

  const data = await Promise.all(files.map(file => uploadFile(type, file, props.options, props.data)))
  model.value = [...(model.value ?? []), ...data]
  document.getElementById(id).value = null
  loading.value = false
}

const onRemove = async (file: BlobObject) => {
  await removeFile(file)
  model.value = model.value?.filter(f => f.pathname !== file.pathname)
  document.getElementById(id).value = null
}
</script>

<template>
  <UInput
    :id="id"
    v-bind="$attrs"
    type="file"
    name="file"
    multiple
    :accept="accept"
    @change="onChange"
  >
    <UProgress
      v-if="loading"
      animation="carousel"
      size="sm"
      class="px-1"
    />
    <div v-if="model?.length" class="w-full flex flex-wrap gap-1 items-center mt-1">
      <span
        v-for="(file, i) in model"
        :key="`${file.pathname}-${i}`"
      >
        <ButtonFile
          v-if="file && Object.entries(file)?.length"
          :file="file"
          :on-remove="onRemove"
        />
      </span>
    </div>
  </UInput>
</template>
