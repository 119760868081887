import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { Rfc } from '@nodecfdi/rfc'
import { merge } from 'es-toolkit/compat'

export const getDefaultBilling = (data: Account['billing']) => merge({
  address: {
    country: 'Mexico',
  },
}, data)

export const getDefaultBillingGenericRFC = (data?: Account['billing']) => merge({
  type: 'genericRFC',
  taxId: Rfc.RFC_GENERIC, // 'XAXX010101000',
  taxCountryCode: 'MX',
  legalName: 'PÚBLICO EN GENERAL',
  satFiscalRegime: '616',
  address: defaultLegalInformation.address,
}, data)

export const getDefaultBillingForeignLegalEntity = (data?: Account['billing']) => merge({
  type: 'foreignLegalEntity',
  taxId: Rfc.RFC_FOREIGN, // 'XEXX010101000',
  satFiscalRegime: '609',
  address: {},
}, data)

export const getDefaultAccount = (data: Partial<Account>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'prospect',
    billing: {
      address: {},
    },
    payment: defaultPayment,
    metrics: {
      purchaseFrequency: null,
      purchaseValue: null,
      lastPurchasedAt: null,
    },
    tags: [],
    data: getDefaultAccountData(data?.data ?? {}),
  }, data)
}

export const getAccountInformation = (data: Partial<AccountWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsAccounts.type, 'value'),
    billing: toMapByKey(optionsAccounts.billing.type, 'value'),
  }
  if (!data) return []
  return [
    ['Nombre', data?.name],
    ['Tipo', maps.type.get(data?.type ?? '')?.label],
    ['Pagina web', data?.domain],
    ['Tipo de entidad', maps.billing.get(data.billing?.type ?? '')?.label],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsAccounts = {
  type: [
    { value: 'prospect', label: 'Prospecto', description: 'Un prospecto es un cliente potencial que aún no ha mostrado interés directo en nuestros productos o servicios, pero que puede convertirse en cliente con el tiempo.', color: 'gray', icon: 'i-mdi-account-search' },
    { value: 'lead', label: 'Lead', description: 'Un lead es una persona o empresa que ha mostrado interés en los productos o servicios a través de acciones como llenar un formulario o pedir más información.', color: 'lime', icon: 'i-mdi-account-tie' },
    { value: 'customer', label: 'Cliente', description: 'Un cliente es una persona o empresa que ha realizado al menos una compra o contrato con nuestra empresa.', color: 'green', icon: 'i-mdi-account' },
    { value: 'premium', label: 'Premium', description: 'Clientes Premium son aquellos que tienen un nivel especial de preferencia debido a su alto valor o importancia para la empresa.', color: 'yellow', icon: 'i-mdi-crown' },
    { value: 'inactive', label: 'Inactivo', description: 'Una cuenta que no ha interactuado con la empresa en un largo período de tiempo o que ya no está activa.', color: 'gray', icon: 'i-mdi-account-off' },
  ],
  billing: optionsBilling,
  metrics: {
    purchaseFrequency: [
      { value: 'daily', label: 'Diario', description: 'Compra realizada diariamente.', color: 'sky' },
      { value: 'weekly', label: 'Semanal', description: 'Compra realizada semanalmente.', color: 'lime' },
      { value: 'biweekly', label: 'Quincenal', description: 'Compra realizada cada quince días.', color: 'indigo' },
      { value: 'monthly', label: 'Mensual', description: 'Compra realizada mensualmente.', color: 'orange' },
      { value: 'quarterly', label: 'Trimestral', description: 'Compra realizada trimestralmente.', color: 'yellow' },
      { value: 'biannual', label: 'Semestral', description: 'Compra realizada cada seis meses.', color: 'amber' },
      { value: 'annual', label: 'Anual', description: 'Compra realizada anualmente.', color: 'green' },
    ],
    purchaseValue: [
      { value: 'low', label: 'Bajo', description: 'Compra de bajo valor.', color: 'gray' },
      { value: 'medium', label: 'Medio', description: 'Compra de valor medio.', color: 'yellow' },
      { value: 'high', label: 'Alto', description: 'Compra de alto valor.', color: 'orange' },
      { value: 'veryHigh', label: 'Muy alto', description: 'Compra de muy alto valor.', color: 'red' },
    ],
  },
} satisfies Options

export const optionsAccountsMap = {
  Tipo: optionsAccounts.type,
  Facturación: {
    'Tipo de entidad': optionsAccounts.billing.type,
    'Uso de CFDI': optionsSAT.taxCfdiUse,
    'Método': optionsSAT.paymentMethod,
    'Forma': optionsSAT.paymentForm,
    'Condiciones': optionsAccounts.billing.paymentConditions,
  },
  Métricas: {
    'Frecuencia de Compra': optionsAccounts.metrics.purchaseFrequency,
    'Valor de Compra': optionsAccounts.metrics.purchaseValue,
  },
}

export const relationsAccounts: Record<string, boolean> = {
  accountgroup: true,
  activities: true,
  branches: true,
  contacts: true,
  deals: true,
  industry: true,
  productionorders: true,
  quotes: true,
  salesorders: true,
  transactions: true,
  user: true,
  fileCsfPdf: true,
  files: true,
  // options: true,
}

export type AccountWithRelations = Account & {
  activities: Activity[]
  branches: Branch[]
  contacts: Contact[]
  deals: Deal[]
  industry: Industry
  quotes: Quote[]
  salesorders: Salesorder[]
  services: Service[]
  transactions: Transaction[]
  user: User
  files: File[]
  options: Option[]
}

export const viewSettingsAccounts: ViewSettings = {
  tableName: 'accounts',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'domain',
      label: 'Dominio',
      sortable: true,
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
    },
    {
      key: 'industry.name',
      label: 'Industria',
      sortable: true,
    },
    {
      key: 'fileCsfPdf',
      label: 'CSF',
    },
    {
      key: 'deals',
      label: 'Negocios',
    },
    {
      key: 'billing.type',
      label: 'Tipo entidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'billing.taxCountryCode',
      label: 'País',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'billing.taxId',
      label: 'RFC (Tax ID)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.conditions',
      label: 'Condiciones pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.satPaymentMethod',
      label: 'Método pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.satPaymentForm',
      label: 'Forma pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.purchaseFrequency',
      label: 'Frecuencia de compra',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.purchaseValue',
      label: 'Valor de compra promedio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    }, {
      key: 'metrics.lastPurchasedAt',
      label: 'Fecha de última compra',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
