import type { User } from '#auth-utils'

type ModuleId = undefined | keyof User['modules']

// GET /db/:tableName
export const read = defineAbility((user: User, moduleId: ModuleId) => {
  if (!moduleId) return true
  const moduleOptions = user?.modules?.[moduleId]
  if (!moduleOptions || moduleOptions.enabled !== true) return false
  return ['admin', 'edit', 'view'].includes(moduleOptions.role)
})

// POST /db/:tableName
export const create = defineAbility((user: User, moduleId: ModuleId) => {
  if (!moduleId) return true
  const moduleOptions = user?.modules?.[moduleId]
  if (!moduleOptions || moduleOptions.enabled !== true) return false
  return ['admin', 'edit'].includes(moduleOptions.role)
})

// PATCH /db/:tableName/:id (including soft delete for archiving feature)
export const update = defineAbility((user: User, moduleId: ModuleId) => {
  if (!moduleId) return true
  const moduleOptions = user?.modules?.[moduleId]
  if (!moduleOptions || moduleOptions.enabled !== true) return false
  return ['admin', 'edit'].includes(moduleOptions.role)
})

// DELETE /db/:tableName/:id
export const del = defineAbility((user: User, moduleId: ModuleId) => {
  if (!moduleId) return true
  const moduleOptions = user?.modules?.[moduleId]
  if (!moduleOptions || moduleOptions.enabled !== true) return false
  return ['admin'].includes(moduleOptions.role)
})

// GET /db/:tableName
export const download = defineAbility((user: User, moduleId: ModuleId) => {
  if (!moduleId) return true
  const moduleOptions = user?.modules?.[moduleId]
  if (!moduleOptions || moduleOptions.enabled !== true) return false
  return ['admin'].includes(moduleOptions.role)
})
