// see http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/GuiallenadoCFDIglobal311221.pdf
// see https://soporte.enlacefiscal.com/article/200-objetos-de-impuesto-4-0#:~:text=(02)%20S%C3%AD%20objeto%20del%20impuesto,Importaci%C3%B3n%20de%20bienes%20o%20servicios.
// see https://www.gncys.com/anexo20/usocfdi
export const optionsSAT = {
  cfdiType: [
    { value: 'I', label: 'I - Ingreso', description: 'Facturas, Recibos de Honorarios, Recibos de Arrendamiento' },
    { value: 'E', label: 'E - Egreso', description: 'Notas de Crédito' },
    { value: 'T', label: 'T - Traslado', description: 'Complemento Carta Porte' },
    { value: 'N', label: 'N - Nómina', description: 'Recibos de Nómina, Salarios, Asimilados a Salarios' },
    { value: 'P', label: 'P - Pago', description: 'Pagos en parcialidades' },
    { value: 'R', label: 'R - Retenciones', description: 'Documentación de retenciones de impuestos' },
  ],
  productUnitId: [
    { value: 'H03', label: 'H03 - Henry por kiloOhm' },
    { value: 'H04', label: 'H04 - Henry por Ohm' },
    { value: 'H05', label: 'H05 - Milihenry por kiloOhm' },
    { value: 'H06', label: 'H06 - Milihenry por Ohm' },
    { value: 'H07', label: 'H07 - Pascal segundo por bar' },
    { value: 'H08', label: 'H08 - Microbequerel' },
    { value: 'H09', label: 'H09 - Año recíproco' },
    { value: 'H10', label: 'H10 - Hora recíproca' },
    { value: 'H11', label: 'H11 - Mes recíproco' },
    { value: 'H12', label: 'H12 - Grado celsius por hora' },
    { value: 'H13', label: 'H13 - Grado celsius por minuto' },
    { value: 'H14', label: 'H14 - Grado celsius por segundo' },
    { value: 'H15', label: 'H15 - Centímetro cuadrado por gramo' },
    { value: 'H16', label: 'H16 - Decámetro cuadrado' },
    { value: 'H18', label: 'H18 - Hectómetro cuadrado' },
    { value: 'H19', label: 'H19 - Hectómetro cúbico' },
    { value: 'H20', label: 'H20 - Kilómetro cúbico' },
    { value: 'H21', label: 'H21 - Blanco' },
    { value: 'H22', label: 'H22 - Voltio pulgada cuadrada por libra fuerza' },
    { value: 'H23', label: 'H23 - Voltio por pulgada' },
    { value: 'H24', label: 'H24 - Voltio por microsegundo' },
    { value: 'H25', label: 'H25 - Por ciento por kelvin' },
    { value: 'H26', label: 'H26 - Ohm por metro' },
    { value: 'H27', label: 'H27 - Grado por metro' },
    { value: 'H28', label: 'H28 - Microfaradio por kilómetro' },
    { value: 'H29', label: 'H29 - Microgramo por litro' },
    { value: 'H30', label: 'H30 - Micrómetro cuadrado' },
    { value: 'H31', label: 'H31 - Amperio por kilogramo' },
    { value: 'H32', label: 'H32 - Amperio cuadrado segundo' },
    { value: 'H33', label: 'H33 - Faradio por kilómetro' },
    { value: 'H34', label: 'H34 - Hertz metro' },
    { value: 'H35', label: 'H35 - Kelvin metro por watt' },
    { value: 'H36', label: 'H36 - MegaOhm por kilómetro' },
    { value: 'H37', label: 'H37 - MegaOhm por metro' },
    { value: 'H38', label: 'H38 - Megaamperio' },
    { value: 'H39', label: 'H39 - Megahertz kilómetro' },
    { value: 'H40', label: 'H40 - Newton por amperio' },
    { value: 'H41', label: 'H41 - Newton metro watts elevado a la potencia menos 0.5' },
    { value: 'H42', label: 'H42 - Pascal por metro' },
    { value: 'H43', label: 'H43 - Siemens por centímetro' },
    { value: 'H44', label: 'H44 - TeraOhm' },
    { value: 'H45', label: 'H45 - Voltio segundo por metro' },
    { value: 'H46', label: 'H46 - Voltio por segundo' },
    { value: 'H47', label: 'H47 - Watt por metro cúbico' },
    { value: 'H48', label: 'H48 - Attofarad' },
    { value: 'H49', label: 'H49 - Centímetro por hora' },
    { value: 'H50', label: 'H50 - Reciprocidad del centímetro cúbico' },
    { value: 'H51', label: 'H51 - Decibel per kilometro' },
    { value: 'H52', label: 'H52 - Decibel per metro' },
    { value: 'H53', label: 'H53 - Kilogramo por bar' },
    { value: 'H54', label: 'H54 - Kilogramo por decímetro cúbico kelvin' },
    { value: 'H55', label: 'H55 - Kilogramo por decímetro cúbico bar' },
    { value: 'H56', label: 'H56 - Kilogramo por metro cuadrado segundo' },
    { value: 'H57', label: 'H57 - Pulgada por dos pi por radián' },
    { value: 'H58', label: 'H58 - Metro por voltio segundo' },
    { value: 'H59', label: 'H59 - Metro cuadrado por newton' },
    { value: 'H60', label: 'H60 - Metro cúbico por metro cúbico' },
    { value: 'H61', label: 'H61 - Milisiemens por centímetro' },
    { value: 'H62', label: 'H62 - Milivoltio por minuto' },
    { value: 'H63', label: 'H63 - Miligramo por centímetro cuadrado' },
    { value: 'H64', label: 'H64 - Miligramo por gramo' },
    { value: 'H65', label: 'H65 - Mililitro por metro cúbico' },
    { value: 'H66', label: 'H66 - Milímetro por año' },
    { value: 'H67', label: 'H67 - Milímetro por hora' },
    { value: 'H68', label: 'H68 - MiliMol por gram' },
    { value: 'H69', label: 'H69 - Picopascal por kilometro' },
    { value: 'H70', label: 'H70 - Picosegundo' },
    { value: 'H71', label: 'H71 - Por ciento al mes' },
    { value: 'H72', label: 'H72 - Por ciento por hectobar' },
    { value: 'H73', label: 'H73 - Por ciento por decakelvin' },
    { value: 'H74', label: 'H74 - Watt por metro' },
    { value: 'H75', label: 'H75 - Decapascal' },
    { value: 'H76', label: 'H76 - Gramo por milímetro' },
    { value: 'H77', label: 'H77 - Anchura del módulo' },
    { value: 'H78', label: 'H78 - Centímetro convencional de agua' },
    { value: 'H79', label: 'H79 - Escala francesa' },
    { value: 'H80', label: 'H80 - Unidad de bastidor' },
    { value: 'H81', label: 'H81 - Milímetro por minuto' },
    { value: 'H82', label: 'H82 - Punto grande' },
    { value: 'H83', label: 'H83 - Litro por kilogramo' },
    { value: 'H84', label: 'H84 - Gramos milímetro' },
    { value: 'H85', label: 'H85 - Semana recíproca' },
    { value: 'H86', label: 'H86 - Semana' },
    { value: 'H87', label: 'H87 - Pieza' },
    { value: 'H88', label: 'H88 - MegaOhm kilómetro' },
    { value: 'H89', label: 'H89 - Por ciento por Ohmio' },
    { value: 'H90', label: 'H90 - Porcentaje por grado' },
    { value: 'H91', label: 'H91 - Por ciento por cada diez mil' },
    { value: 'H92', label: 'H92 - Ciento por cien mil' },
    { value: 'H93', label: 'H93 - Porcentaje por cien' },
    { value: 'H94', label: 'H94 - Por ciento por mil' },
    { value: 'H95', label: 'H95 - Por ciento por voltio' },
    { value: 'H96', label: 'H96 - Tanto por ciento por bar' },
    { value: 'H97', label: 'H97 - Por ciento por pulgada' },
    { value: 'H98', label: 'H98 - Por ciento por metro' },
    { value: 'HA', label: 'HA - Madeja' },
    { value: 'HAR', label: 'HAR - Hectárea' },
    { value: 'HBA', label: 'HBA - Hectobar' },
    { value: 'HBX', label: 'HBX - Ciento de cajas' },
    { value: 'HC', label: 'HC - Conteo en cientos' },
    { value: 'HD', label: 'HD - Media docena' },
    { value: 'HDW', label: 'HDW - Cien kilogramos, peso seco' },
    { value: 'HE', label: 'HE - Centésima de un quilate' },
    { value: 'HEA', label: 'HEA - Cabeza' },
    { value: 'HF', label: 'HF - Cien pies' },
    { value: 'HGM', label: 'HGM - Hectogramo' },
    { value: 'HH', label: 'HH - Cien pies cúbicos' },
    { value: 'HI', label: 'HI - Cien hojas' },
    { value: 'HIU', label: 'HIU - Unidad internacional de cien' },
    { value: 'HJ', label: 'HJ - Potencia métrica' },
    { value: 'HK', label: 'HK - Cien kilogramos' },
    { value: 'HKM', label: 'HKM - Cien kilogramos, masa neta' },
    { value: 'HL', label: 'HL - Cien pies (lineal)' },
    { value: 'HLT', label: 'HLT - Hectolitro' },
    { value: 'HM', label: 'HM - Milla por hora (milla estatal)' },
    { value: 'HMQ', label: 'HMQ - Millones de metros cúbicos' },
    { value: 'HMT', label: 'HMT - Hectómetro' },
    { value: 'HN', label: 'HN - Milímetro convencional de mercurio' },
    { value: 'HO', label: 'HO - Cien onzas troy' },
    { value: 'HP', label: 'HP - Milímetro convencional de agua' },
    { value: 'HPA', label: 'HPA - Hectolitro de alcohol puro' },
    { value: 'HS', label: 'HS - Cien pies cuadrados' },
    { value: 'HT', label: 'HT - Media hora' },
    { value: 'HTZ', label: 'HTZ - Hertz' },
    { value: 'HUR', label: 'HUR - Hora' },
    { value: 'HY', label: 'HY - Cien yardas' },
    { value: 'IA', label: 'IA - Pulgada libra' },
    { value: 'IC', label: 'IC - Contar por pulgada' },
    { value: 'IE', label: 'IE - Personas' },
    { value: 'IF', label: 'IF - Pulgadas de agua' },
    { value: 'II', label: 'II - Columna pulgada' },
    { value: 'IM', label: 'IM - Impresión' },
    { value: 'INH', label: 'INH - Pulgada' },
    { value: 'INK', label: 'INK - Pulgada cuadrada' },
    { value: 'INQ', label: 'INQ - Pulgada cúbica' },
    { value: 'IP', label: 'IP - Póliza de seguros' },
    { value: 'ISD', label: 'ISD - Grado internacional de azúcar' },
    { value: 'IT', label: 'IT - Recuento por centímetro' },
    { value: 'IU', label: 'IU - Pulgada por segundo' },
    { value: 'IV', label: 'IV - Pulgada por segundo al cuadrado' },
    { value: 'EA', label: 'EA - Elemento' },
    { value: 'E48', label: 'E48 - Unidad de Servicio' },
    { value: 'ACT', label: 'ACT - Actividad' },
    { value: 'KGM', label: 'KGM - Kilogramo' },
    { value: 'E51', label: 'E51 - Trabajo' },
    { value: 'A9', label: 'A9 - Tarifa' },
    { value: 'MTR', label: 'MTR - Metro' },
    { value: 'AB', label: 'AB - Paquete a granel' },
    { value: 'BB', label: 'BB - Caja base' },
    { value: 'KT', label: 'KT - Kit' },
    { value: 'SET', label: 'SET - Conjunto' },
    { value: 'LTR', label: 'LTR - Litro' },
    { value: 'XBX', label: 'XBX - Caja' },
    { value: 'MON', label: 'MON - Mes' },
    { value: 'HUR', label: 'HUR - Hora' },
    { value: 'MTK', label: 'MTK - Metro cuadrado' },
    { value: '11', label: '11 - Equipos' },
    { value: 'MGM', label: 'MGM - Miligramo' },
    { value: 'XPK', label: 'XPK - Paquete' },
    { value: 'XKI', label: 'XKI - Kit(Conjunto de piezas)' },
    { value: 'AS', label: 'AS - Variedad' },
    { value: 'GRM', label: 'GRM - Gramo' },
    { value: 'PR', label: 'PR - Par' },
    { value: 'DPC', label: 'DPC - Docenas de piezas' },
    { value: 'xun', label: 'xun - Unidad' },
    { value: 'DAY', label: 'DAY - Día' },
    { value: 'XLT', label: 'XLT - Lote' },
    { value: '10', label: '10 - Grupos' },
    { value: 'MLT', label: 'MLT - Mililitro' },
    { value: 'E54', label: 'E54 - Viaje' },
  ],
  taxCfdiUse: [
    { value: 'G01', label: 'G01 - Adquisición de mercancías' },
    { value: 'G02', label: 'G02 - Devoluciones, descuentos o bonificaciones' },
    { value: 'G03', label: 'G03 - Gastos en general' },
    { value: 'I01', label: 'I01 - Construcciones' },
    { value: 'I02', label: 'I02 - Mobiliario y equipo de oficina por inversiones' },
    { value: 'I03', label: 'I03 - Equipo de transporte' },
    { value: 'I04', label: 'I04 - Equipo de cómputo y accesorios' },
    { value: 'I05', label: 'I05 - Dados, troqueles, moldes, matrices y herramental' },
    { value: 'I06', label: 'I06 - Comunicaciones telefónicas' },
    { value: 'I07', label: 'I07 - Comunicaciones satelitales' },
    { value: 'I08', label: 'I08 - Otra maquinaria y equipo' },
    { value: 'D01', label: 'D01 - Honorarios médicos, dentales y gastos hospitalarios' },
    { value: 'D02', label: 'D02 - Gastos médicos por incapacidad o discapacidad' },
    { value: 'D03', label: 'D03 - Gastos funerales' },
    { value: 'D04', label: 'D04 - Donativos' },
    { value: 'D05', label: 'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)' },
    { value: 'D06', label: 'D06 - Aportaciones voluntarias al SAR' },
    { value: 'D07', label: 'D07 - Primas por seguros de gastos médicos' },
    { value: 'D08', label: 'D08 - Gastos de transportación escolar obligatoria' },
    { value: 'D09', label: 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones' },
    { value: 'D10', label: 'D10 - Pagos por servicios educativos (colegiaturas)' },
    { value: 'CN01', label: 'CN01 - Nómina' },
    { value: 'CP01', label: 'CP01 - Pagos' },
    { value: 'S01', label: 'S01 - Sin efectos fiscales' },
  ],
  fiscalRegime: [
    { value: '601', label: '601 - General de Ley Personas Morales' },
    { value: '603', label: '603 - Personas Morales con Fines no Lucrativos' },
    { value: '605', label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { value: '606', label: '606 - Arrendamiento' },
    { value: '607', label: '607 - Demás ingresos' },
    { value: '608', label: '608 - Consolidación' },
    { value: '609', label: '609 - Residentes en el Extranjero sin Establecimiento Permanente en Mexico' },
    { value: '610', label: '610 - Ingresos por Dividendos (socios y accionistas)' },
    { value: '611', label: '611 - Personas Físicas con Actividades Empresariales y Profesionales' },
    { value: '612', label: '612 - Incorporación Fiscal' },
    { value: '614', label: '614 - Ingresos por intereses' },
    { value: '615', label: '615 - Régimen de los ingresos por obtención de premios' },
    { value: '616', label: '616 - Sin obligaciones fiscales' },
    { value: '620', label: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { value: '621', label: '621 - Incorporación Fiscal para personas morales' },
    { value: '622', label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { value: '623', label: '623 - Opcional para Grupos de Sociedades' },
    { value: '624', label: '624 - Coordinados' },
    { value: '625', label: '625 - Régimen de Enajenación o Adquisición de Bienes' },
    { value: '626', label: '626 - Régimen Simplificado de Confianza' },
  ],
  taxObject: [
    { value: '01', label: '01 - Sin objeto de impuesto' },
    { value: '02', label: '02 - Con objeto de impuesto' },
    // { value: '03', label: '03 - Con objeto de impuesto, no causa impuesto' },
    // { value: '04', label: 'Locales' },
  ],
  taxTypes: [
    { value: '001', label: '001 - ISR' }, // Impuesto sobre la renta
    { value: '002', label: '002 - IVA' }, // Impuesto al valor agregado
    { value: '003', label: '003 - IEPS' }, // Impuesto especial sobre producción y servicios
  ],
  taxFactor: [
    { value: 'rate', label: 'Tasa' }, // Tasa % del total
    { value: 'quota', label: 'Cuota' }, // Cuota fija
    { value: 'exempt', label: 'Exento' },
  ],
  // Traslados: Cuando el emisor del CFDI cobra impuestos al receptor y luego los entera al SAT.
  tax002RateTransfers: [
    { value: 0.160000, label: '16%' },
    { value: 0.080000, label: '8%' },
    { value: 0.040000, label: '4%' },
    { value: 0.000000, label: '0%' },
  ],
  tax003RateTransfers: [
    { value: 1.600000, label: '160%' },
    { value: 0.530000, label: '53%' },
    { value: 0.500000, label: '50%' },
    { value: 0.304000, label: '30.4%' },
    { value: 0.265000, label: '26.5' },
    { value: 0.250000, label: '25%' },
    { value: 0.090000, label: '9%' },
    { value: 0.080000, label: '8%' },
  ],
  // Retenciones: Cuando el emisor del CFDI retiene impuestos que serán enterados al SAT por el emisor.
  tax001RateRetentions: [
    { value: 0.012500, label: '1.25%' },
  ],
  tax002RateRetentions: [
    { value: 0.160000, label: '16%' },
    { value: 0.106666, label: '10.6666%' },
    { value: 0.080000, label: '8%' },
    { value: 0.053333, label: '5.3333%' },
  ],
  // http://omawww.sat.gob.mx/informacion_fiscal/factura_electronica/Documents/Complementoscfdi/catPagos.xls
  paymentForm: [
    { value: '01', label: '01 - Efectivo' },
    { value: '02', label: '02 - Cheque nominativo' },
    { value: '03', label: '03 - Transferencia electrónica de fondos' },
    { value: '04', label: '04 - Tarjeta de crédito' },
    { value: '05', label: '05 - Monedero electrónico' },
    { value: '06', label: '06 - Dinero electrónico' },
    { value: '08', label: '08 - Vales de despensa' },
    { value: '12', label: '12 - Dación en pago' },
    { value: '13', label: '13 - Pago por subrogación' },
    { value: '14', label: '14 - Pago por consignación' },
    { value: '15', label: '15 - Condonación' },
    { value: '17', label: '17 - Compensación' },
    { value: '23', label: '23 - Novación' },
    { value: '24', label: '24 - Confusión' },
    { value: '25', label: '25 - Remisión de deuda' },
    { value: '26', label: '26 - Prescripción o caducidad' },
    { value: '27', label: '27 - A satisfacción del acreedor' },
    { value: '28', label: '28 - Tarjeta de débito' },
    { value: '29', label: '29 - Tarjeta de servicios' },
    { value: '30', label: '30 - Aplicación de anticipos' },
    { value: '31', label: '31 - Intermediario pagos' },
    { value: '99', label: '99 - Por definir' },
  ],
  paymentMethod: [
    { value: 'PUE', label: 'PUE - Pago en una sola exhibición', color: 'lime' },
    { value: 'PPD', label: 'PPD - Pago en parcialidades o diferido', color: 'fuchsia' },
    { value: 'N/A', label: 'N/A - No aplica', color: 'gray' },
  ],
  currency: [
    { value: 'USD', label: 'USD - Dólar Americano', color: 'green' },
    { value: 'MXN', label: 'MXN - Peso Mexicano', color: 'blue' },
  ],
}
