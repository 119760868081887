import { AppServicesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppServicesSlideoverProps = ComponentProps<typeof AppServicesSlideover>

export type ServicesUtils = ReturnType<typeof useServicesUtils>

export const useServices = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Service>('services')

  const slideoverOpenCreate = (props: AppServicesSlideoverProps) => {
    return new Promise<Service>((resolve, reject) => {
      const data = getDefaultService(props.data)
      slideover.open(AppServicesSlideover, {
        title: 'Crear servicio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
        navigateToSubpage: true,
      })
    })
  }

  const slideoverOpenEdit = (props: AppServicesSlideoverProps) => {
    return new Promise<Service>((resolve, reject) => {
      const data = getDefaultService(props.data)
      slideover.open(AppServicesSlideover, {
        title: 'Editar servicio',
        ...props,
        data,
        disabledFields: ['type'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    name: z.string(),
    description: z.string(),
    fiscalData: fiscalDataSchema,
    categoryId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Service, refresh: () => Promise<void>, utils: ServicesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'services', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'services', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'services', row, refresh, utils),
      getDropdownItem('files', 'services', row, refresh, utils),
    ],
    [
      {
        label: 'Crear item',
        icon: ICONS.serviceitems,
        click: () => {
          const serviceitems$ = useServiceitems()
          serviceitems$.slideoverOpenCreate({
            data: { serviceId: row.id },
            disabledFields: ['serviceId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'services', row, refresh, utils),
      getDropdownItem('immutable', 'services', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'services', row, refresh, utils),
      getDropdownItem('delete', 'services', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useServicesUtils = ({ $suppliers, $categories }: {
  $suppliers?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $categories?: Awaited<ReturnType<typeof useFetch<Category[]>>>
} = {}) => {
  const options = {
    ...optionsServices,
    categoryId: computed(() => $categories?.data.value.map(toOption)),
    supplierId: computed(() => $suppliers?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsServices.type, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    categoryId: computed(() => toMapByKey($categories?.data.value, 'id')),
    supplierId: computed(() => toMapByKey($suppliers?.data.value, 'id')),
  }

  return { options, maps }
}
