import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultProduct = (data: Partial<Product>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    images: [],
    fiscalData: {},
    applicationIds: [],
    tags: [],
    data: getDefaultProductData(data?.data ?? {}),
  }, data)
}

export const getProductInformation = (data: Partial<ProductWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsProducts.type, 'value'),
  }
  if (!data) return []
  return [
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Proveedor', data.supplier?.name],
    ['Fabricante', data.supplierManufacturer?.name],
    ['Clave Producto (SAT)', data.fiscalData?.satProductServiceKey],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsProducts = {
  type: [
    { value: 'consumables', label: 'Consumibles', icon: 'i-mdi-cogs', color: 'blue', description: 'Materiales utilizados en el proceso de producción que no se integran en el producto final, como suministros de limpieza, lubricantes y equipos de seguridad.' },
    { value: 'raw-materials', label: 'Materia prima', icon: 'i-mdi-raw', color: 'yellow', description: 'Materiales básicos e insumos utilizados en la fabricación de productos propios.' },
    { value: 'components', label: 'Componentes', icon: 'i-mdi-apps', color: 'purple', description: 'Partes o piezas que se ensamblan para formar productos finales, como piezas mecánicas o electrónicas.' },
    { value: 'packaging', label: 'Embalaje', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Materiales utilizados para el embalaje y envío de productos, como etiquetas, sacos, cajas, cubetas y otros materiales de empaque.' },
    { value: 'finished-goods', label: 'Producto terminado', icon: 'i-mdi-diamond-stone', color: 'green', description: 'Productos completamente fabricados que se venden directamente al cliente final.' },
  ],
} satisfies Options

export const optionsProductsMap = {
  Tipo: optionsProducts.type,
}

export const relationsProducts: Record<string, boolean> = {
  category: true,
  productitems: true,
  supplier: true,
  supplierManufacturer: true,
  files: true,
  // options: true,
}

export type ProductWithRelations = Product & {
  category: Category
  productitems: Productitem[]
  supplier: Supplier
  supplierManufacturer: Supplier
  files: File[]
  options: Option[]
}

export const viewSettingsProducts: ViewSettings = {
  tableName: 'products',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'category.name',
      label: 'Categoría',
      sortable: true,
    },
    {
      key: 'supplier.name',
      label: 'Proveedor',
      sortable: true,
    },
    {
      key: 'supplierManufacturer.name',
      label: 'Fabricante',
      sortable: true,
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
