import { AppNotesSlideover, AppNotesSlideoverList } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppNotesSlideoverProps = ComponentProps<typeof AppNotesSlideover>
type AppNotesSlideoverListProps = ComponentProps<typeof AppNotesSlideoverList>

export type NotesUtils = ReturnType<typeof useNotesUtils>

export const useNotes = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Note>('notes')

  const slideoverOpenCreate = (props: AppNotesSlideoverProps) => {
    return new Promise<Note>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultNote({ userId: user.value.id, ...props.data })
      slideover.open(AppNotesSlideover, {
        title: 'Crear nota',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppNotesSlideoverProps) => {
    return new Promise<Note>((resolve, reject) => {
      const data = getDefaultNote(props.data)

      slideover.open(AppNotesSlideover, {
        title: 'Editar nota',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const slideoverOpenList = (props: AppNotesSlideoverListProps) => {
    return new Promise<Note>((resolve, reject) => {
      try {
        const data = getDefaultNote(props.data)
        slideover.open(AppNotesSlideoverList, {
          title: 'Notas del registro',
          ...props,
          data,
        })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const schema = z.object({
    tableName: z.string(),
    recordId: z.string(),
    text: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Note, refresh: () => Promise<void>, utils: NotesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'notes', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'notes', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'notes', row, refresh, utils),
      getDropdownItem('immutable', 'notes', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'notes', row, refresh, utils),
      getDropdownItem('delete', 'notes', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenList,
    schema,
    getDropdownItems,
  }
}

export const useNotesUtils = () => {
  const options = {
    ...optionsNotes,
  }

  const maps = {}

  return { options, maps }
}
