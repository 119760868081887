<script setup lang="ts">
definePageMeta({ layout: 'auth' })

const seo = { title: 'Iniciar sesión' }

useSeoMeta({
  titleTemplate: '%s · Netzo',
  title: () => seo.title,
  ogTitle: () => seo.title,
})

const appConfig = useAppConfig()

const tab = useRouteQuery<string>('tab', 'password')
if (!['password'].includes(tab.value)) tab.value = 'password' // until passkeys enabled
const email = useRouteQuery<string>('email')

// const tabItemsEmail = [
//   { label: 'Passkey', value: 'passkey', icon: 'i-mdi-fingerprint', disabled: true },
//   { label: 'Contraseña', value: 'password', icon: 'i-mdi-form-textbox-password' },
// ]

// const tabIndex = computed({
//   get: () => {
//     const index = tabItemsEmail.findIndex(t => t.value === tab.value)
//     return index === -1 ? 0 : index
//   },
//   set: value => tab.value = tabItemsEmail[value]?.value,
// })

// TODO: push errors to query for better UX with feedback
const error = useRoute().query.error as string
</script>

<template>
  <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
    <div class="w-full max-w-sm mx-auto space-y-4">
      <h1 class="font-semibold text-2xl text-center">
        {{ seo.title }}
      </h1>

      <UAlert
        v-if="error"
        :title="error"
        variant="subtle"
        color="red"
        icon="i-mdi-alert-circle"
        class="mt-4 text-left"
      />

      <template v-if="appConfig.netzo?.auth">
        <!-- <UTabs
          v-model="tabIndex"
          :items="tabItemsEmail"
          :ui="{ list: { background: '' } }"
        />
        <AuthLoginPasskeyForm v-if="tab === 'passkey'" /> -->
        <AuthLoginPasswordForm v-if="tab === 'password'" />
      </template>
    </div>

    <template v-if="appConfig.netzo?.auth?.allowUserRegistration === true" #footer>
      <p class="text-sm text-center">
        ¿No tienes una cuenta?
        <UButton
          :to="REGEX.email.test(email)
            ? `/auth/register?tab=${tab}&email=${encodeURIComponent(email)}`
            : `/auth/register?tab=${tab}`
          "
          variant="link"
          :padded="false"
          color="gray"
        >
          Registrarse
        </UButton>
      </p>
    </template>
  </UCard>
</template>
