<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Campaign>
  onSubmit: (event: FormSubmitEvent<Partial<Campaign>>) => void
  openNestedCallback: (data: Partial<Campaign>) => void
  title?: string
  disabledFields?: (keyof Campaign | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Campaign>>(props.data)

const campaigns$ = useCampaigns()

const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useCampaignsUtils({ $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.campaigns,
      defaultOpen: true,
    },
    {
      slot: 'metrics',
      label: 'Métricas predeterminadas',
      icon: 'i-mdi-chart-line',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const onSubmitCampaign = async (event: FormSubmitEvent<Partial<Campaign>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.campaigns"
      :validate-on="['submit']"
      :schema="campaigns$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitCampaign"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(campaigns$.schema, 'name')"
            :hint="state.uid"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
              autofocus
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="tipo"
            :required="isRequired(campaigns$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(campaigns$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(campaigns$.schema, 'name')"
            :hint="state.uid"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
              autofocus
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="tipo"
            :required="isRequired(campaigns$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(campaigns$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(campaigns$.schema, 'dateStart')"
            :is-required-end-date="isRequired(campaigns$.schema, 'dateEnd')"
          />

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(campaigns$.schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              table-name="campaigns"
              :record-id="state.id"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="campaigns"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(campaigns$.schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              autoresize
              :disabled="disabledFields?.includes('description')"
              placeholder="La descripción de la campaña debe contener objetivos y detalles importantes de la misma."
            />
          </UFormGroup>
        </template>

        <template #metrics>
          <UFormGroup
            label="Frecuencia de negocio"
            name="metrics.frequency"
            :required="isRequired(campaigns$.schema, 'metrics.frequency')"
          >
            <SelectMenuBaseInfo
              v-model="state.metrics.frequency"
              clearable
              :options="utils.options.metrics.frequency"
              :disabled="disabledFields?.includes('metrics.frequency')"
            />
          </UFormGroup>

          <UFormGroup
            label="Probabilidad de cierre"
            name="metrics.probability"
            :required="isRequired(campaigns$.schema, 'metrics.probability')"
          >
            <InputPercent
              v-model="state.metrics.probability"
              icon
              :disabled="disabledFields?.includes('metrics.probability')"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Valor estimado (${defaultAppVariables.currency})`"
            name="metrics.value"
            :required="isRequired(campaigns$.schema, 'metrics.value')"
          >
            <InputCurrency
              v-model="state.metrics.value"
              icon
              :disabled="disabledFields?.includes('metrics.value')"
            />
          </UFormGroup>

          <AppAlert
            type="info"
            description="Estos campos se utilizarán como predeterminados para las oportunidades que se creen a partir de esta campaña. Es posible modificarlos en cada negocio."
          />
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.campaigns"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
