import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultProductitem = (data: Partial<Productitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    fiscalData: {},
    tags: [],
    data: getDefaultProductitemData(data?.data ?? {}),
  }, data)
}

export const productitemToConcept = (data: Productitem & { product: Product }, basePrice?: number): Partial<Quoteitem> | Partial<Salesorderitem> => {
  const { product, ...productitem } = data ?? {}
  return {
    type: 'products',
    subtype: product.type,
    name: productitem?.name,
    description: productitem?.description || null,
    image: productitem?.image ?? product.image ?? null,
    sku: productitem?.sku || null,
    fiscalData: {
      satProductServiceKey: product?.fiscalData?.satProductServiceKey ?? undefined,
      satUnitKey: productitem?.fiscalData?.satUnitKey ?? undefined,
    },
    unitPrice: basePrice,
    productitemId: productitem.id,
  }
}

export const getProductitemInformation = (data: Partial<ProductitemWithRelations>) => {
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Descripción', data.description],
    ['SKU', data.sku],
    ['Unidad (SAT)', data.fiscalData?.satUnitKey],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsProductitems = {
} satisfies Options

export const optionsProductitemsMap = {
  'Datos Fiscales': {
    'Unidad SAT': optionsSAT.productUnitId,
  },
}

export const relationsProductitems: Record<string, boolean> = {
  pricelistitems: true,
  product: true,
  salesorderitems: true,
  quoteitems: true,
  files: true,
  // options: true,
}

export type ProductitemWithRelations = Productitem & {
  pricelistitems: Pricelistitem[]
  product: Product
  salesorderitems: Salesorderitem[]
  quoteitems: Quoteitem[]
  files: File[]
  options: Option[]
}

export const viewSettingsProductitems: ViewSettings = {
  tableName: 'productitems',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'sku',
      label: 'SKU',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'product.name',
      label: 'Producto',
      sortable: true,
    },
    {
      key: 'product.type',
      label: 'Tipo de Producto',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'product.fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
