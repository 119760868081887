import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId, createUid } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'es-toolkit/compat'

export const getDefaultActivity = (data: Partial<Activity>) => {
  const uid = createUid(new Date(), 'ACT') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'outbound',
    subtype: 'email',
    status: 'pending',
    priority: '2',
    uid: uid,
    name: uid,
    isDatetime: false,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    contactIds: [],
    userIds: [],
    tags: [],
    data: getDefaultActivityData(data?.data ?? {}),
  }, data)
}

export const getActivityInformation = (data: Partial<ActivityWithRelations>) => {
  const maps = {
    type: toMapByKey(optionsActivities.type, 'value'),
    subtype: toMapByKey(optionsActivities.subtype, 'value'),
    status: toMapByKey(optionsActivities.status, 'value'),
  }
  if (!data) return []
  return [
    ['Nombre', data?.name],
    ['Referencia', data?.uid],
    ['Tipo', maps.type.get(data?.type)?.label],
    ['Subtipo', maps.subtype.get(data?.subtype)?.label],
    ['Estado', maps.status.get(data?.status)?.label],
    ['Inicio', data?.dateStart],
    ['Fin', data?.dateEnd],
    ['Negocio', data?.deal.name],
    ['Cuenta', data?.account.name],

  ].map(([key, value]) => [key, value || '-'])
}

export const optionsActivities = {
  type: [
    { value: 'inbound', label: 'Entrante', description: 'Actividad que representa la recepción de una comunicación o interacción, como una llamada recibida o un correo electrónico entrante.', color: 'blue', icon: 'i-mdi-arrow-down-circle' },
    { value: 'outbound', label: 'Saliente', description: 'Actividad que representa la emisión de una comunicación o interacción, como una llamada realizada o un correo electrónico enviado.', color: 'green', icon: 'i-mdi-arrow-up-circle' },
  ],
  subtype: [
    { value: 'email', label: 'Email', description: 'Envío de un correo electrónico.', color: 'blue', icon: 'i-mdi-email' },
    { value: 'call', label: 'Llamada', description: 'Llamada emitida.', color: 'red', icon: 'i-mdi-phone' },
    { value: 'videocall', label: 'Videollamada', description: 'Reunión virtual mediante video.', color: 'orange', icon: 'i-mdi-video' },
    { value: 'meeting', label: 'Reunión', description: 'Encuentro organizado, presencial o digital.', color: 'purple', icon: 'i-mdi-calendar' },
    { value: 'whatsapp', label: 'WhatsApp', description: 'Envío de mensajes a través de WhatsApp.', color: 'lime', icon: 'i-mdi-whatsapp' },
    { value: 'other', label: 'Otros', description: 'Otra forma de comunicación no especificada.', color: 'gray', icon: 'i-mdi-comment' },
  ],
  status: [
    { value: 'pending', label: 'Pendiente', description: 'La actividad está programada pero aún no ha comenzado.', color: 'amber', icon: 'i-mdi-radiobox-blank' },
    { value: 'completed', label: 'Completada', description: 'La actividad se ha completado con éxito.', color: 'green', icon: 'i-mdi-check-circle-outline' },
    { value: 'canceled', label: 'Cancelada', description: 'La actividad fue planificada pero luego cancelada.', color: 'red', icon: 'i-mdi-close-circle' },
    { value: 'rescheduled', label: 'Reprogramada', description: 'La actividad se ha pospuesto y está programada para más tarde.', color: 'blue', icon: 'i-mdi-calendar-clock' },
    { value: 'no-show', label: 'No asistió', description: 'La actividad no se llevó a cabo porque cualquiera de los participantes no se presentó.', color: 'orange', icon: 'i-mdi-account-off' },
    { value: 'other', label: 'Otra', description: 'La actividad tiene un tipo diferente no especificado.', color: 'gray', icon: 'i-mdi-asterisk' },
  ],
  priority: optionsShared.priority,
} satisfies Options

export const optionsActivitiesMap = {
  Tipo: optionsActivities.type,
  Subtipo: optionsActivities.subtype,
  Estado: optionsActivities.status,
  Prioridad: optionsActivities.priority,
}

export const relationsActivities: Record<string, boolean> = {
  account: true,
  branch: true,
  deal: true,
  files: true,
  // options: true,
}

export type ActivityWithRelations = Activity & {
  account: Account
  branch: Branch
  deal: Deal
  files: File[]
  options: Option[]
}

export const activityToViewCalendarEvent = (calendarId: keyof Activity, utils: ActivitiesUtils) => {
  return (row: Activity): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const viewSettingsActivities: ViewSettings = {
  tableName: 'activities',
  typeOptions: optionsShared.views.filter(view => ['list', 'grid', 'calendar'].includes(view.value)),
  type: 'list',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fin',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'deal.name',
      label: 'Negocio',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'branch.name',
      label: 'Sucursal',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contactIds',
      label: 'Contactos',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userIds',
      label: 'Usuarios',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'subtype',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsActivities.type },
    { label: 'Subtipo', value: 'subtype', options: optionsActivities.subtype },
    { label: 'Estado', value: 'status', options: optionsActivities.status },
    { label: 'Prioridad', value: 'priority', options: optionsActivities.priority },
  ],
}
