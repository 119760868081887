<script setup lang="ts">
import { useSortable } from '@vueuse/integrations/useSortable'

const model = defineModel<[string, string][]>({ default: () => [] })

const props = defineProps<{
  placeholder?: string
}>()

// NOTE: useSortable requires having a unique id for each item which
// cannot be the index since it changes when sorting, so we generate one
const toEntry = (key: string, value: string) => ({ key, value, id: Math.random().toString(36).substr(2, 9) })

const entries = ref(model.value?.map(([key, value]) => toEntry(key, value)) ?? [])

const removeAtIndex = (index: number) => {
  entries.value = entries.value.filter((_, i) => i !== index)
}

watch(entries, (value) => {
  model.value = value.map(({ key, value }) => [key, value])
}, { deep: true })

const onInputLastKey = async (event) => {
  const value = event.target?.value?.trim()
  if (value) {
    entries.value.push(toEntry(value, '')) // Add the new entry
    await nextTick() // Wait for the DOM to update
    const index = entries.value.length - 1 // Get the index of the new entry
    document.getElementById(`entries-key-${index}`)?.focus() // Focus on the new entry
    document.getElementById('last-entry-key')!.value = '' // Clear the last entry input
  }
}

const onInputLastValue = async (event) => {
  const value = event.target?.value?.trim()
  if (value) {
    entries.value.push(toEntry('', value)) // Add the new entry
    await nextTick() // Wait for the DOM to update
    const index = entries.value.length - 1 // Get the index of the new entry
    document.getElementById(`entries-value-${index}`)?.focus() // Focus on the new entry
    document.getElementById('last-entry-value')!.value = '' // Clear the last entry input
  }
}

const ROW = 'grid grid-cols-[min-content,2fr,3fr,min-content] gap-1'

// sortable:
const el = useTemplateRef('el')
useSortable(el, entries, { handle: '.handle', animation: 100 })
</script>

<template>
  <fieldset>
    <div ref="el">
      <div
        v-for="(entry, index) in entries"
        :key="`entry-${entry.id}`"
        class="mb-2 text-sm"
        :class="[ROW]"
      >
        <UButton
          variant="ghost"
          icon="i-mdi-drag"
          size="xs"
          color="gray"
          class="handle cursor-move"
        />
        <UInput
          :id="`entries-key-${index}`"
          v-model="entry.key"
          placeholder="Key"
          resize
        />
        <UTextarea
          :id="`entries-value-${index}`"
          v-model="entry.value"
          placeholder="Value"
          resize
          autoresize
          :rows="1"
        />
        <UButton
          variant="ghost"
          color="red"
          size="xs"
          icon="i-mdi-close"
          @click="removeAtIndex(index)"
        />
      </div>
    </div>
    <div class="text-sm" :class="[ROW]">
      <div class="w-[28px]" />
      <UInput
        id="last-entry-key"
        :rows="1"
        resize
        :placeholder="placeholder"
        @input="onInputLastKey"
      />
      <UTextarea
        id="last-entry-value"
        :rows="1"
        resize
        :placeholder="placeholder"
        @input="onInputLastValue"
      />
      <div class="w-[28px]" />
    </div>
  </fieldset>
</template>
