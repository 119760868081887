<script setup lang="ts">
const model = defineModel<PdfSettings>({ required: true, default: () => ({}) })
defineProps<{
  cols?: number
  hiddenSections?: keyof PdfSettings[]
  disabled?: boolean
}>()

const FIELDSET = `w-full grid gap-2`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      v-if="!hiddenSections?.includes('images')"
      label="Imágenes"
      class="w-full"
    >
      <UButtonGroup class="w-full">
        <UButton
          :icon="model.images ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          @click="() => model.images = !model.images"
        />
        <InputReadOnly
          model-value="Generar PDF con imágenes"
          class="flex-1"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      v-if="!hiddenSections?.includes('details')"
      label="Mostrar detalles"
      class="w-full"
    >
      <UButtonGroup class="w-full">
        <UButton
          :icon="model.details ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          @click="() => model.details = !model.details"
        />
        <InputReadOnly
          model-value="Generar versión detallada"
          class="flex-1"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      v-if="!hiddenSections?.includes('watermark')"
      label="Marca de agua"
      class="w-full"
    >
      <div class="w-full flex flex-row gap-2">
        <div class="w-full flex items-center gap-2">
          <UButtonGroup class="flex-grow">
            <UButton
              :icon="`${model.watermark.enabled ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'}`"
              square
              variant="outline"
              :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
              @click="() => model.watermark.enabled = !model.watermark.enabled"
            />
            <InputText
              v-model.defaultcase="model.watermark.text"
              class="flex-1"
              :disabled="!model.watermark.enabled"
              :required="model.watermark.enabled"
            />
          </UButtonGroup>
        </div>
        <UButtonGroup>
          <UButton
            v-model="model.watermark.italic"
            square
            variant="soft"
            :color="model.watermark.italic ? 'primary' : 'gray'"
            icon="i-mdi-format-italic"
            class="border"
            @click="() => model.watermark.italic = !model.watermark.italic"
          />
          <UButton
            v-model="model.watermark.bold"
            square
            variant="soft"
            :color="model.watermark.bold ? 'primary' : 'gray'"
            icon="i-mdi-format-bold"
            class="border"
            @click="() => model.watermark.bold = !model.watermark.bold"
          />
        </UButtonGroup>
      </div>
    </UFormGroup>

    <UFormGroup
      v-if="!hiddenSections?.includes('pageSize')"
      label="Tamaño de página"
      class="w-full"
    >
      <SelectMenuBase
        v-model="model.pageSize"
        :disabled="disabled"
        :options="optionsPdfSettings.pageSize"
      />
    </UFormGroup>
  </fieldset>
</template>
