<script setup lang="ts">
import type { ZodObject } from 'zod'

const model = defineModel<Quoteitem | Salesorderitem>({ required: true })

const props = defineProps<{
  tableName: 'quoteitems' | 'salesorderitems'
  utils: QuoteitemsUtils | SalesorderitemsUtils
  schema: ZodObject<unknown>
  disabledFields?: (keyof Quoteitem | Salesorderitem | string)[]
  disabled?: boolean
}>()

const onUpdateDiscountType = () => {
  model.value.discount = {
    type: model.value.discount.type,
    value: null,
    amount: null,
  }
}
</script>

<template>
  <fieldset :class="FIELDSET_FULL_12" :disabled="disabled">
    <UFormGroup
      label="SKU"
      name="sku"
      :required="isRequired(schema, 'sku')"
      class="md:col-span-2"
    >
      <InputText
        v-model.defaultcase="model.sku"
        :disabled="disabledFields?.includes('sku')"
      />
    </UFormGroup>

    <UFormGroup
      label="Nombre"
      name="name"
      :required="isRequired(schema, 'name')"
      class="md:col-span-10"
    >
      <InputText
        v-model.defaultcase="model.name"
        :disabled="disabledFields?.includes('name')"
      />
    </UFormGroup>

    <UFormGroup
      label="Descripción"
      name="description"
      :required="isRequired(schema, 'description')"
      class="md:col-span-12"
    >
      <UTextarea
        v-model="model.description"
        resize
        :rows="2"
        :disabled="disabledFields?.includes('description')"
      />
    </UFormGroup>

    <UFormGroup
      label="Cantidad"
      name="quantity"
      :required="isRequired(schema, 'quantity')"
      class="md:col-span-6"
    >
      <UInput
        v-model="model.quantity"
        type="number"
        inputmode="decimal"
        :min="0.00"
        :step="0.01"
        :disabled="disabledFields?.includes('quantity')"
      />
    </UFormGroup>

    <UFormGroup
      label="Unidad"
      name="fiscalData.satUnitKey"
      :required="isRequired(schema, 'fiscalData.satUnitKey')"
      class="md:col-span-6"
    >
      <SelectMenuBase
        v-model="model.fiscalData.satUnitKey"
        :disabled="disabledFields?.includes('fiscalData.satUnitKey')"
        :options="optionsSAT.productUnitId"
      />
    </UFormGroup>

    <UFormGroup
      :label="`Precio U (${defaultAppVariables.currency})`"
      name="unitPrice"
      :required="isRequired(schema, 'unitPrice')"
      class="md:col-span-6"
    >
      <InputCurrency
        v-model="model.unitPrice"
        :currency="defaultAppVariables.currency"
        icon
        :disabled="disabledFields?.includes('unitPrice')"
      />
    </UFormGroup>

    <UFormGroup
      label="Descuento"
      name="discount"
      :required="isRequired(schema, 'discount')"
      class="md:col-span-6"
    >
      <div
        v-if="model.discount?.type === 'percent'"
        class="grid grid-cols-1 md:grid-cols-[1fr,2fr,3fr] gap-1"
      >
        <SelectMenuBase
          v-model="model.discount.type"
          :disabled="disabledFields?.includes('discount')"
          :options="utils.options.discount"
          @update:model-value="onUpdateDiscountType"
        />
        <InputPercent
          v-model="model.discount.value"
          :disabled="disabledFields?.includes('discount')"
        />
        <InputReadOnly
          :model-value="toCurrency(isNaN(model.discount?.amount) ? 0 : (model.discount?.amount ?? 0), defaultAppVariables.currency)"
        />
      </div>

      <div
        v-else
        class="grid grid-cols-1 md:grid-cols-[1fr,4fr] gap-1"
      >
        <SelectMenuBase
          v-model="model.discount.type"
          :disabled="disabledFields?.includes('discount')"
          :options="utils.options.discount"
          @update:model-value="onUpdateDiscountType"
        />
        <InputPercent
          v-if="model.discount.type === 'percent'"
          v-model="model.discount.value"
          :disabled="disabledFields?.includes('discount')"
        />
        <InputCurrency
          v-else
          v-model="model.discount.value"
          :currency="defaultAppVariables.currency"
          :max="model.amount"
          icon
          :disabled="disabledFields?.includes('discount')"
        />
      </div>
    </UFormGroup>

    <div class="hidden md:block md:col-span-6" />

    <UFormGroup
      :label="`Importe (${defaultAppVariables.currency})`"
      name="amount"
      :required="isRequired(schema, 'amount')"
      class="md:col-span-6"
    >
      <InputReadOnly
        :model-value="toCurrency(isNaN(model.amount) ? 0 : (model.amount ?? 0), defaultAppVariables.currency)"
      />
    </UFormGroup>
  </fieldset>

  <UFormGroup
    label="Imagen"
    name="image"
    :required="isRequired(schema, 'image')"
  >
    <InputImage
      v-model="model.image"
      :table-name="tableName"
      :record-id="model.id"
      :disabled="disabledFields?.includes('image')"
    />
  </UFormGroup>

  <UFormGroup
    label="Etiquetas"
    name="tags"
  >
    <SelectMenuCreatableString
      v-model="model.tags"
      :options="model.tags"
      creatable
    />
  </UFormGroup>

  <UFormGroup
    label="Archivos"
    name="files"
  >
    <InputFiles
      v-model="model.files"
      :table-name="tableName"
      :record-id="model.id"
      accept="*"
    />
  </UFormGroup>
</template>
