import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultApplication = (data: Partial<Application>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    industryIds: [],
    tags: [],
    data: getDefaultApplicationData(data?.data ?? {}),
  }, data)
}
export const optionsApplications = {} satisfies Options

export const optionsApplicationsMap = {}

export const relationsApplications: Record<string, boolean> = {
  files: true,
  // options: true,
}

export type ApplicationWithRelations = Application & {
  files: File[]
  options: Option[]
}

export const viewSettingsApplications: ViewSettings = {
  tableName: 'applications',
  typeOptions: optionsShared.views.filter(view => ['grid', 'gallery'].includes(view.value)),
  type: 'gallery',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
