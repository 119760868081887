import { merge } from 'es-toolkit/compat'

export const getDefaultAuditlog = (data: Partial<Auditlog>) => {
  return merge({}, data)
}

export const optionsAuditlogs = {
  action: [
    {
      value: 'create',
      label: 'Creado',
      description: 'Se creó un nuevo registro.',
      color: 'green',
    },
    {
      value: 'update',
      label: 'Modificado',
      description: 'Se modifico un registro existente.',
      color: 'blue',
    },
    {
      value: 'delete',
      label: 'Eliminado',
      description: 'Se eliminó un registro existente.',
      color: 'red',
    },
    {
      value: 'login',
      label: 'Inicio de sesión',
      description: 'Un usuario inició sesión.',
      color: 'purple',
    },
    {
      value: 'logout',
      label: 'Cierre de sesión',
      description: 'Un usuario cerró sesión.',
      color: 'orange',
    },
  ],
  tableName: optionsShared.tableName,
} satisfies Options

export const optionsAuditlogsMap = {
  Acción: optionsAuditlogs.action,
}

export const relationsAuditlogs: Record<string, boolean> = {
  user: true,
}

export const viewSettingsAuditlogs: ViewSettings = {
  tableName: 'auditlogs',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'action',
      label: 'Acción',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'createdAt',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tableName',
      label: 'Registro',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'recordId',
      label: 'ID del Registro',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'changes',
      label: 'Cambios',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'user.name',
      label: 'Usuario',
      sortable: true,
    },
  ],
}
