<script setup lang="ts" generic="T extends Row">
import type { z } from '#netzo/shared/utils/zod'
import { flatten, unflatten } from 'flat'

const model = defineModel<T['data']>({ required: true })

const props = defineProps<{
  schema?: z.ZodObject<unknown>
  utils: RowUtils
  disabled?: boolean
}>()

const entries = computed<[string, string][]>({
  get: () => Object.entries(flatten(model.value) ?? {}),
  set: (value: [string, string][]) => {
    model.value = unflatten(Object.fromEntries(value))
  },
})

const tab = ref(0)
const tabs = [
  { label: 'Formulario', icon: 'i-mdi-format-list-bulleted' },
  { label: 'JSON', icon: 'i-mdi-code-json' },
]
</script>

<template>
  <UTabs v-model="tab" :items="tabs">
    <template #item="{ item }">
      <!-- force rerender when changing tabs -->
      <InputEntries
        v-if="item.label === 'Formulario'"
        :key="`input-entries-${tab}`"
        v-model="entries"
      />

      <UTextarea
        v-else
        :model-value="JSON.stringify(model, null, 2)"
        :rows="25"
        autoresize
        resize
        @update:model-value="(value) => {
          try {
            model = JSON.parse(value)
          }
          catch { }
        }"
      />
    </template>
  </UTabs>
</template>
