import { computed, ICONS, navigateTo, useRoute, type ComputedRef } from '#imports'
import type { DashboardSidebarLink } from '#ui-pro/types'
import type { DropdownItem } from '#ui/types'
import type { Module } from './useModules'

const moduleToDashboardSidebarLink = (mod: Module): DashboardSidebarLink => ({
  label: mod.name,
  icon: mod.icon,
  to: mod.disabled ? undefined : `/${mod.id}`,
  tooltip: {
    text: mod.name,
    // shortcuts: [],
  },
  disabled: mod.disabled,
})

const moduleToDropdownItem = (mod: Module): DropdownItem => ({
  label: mod.name,
  to: mod.disabled ? undefined : `/${mod.id}`,
  icon: mod.icon,
  disabled: mod.disabled,
})

export function useNavigation(modules: ComputedRef<Module[]>) {
  const route = useRoute()

  const moduleId = computed({
    get: () => route.path.split('/')[1] ?? 'admin',
    set: (value: string) => navigateTo(`/${value}`),
  })

  const CORE = ['admin']
  const coreModules = computed<Module[]>(() => modules.value.filter(mod => CORE.includes(mod.id)))
  const otherModules = computed<Module[]>(() => modules.value.filter(mod => !CORE.includes(mod.id)))

  const activeModule = computed(() => modules.value.find(mod => mod.id === moduleId.value))

  const isActiveRoute = (name: `${string}-${string}`) => {
    return [name].includes(route.name) || route.name.startsWith(`${name}-id`)
  }

  const LINKS = computed<Record<string, DashboardSidebarLink[]>>(() => ({
    admin: [
      {
        label: 'Incidencias',
        icon: ICONS.issues,
        to: '/admin/issues',
        tooltip: { text: 'Incidencias' },
      },
      {
        label: 'Autenticación',
        icon: 'i-mdi-security',
        children: [
          {
            label: 'Usuarios',
            to: '/admin/auth/users',
            tooltip: { text: 'Usuarios' },
          },
          // DISABLED: until v1 ready
          // {
          //   label: 'Claves API',
          //   to: '/admin/auth/apikeys',
          //   tooltip: { text: 'Claves API' },
          // },
        ],
        defaultOpen: true,
        tooltip: { text: 'Autenticación' },
      },
      {
        label: 'Registros de Auditoría',
        icon: ICONS.auditlogs,
        to: '/admin/auditlogs',
        tooltip: { text: 'Registros de Auditoría' },
      },
    ],
    catalogs: [
      {
        label: 'Categorias',
        icon: ICONS.categories,
        to: '/catalogs/categories',
        tooltip: { text: 'Categorias' },
        active: isActiveRoute('catalogs-categories'),
      },
      {
        label: 'Industrias',
        icon: ICONS.industries,
        to: '/catalogs/industries',
        tooltip: { text: 'Industrias' },
        active: isActiveRoute('catalogs-industries'),
      },
      {
        label: 'Aplicaciones',
        icon: ICONS.applications,
        to: '/catalogs/applications',
        tooltip: { text: 'Aplicaciones' },
        active: isActiveRoute('catalogs-applications'),
      },
      {
        label: 'Productos',
        icon: ICONS.products,
        children: [
          {
            label: 'Productos',
            to: '/catalogs/products',
            tooltip: { text: 'Productos' },
            active: isActiveRoute('catalogs-products'),
          },
          {
            label: 'Items',
            to: '/catalogs/products/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Productos' },
      },
      {
        label: 'Servicios',
        icon: ICONS.services,
        children: [
          {
            label: 'Servicios',
            to: '/catalogs/services',
            tooltip: { text: 'Servicios' },
            active: isActiveRoute('catalogs-services'),
          },
          {
            label: 'Items',
            to: '/catalogs/services/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Servicios' },
      },
      {
        label: 'Listas de Precios',
        icon: ICONS.pricelists,
        to: '/catalogs/pricelists',
        tooltip: { text: 'Listas de Precios' },
        active: isActiveRoute('catalogs-pricelists'),
      },
    ],
    finances: [
      {
        label: 'Informes',
        icon: 'i-mdi-chart-line',
        children: [
          {
            label: 'Flujo de Caja',
            // to: '/finances/reports/cashflow',
            tooltip: { text: 'Informes de flujo de caja' },
          },
          {
            label: 'Estado de Resultados',
            // to: '/finances/reports/income-statement',
            tooltip: { text: 'Informes de estado de resultados' },
          },
          {
            label: 'Balance General',
            // to: '/finances/reports/balance-sheet',
            tooltip: { text: 'Informes de balance general' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Informes Financieros' },
      },
      {
        label: 'Tesorería',
        icon: 'i-mdi-cash-register',
        children: [
          {
            label: 'Conciliaciones',
            to: '/finances/reconciliations',
            tooltip: { text: 'Conciliaciones' },
          },
          {
            label: 'Cuentas por Cobrar',
            tooltip: { text: 'Cuentas por cobrar' },
            disabled: true,
          },
          {
            label: 'Cuentas por Pagar',
            tooltip: { text: 'Cuentas por pagar' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Tesorería' },
      },
      {
        label: 'Bancos y Cuentas',
        icon: 'i-mdi-bank-outline',
        children: [
          {
            label: 'Bancos',
            to: '/finances/banks',
            tooltip: { text: 'Bancos' },
          },
          {
            label: 'Cuentas Financieras',
            to: '/finances/financialaccounts',
            tooltip: { text: 'Cuentas Financieras' },
          },
          {
            label: 'Transacciones',
            to: '/finances/transactions',
            tooltip: { text: 'Transacciones' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Bancos y Cuenta' },
      },
      {
        label: 'Documentos',
        icon: 'i-mdi-file-document',
        children: [
          {
            label: 'Facturas sin timbrar',
            // to: '/finances/cfdis',
            tooltip: { text: 'Facturas sin timbrar' },
          },
          {
            label: 'CFDIs',
            to: '/finances/cfdis',
            tooltip: { text: 'CFDIs' },
          },
          {
            label: 'Cargar CFDIs',
            to: '/finances/upload',
            tooltip: { text: 'Cargar CFDIs' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Documentos' },
      },
    ],
    production: [
      {
        label: 'Producción',
        icon: ICONS.productionorders,
        children: [
          {
            label: 'Ordenes de Producción',
            to: '/production/productionorders',
            tooltip: { text: 'Ordenes de Producción' },
            active: isActiveRoute('production-productionorders'),
          },
          {
            label: 'Items',
            to: '/production/productionorders/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Ordenes de Producción' },
      },
    ],
    fulfillment: [],
    sales: [
      {
        label: 'Negocios',
        icon: ICONS.deals,
        to: '/sales/deals',
        tooltip: { text: 'Negocios' },
        active: isActiveRoute('sales-deals'),
      },
      {
        label: 'Actividades',
        icon: ICONS.activities,
        to: '/sales/activities',
        tooltip: { text: 'Actividades' },
      },
      {
        label: 'Campañas',
        icon: ICONS.campaigns,
        to: '/sales/campaigns',
        tooltip: { text: 'Campañas' },
        active: isActiveRoute('sales-campaign'),
      },
      {
        label: 'Cotizaciones',
        icon: ICONS.quotes,
        to: '/sales/quotes',
        tooltip: { text: 'Cotizaciones' },
        active: isActiveRoute('sales-quotes'),
      },
      {
        label: 'Ordenes de Venta',
        icon: ICONS.salesorders,
        to: '/sales/salesorders',
        tooltip: { text: 'Ordenes de Venta' },
        active: isActiveRoute('sales-salesorders'),
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Cuentas',
            to: '/sales/accounts',
            tooltip: { text: 'Cuentas' },
            active: isActiveRoute('sales-accounts'),
          },
          {
            label: 'Sucursales',
            to: '/sales/branches',
            tooltip: { text: 'Sucursales' },
          },
          {
            label: 'Contactos',
            to: '/sales/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
  }))

  const links = computed<DashboardSidebarLink[][]>(() => {
    if (moduleId.value in LINKS.value) return [LINKS.value[moduleId.value]]
    return [
      otherModules.value.map(moduleToDashboardSidebarLink), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDashboardSidebarLink),
    ]
  })

  const items = computed<DropdownItem[][]>(() => {
    return [
      otherModules.value.map(moduleToDropdownItem), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDropdownItem),
    ]
  })

  const isModuleActive = computed(() => modules.value.some(mod => route.path.startsWith(`/${mod.id}`)))

  return {
    moduleId,
    coreModules,
    otherModules,
    activeModule,
    links,
    items,
    isModuleActive,
  }
}
