import { z } from 'zod'

export { z }

export const isRequired = (schema: z.ZodObject<unknown>, key: string) => {
  // if key is nested (dot notation) access nested
  // zodschema with .shape for each level:
  const keyParts = key.split('.')
  let schemaPart = schema
  for (const keyPart of keyParts) {
    schemaPart = schemaPart?.shape?.[keyPart]
  }
  if (!schemaPart) return false
  return !schemaPart.isOptional()
}

// regex:

export const REGEX = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
}
