<script setup lang="ts">
import type { DropdownItem } from '#ui/types'
import type { BlobObject } from '@nuxthub/core'

const props = defineProps<{
  row: BlobObject
  dropdownItems?: DropdownItem[][]
}>()

const { user } = useUserSession()
const { moduleId } = useModules()

const { slideoverOpenFilePreview } = useFiles()
</script>

<template>
  <!-- IMPORTANT: must use CSS variables and not e.g. `border-[toHexColor(row.userId)]`
  directly since TailwindCSS will not pick-up dynamic CSS colors outside of the SSR (on client) -->
  <div
    :id="`file-${row.id}`"
    class="w-full h-min py-[0.5] px-2 rounded-md bg-gray-100 dark:bg-gray-800"
  >
    <header class="flex items-center justify-between text-xs py-1">
      <div class="flex-1 flex items-center gap-1 truncate">
        <div :title="row.name" class="font-medium text-xs">
          <div class="flex items-center gap-1">
            <AppFilesThumbnail :file="row" compact />
            <NuxtLink
              :title="row.name"
              class="text-primary hover:cursor-pointer"
              @click="slideoverOpenFilePreview(row)"
            >
              {{ row.name.split('/').pop() }}
            </NuxtLink>
          </div>
        </div>
        <div class="ml-auto flex items-center gap-2">
          <Can
            v-if="dropdownItems?.length"
            :ability="update"
            :args="[moduleId]"
          >
            <UDropdown
              :items="dropdownItems"
              :popper="{ placement: 'auto-start' }"
              :ui="{ width: 'min-w-max' }"
            >
              <UButton
                icon="i-mdi-dots-horizontal"
                variant="ghost"
                size="2xs"
                square
              />
            </UDropdown>
          </Can>
        </div>
      </div>
    </header>
  </div>
</template>
