import { AppPricelistitemsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppPricelistitemsSlideoverProps = ComponentProps<typeof AppPricelistitemsSlideover>

export type PricelistitemsUtils = ReturnType<typeof usePricelistitemsUtils>

export const usePricelistitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Pricelistitem>('pricelistitems')

  const slideoverOpenCreate = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem>((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Crear precio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem > ((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Editar precio',
        ...props,
        data,
        disabledFields: ['pricelistId'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schemaProducts = z.object({
    currency: z.string(),
    basePrice: z.number(),
    pricelistId: z.string(),
    productitemId: z.string(),
  })

  const schemaServices = z.object({
    currency: z.string(),
    basePrice: z.number(),
    pricelistId: z.string(),
    serviceitemId: z.string(),
  })

  // actions:

  const getItem = (row: Pricelistitem): Productitem | Serviceitem => {
    return row?.productitem ?? row?.serviceitem
  }

  const getDropdownItems = (row: Pricelistitem, refresh: () => Promise<void>, utils: PricelistitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'pricelistitems', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'pricelistitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'pricelistitems', row, refresh, utils),
      getDropdownItem('files', 'pricelistitems', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'pricelistitems', row, refresh, utils),
      {
        label: row.immutable ? 'Desbloquear' : 'Bloquear',
        icon: row.immutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.pricelist?.immutable,
        click: async () => {
          await onSubmitUpdate({ ...row, immutable: !row.immutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('archive', 'pricelistitems', row, refresh, utils),
      getDropdownItem('delete', 'pricelistitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schemaProducts,
    schemaServices,
    getItem,
    getDropdownItems,
  }
}

export const usePricelistitemsUtils = ({ $pricelists, $productitems, $serviceitems }: {
  $pricelists?: Awaited<ReturnType<typeof useFetch<Pricelist[]>>>
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsPricelistitems,
    pricelistId: computed(() => $pricelists?.data.value.map(toOption)),
    productitemId: computed(() => $productitems?.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    pricelistId: computed(() => toMapByKey($pricelists?.data.value, 'id')),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  }

  return { options, maps }
}
