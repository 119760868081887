import type { File } from '#netzo/shared/types/db'

export const getFileProps = (file: File | null, label?: string) => {
  const { pathname, contentType } = file ?? {}
  label = label ?? pathname?.split('/').pop() ?? 'Archivo'

  if (contentType?.startsWith('image/')) {
    return {
      icon: 'i-mdi-image',
      label,
      color: 'yellow',
      ui: { icon: { base: 'text-yellow-500' } },
    }
  }
  else if (contentType?.startsWith('video/')) {
    return {
      icon: 'i-mdi-video',
      label,
      color: 'yellow',
      ui: { icon: { base: 'text-yellow-500' } },
    }
  }
  else if (contentType?.startsWith('audio/')) {
    return {
      icon: 'i-mdi-volume',
      label,
      color: 'purple',
      ui: { icon: { base: 'text-purple-500' } },
    }
  }
  else if (
    contentType?.startsWith('text/')
    || contentType?.startsWith('application/')
  ) {
    if (contentType?.includes('csv')) {
      return {
        icon: 'i-mdi-file-excel',
        label,
        color: 'green',
        ui: { icon: { base: 'text-green-500' } },
      }
    }
    else if (contentType?.includes('html')) {
      return {
        icon: 'i-mdi-language-html5',
        label,
        color: 'yellow',
        ui: { icon: { base: 'text-yellow-500' } },
      }
    }
    else if (contentType?.includes('css')) {
      return {
        icon: 'i-mdi-language-css3',
        label,
        color: 'blue',
        ui: { icon: { base: 'text-blue-500' } },
      }
    }
    else if (contentType?.includes('javascript')) {
      return {
        icon: 'i-mdi-language-javascript',
        label,
        color: 'purple',
        ui: { icon: { base: 'text-purple-500' } },
      }
    }
    else if (contentType?.includes('pdf')) {
      return {
        icon: 'i-mdi-file-pdf',
        label,
        color: 'red',
        ui: { icon: { base: 'text-red-500' } },
      }
    }
    else if (contentType?.includes('xml')) {
      return {
        icon: 'i-mdi-file-xml',
        label,
        color: 'blue',
        ui: { icon: { base: 'text-blue-500' } },
      }
    }
    else if (contentType?.includes('json')) {
      return {
        icon: 'i-mdi-code-json',
        label,
        color: 'yellow',
        ui: { icon: { base: 'text-yellow-500' } },
      }
    }
    else if (contentType?.includes('zip')) {
      return {
        icon: 'i-mdi-folder-zip',
        label,
        color: 'blue',
        ui: { icon: { base: 'text-blue-500' } },
      }
    }
    else if (contentType?.includes('plain')) {
      return {
        icon: 'i-mdi-file-document',
        label,
        color: 'gray',
        ui: { icon: { base: 'text-gray-500' } },
      }
    }
    else {
      return {
        icon: 'i-mdi-file',
        label,
        color: 'gray',
        ui: { icon: { base: 'text-gray-500' } },
      }
    }
  }
  else {
    return {
      icon: 'i-mdi-file',
      label,
      color: 'gray',
      ui: { icon: { base: 'text-gray-500' } },
    }
  }
}
