<script setup lang="ts">
const { modules, moduleId } = useModules()

const { activeModule, items } = useNavigation(modules)
</script>

<template>
  <UDropdown
    v-slot="{ open }"
    :items="items"
    class="w-full"
    :ui="{ width: 'min-w-max' }"
    :popper="{ strategy: 'absolute' }"
  >
    <div>
      <UButton
        size="sm"
        color="gray"
        variant="ghost"
        :trailing-icon="`${open ? 'i-mdi-chevron-up' : 'i-mdi-chevron-down'}`"
        :class="[
          open && 'bg-primary-200/50 dark:bg-primary-800/50',
          'hover:bg-primary-200/50 dark:hover:bg-primary-800/50',
        ]"
      >
        <!-- <UIcon :name="activeModule?.icon" size="2xs" /> -->
        <h2 class="text-gray-900 dark:text-white text-lg font-semibold truncate">
          {{ activeModule?.name }}
        </h2>
      </UButton>
    </div>
  </UDropdown>
</template>
