<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<User>
  onSubmit: (event: FormSubmitEvent<Partial<User>>) => void
  openNestedCallback: (data: Partial<User>) => void
  title?: string
  disabledFields?: (keyof User | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<User>>(props.data)

const users$ = useUsers()

const utils = useUsersUtils()

const items: AccordionItem[] = [
  {
    slot: 'general',
    label: 'General',
    icon: 'i-mdi-information',
    defaultOpen: true,
  },
  {
    slot: 'modules',
    label: 'Acceso a Módulos',
    icon: 'i-mdi-hexagon-multiple',
    defaultOpen: true,
  },
]

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const optionsAccountId = computed(() => $accounts.data.value?.map(toOption) ?? [])

const loading = ref(false)

const onSubmitUser = async (event: FormSubmitEvent<Partial<User>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.users"
      :validate-on="['submit']"
      :schema="users$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitUser"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(users$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(users$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Email"
            name="email"
            :required="isRequired(users$.schema, 'email')"
          >
            <InputText
              v-model.lowercase="state.email"
              type="email"
              autocomplete="email"
              :disabled="disabledFields?.includes('email')"
            />
          </UFormGroup>

          <!-- <UFormGroup label="Contraseña" name="password" required>
            <InputPassword v-model="state.password" autocomplete="new-password" />
          </UFormGroup> -->

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(users$.schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              table-name="users"
              :record-id="state.id"
            />
          </UFormGroup>
        </template>

        <template #modules>
          <fieldset>
            <div
              v-for="(mod, i) in optionsUsers.modules"
              :key="`users-module-${mod.value}-${i}`"
              :name="`modules.${mod.value}.enabled`"
              class="grid grid-cols-[auto,auto,140px] gap-2 items-center"
            >
              <div class="flex items-center gap-2">
                {{ mod.label }}
                <UTooltip :text="mod.description">
                  <UIcon name="i-mdi-information" size="xs" />
                </UTooltip>
                <UBadge
                  v-if="mod.type === 'external'"
                  variant="subtle"
                  color="primary"
                >
                  Externo
                </UBadge>
              </div>
              <div class="text-right mr-3">
                <UToggle
                  v-model="state.modules[mod.value].enabled"
                  label="Activado"
                  size="md"
                  :disabled="disabledFields?.includes('modules') || disabledFields?.includes(`modules.${mod.value}.enabled`)"
                  :title="state.modules[mod.value]?.enabled ? 'Acceso al módulo activado' : 'Acceso al módulo desactivado'"
                  @update:model-value="() => {
                    const { enabled, role } = state.modules[mod.value]
                    if (enabled && !role) state.modules[mod.value].role = 'view'
                    else if (!enabled) state.modules[mod.value].role = undefined
                  }"
                />
              </div>
              <div class="py-2">
                <SelectMenuBase
                  v-model="state.modules[mod.value].role"
                  label="Rol"
                  :options="optionsUsers.modulesRole"
                  :disabled="!state.modules[mod.value]?.enabled || disabledFields?.includes('modules') || disabledFields?.includes(`modules.${mod.value}.enabled`)"
                />
              </div>
            </div>
          </fieldset>
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.users"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
