type ExchangeRate = {
  date: string
  value: number
}

export function useExchangeRate() {
  const { getExchangeRate } = useServerFunctions()
  const {
    state,
    isReady,
    execute,
  } = useAsyncState<ExchangeRate>(() => getExchangeRate(), {} as ExchangeRate)

  return {
    state,
    isReady,
    execute,
  }
}
