import type { Options } from '#netzo/shared/types/core'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultUser = (data: Partial<User>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'internal',
    modules: Object.fromEntries(MODULES.map(mod => [mod.id, {}])),
  }, data)
}

export const getUserInformation = (data: Partial<User>) => {
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Email', data.email],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsUsers = {
  type: [
    {
      value: 'internal',
      label: 'Interno',
      description: 'Usuario interno de la organización, identificado con un correo corporativo con acceso a los distintos módulos del sistema.',
      color: 'blue',
    },
    {
      value: 'external',
      label: 'Externo',
      description: 'Usuario externo a la organización, identificado con un correo no corporativo con acceso a portales para externos (e.g. proveedores o clientes).',
      color: 'pink',
    },
  ],
  emailVerified: [
    { value: true, label: 'Verificado', icon: 'i-mdi-check-circle', color: 'green' },
    { value: false, label: 'No verificado', icon: 'i-mdi-alert-circle', color: 'red' },
  ],
  banned: [
    { value: true, label: 'Bloqueado', color: 'red' },
    { value: false, label: 'Activo', color: 'green' },
  ],
  modules: MODULES.map(toOption),
  modulesRole: [
    {
      value: 'admin',
      label: 'Administrador',
      description: 'Tiene acceso total a todos los módulos y funciones.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Editor',
      description: 'Puede editar y crear registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Visualizador',
      description: 'Solo puede ver y leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const optionsUsersMap = {
  Módulo: optionsUsers.modules.map(({ color, ...option }) => option),
  Rol: optionsUsers.modulesRole,
}

export const relationsUsers: Record<string, boolean> = {
  // NOTE: user relations explicitly disabled for security/performance
  // also since they are never  needed in the /admin/auth/users page
}

export const viewSettingsUsers: ViewSettings = {
  tableName: 'users',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'emailVerified',
      label: 'Email verificado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'banned',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'modules',
      label: 'Módulos',
    },
  ],
}
