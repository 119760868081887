import { AppProductionorderitemsSlideover, AppProductionorderitemsSlideoverItems } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppProductionorderitemsSlideoverProps = ComponentProps<typeof AppProductionorderitemsSlideover>
type AppProductionorderitemsSlideoverItemsProps = ComponentProps<typeof AppProductionorderitemsSlideoverItems>

export type ProductionorderitemsUtils = ReturnType<typeof useProductionorderitemsUtils>

export const useProductionorderitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Productionorderitem>('productionorderitems')

  const slideoverOpenCreate = (props: AppProductionorderitemsSlideoverProps) => {
    return new Promise<Productionorderitem>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultProductionorderitem({userId: user.value.id, ...props.data})
      slideover.open(AppProductionorderitemsSlideover, {
        type: 'POST',
        title: 'Agregar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductionorderitemsSlideoverProps) => {
    return new Promise<Productionorderitem>((resolve, reject) => {
      const data = getDefaultProductionorderitem(props.data)
      slideover.open(AppProductionorderitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const slideoverOpenItems = (props: AppProductionorderitemsSlideoverItemsProps) => {
    return new Promise<Productionorder>((resolve, reject) => {
      const data = getDefaultProductionorder(props.data)
      slideover.open(AppProductionorderitemsSlideoverItems, {
        title: 'Agregar items',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    quantityPlanned: z.number().int().positive(),
    productionorderId: z.string(),
    productitemId: z.string(),
    userId: z.string(),
    attributes: z.object({
      measurements: z.string(),
    }),
    text: z.string(),
    images: z.array(z.string()).nonempty("Es necesario al menos una imagen"),
  })

  // actions:

  const getDropdownItems = (row: Productionorderitem, refresh: () => Promise<void>, utils: ProductionorderitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'productionorderitems', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'productionorderitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'productionorderitems', row, refresh, utils),
      getDropdownItem('files', 'productionorderitems', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'productionorderitems', row, refresh, utils),
      {
        label: `${row.immutable ? 'Habilitar' : 'Deshabilitar'}`,
        icon: row.immutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.productionorder?.immutable,
        click: async () => {
          await onSubmitUpdate({ ...row, immutable: !row.immutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('archive', 'productionorderitems', row, refresh, utils),
      getDropdownItem('delete', 'productionorderitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenItems,
    schema,
    getDropdownItems,
  }
}

export const useProductionorderitemsUtils = ({ $productitems, $salesorderitems, $users }: {
  $productitems?: Awaited<ReturnType<typeof useFetch<ProductitemWithRelations[]>>>
  $salesorderitems?: Awaited<ReturnType<typeof useFetch<Salesorderitem[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsProductionorderitems,
    productitemId: computed(() => $productitems?.data.value.map(toOption)),
    salesorderitemId: computed(() => $salesorderitems?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsProductionorderitems.type, 'value'),
    status: toMapByKey(optionsProductionorderitems.status, 'value'),
    priority: toMapByKey(optionsProductionorderitems.priority, 'value'),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    salesorderitemId: computed(() => toMapByKey($salesorderitems?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    productitemsByProductType: computed(() => toMapByNestedKey($productitems?.data.value, 'product', 'type')),
    salesorderitemsBySalesorderId: computed(() => toMapByGroupKey($salesorderitems?.data.value, 'salesorderId')),
  }
  return { options, maps }
}
