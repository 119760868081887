import { AppSalesordersSlideover } from '#components'
import type { Salesorder } from '#netzo/shared/types/db'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppSalesordersSlideoverProps = ComponentProps<typeof AppSalesordersSlideover>

export type SalesordersUtils = ReturnType<typeof useSalesordersUtils>

export const useSalesorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Salesorder>('salesorders')

  const slideoverOpenCreate = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultSalesorder({ userId: user.value.id, ...props.data })
      slideover.open(AppSalesordersSlideover, {
        title: 'Registrar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
        navigateToSubpage: true,
      })
    })
  }

  const slideoverOpenEdit = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.open(AppSalesordersSlideover, {
        title: 'Editar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schemaPendingDelivery = z.object({
    status: z.string(),
    name: z.string(),
    date: z.string(),
    payment: paymentSchema,
    delivery: z.object({
      incoterm: z.enum(['pending', 'EXW']),
      schedule: z.string(),
      isDatetime: z.boolean().optional(),
      date: z.string().nullable().optional(),
      useAccountAddress: z.boolean(),
      address: z.object({}),
      text: z.string().nullable().optional(),
    }),
    accountId: z.string(),
    dealId: z.string(),
    quoteId: z.string(),
    userId: z.string(),
  })

  const schemaWithDelivery = z.object({
    status: z.string(),
    name: z.string(),
    date: z.string(),
    payment: paymentSchema,
    delivery: z.object({
      incoterm: z.enum(['FCA', 'CPT', 'CIP', 'DAP', 'DPU', 'DDP', 'FAS', 'FOB', 'CFR', 'CIF']),
      schedule: z.string(),
      isDatetime: z.boolean().optional(),
      date: z.string().nullable().optional(),
      useAccountAddress: z.boolean(),
      address: z.object({
        street: z.string().nullable(),
        numberExterior: z.string().nullable(),
        numberInterior: z.string().nullable().optional(),
        neighborhood: z.string().nullable().optional(),
        city: z.string().nullable(),
        district: z.string().nullable().optional(),
        state: z.string().nullable(),
        country: z.string().nullable(),
        postalCode: z.string().nullable(),
      }),
      text: z.string().nullable().optional(),
    }),
    accountId: z.string(),
    dealId: z.string(),
    quoteId: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Salesorder, refresh: () => Promise<void>, utils: SalesordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'salesorders', row, refresh, utils),
      getDropdownItem('filePdf', 'salesorders', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'salesorders', row, refresh, utils),
      getDropdownItem('copyUid', 'salesorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'salesorders', row, refresh, utils),
      getDropdownItem('files', 'salesorders', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'salesorders', row, refresh, utils),
      getDropdownItem('immutable', 'salesorders', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'salesorders', row, refresh, utils),
      getDropdownItem('delete', 'salesorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const createPdf = async (data: SalesorderWithRelations, utils: SalesordersUtils) => {
    const documentDefinition = await createPdfmakeDocumentDefinitionSalesorders(data, utils)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the salesorder.files array (to be used after POST/PATCH)
  async function createFilePdf(data: Partial<Salesorder>, utils: SalesordersUtils) {
    const { uploadFile } = useApiBlob('salesorders', data.id)
    const [existingData] = await $fetch<SalesorderWithRelations[]>(`/api/db/salesorders`, {
      query: { id: data.id, $with: relationsSalesorders },
    })
    const newData = { ...existingData, ...data } as SalesorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'salesorder.pdf', { type: 'application/pdf' })
    return uploadFile('filePdf', file)
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schemaPendingDelivery,
    schemaWithDelivery,
    getDropdownItems,
    createPdf,
    createFilePdf,
  }
}

export const useSalesordersUtils = ({ $accounts, $branches, $contacts, $deals, $quotes, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $quotes?: Awaited<ReturnType<typeof useFetch<Quote[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsSalesorders,
    delivery: optionsDeliveries,
    productionorder: {
      ...optionsProductionorders,
    },
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    quoteId: computed(() => $quotes?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    status: toMapByKey(optionsSalesorders.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      cfdiType: toMapByKey(optionsSAT.cfdiType, 'value'),
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    payment: {
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
    },
    delivery: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    quoteId: computed(() => toMapByKey($quotes?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    dealByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    quoteByAccountId: computed(() => toMapByGroupKey($quotes?.data.value, 'accountId')),
    quoteByDealId: computed(() => toMapByGroupKey($quotes?.data.value, 'dealId')),
  }

  return { options, maps }
}
