import type { Options } from '#netzo/shared/types/core'
import { z } from '#netzo/shared/utils/zod'

export const paymentSchema = z.object({
  satCfdiUse: z.string(),
  satPaymentMethod: z.string(),
  satPaymentForm: z.string(),
  conditions: z.string(),
  currency: z.string(),
  exchangeRate: z.object({
    value: z.number(),
    date: z.string().datetime(),
  }),
})

export const optionsBilling = {
  type: [
    { value: 'individual', label: 'Persona Fisica', description: 'Un individuo que emite o recibe facturas a título personal.', icon: 'i-mdi-account', color: 'sky' },
    { value: 'legalEntity', label: 'Persona Moral', description: 'Una entidad legal que emite o recibe facturas en nombre de una empresa o corporación.', icon: 'i-mdi-domain', color: 'lime' },
    { value: 'foreignLegalEntity', label: 'Persona Moral Extranjera', description: 'Una entidad legal extranjera que emite o recibe facturas en nombre de una empresa o corporación fuera del país.', icon: 'i-mdi-web', color: 'yellow' },
    { value: 'genericRFC', label: 'RFC Genérico', description: 'Un RFC genérico utilizado para operaciones que no requieren facturación personalizada.', icon: 'i-mdi-file-document', color: 'fuchsia' },
  ],
  paymentConditions: [
    { value: 'NET0', label: 'NET0 - Pago inmediato', description: 'Pago inmediato a la entrega del producto o servicio.', color: 'sky' },
    { value: 'NET7', label: 'NET7 - Pago en 7 días', description: 'Pago a realizarse dentro de 7 días a partir de la emisión de la factura.', color: 'sky' },
    { value: 'NET15', label: 'NET15 - Pago en 15 días', description: 'Pago a realizarse dentro de 15 días a partir de la emisión de la factura.', color: 'lime' },
    { value: 'NET30', label: 'NET30 - Pago en 30 días', description: 'Pago a realizarse dentro de 30 días a partir de la emisión de la factura.', color: 'indigo' },
    { value: 'NET45', label: 'NET45 - Pago en 45 días', description: 'Pago a realizarse dentro de 45 días a partir de la emisión de la factura.', color: 'orange' },
    { value: 'NET60', label: 'NET60 - Pago en 60 días', description: 'Pago a realizarse dentro de 60 días a partir de la emisión de la factura.', color: 'yellow' },
    { value: 'NET90', label: 'NET90 - Pago en 90 días', description: 'Pago a realizarse dentro de 90 días a partir de la emisión de la factura.', color: 'amber' },
    { value: 'NET120', label: 'NET120 - Pago en 120 días', description: 'Pago a realizarse dentro de 120 días a partir de la emisión de la factura.', color: 'green' },
    { value: 'COD', label: 'COD - Pago contra entrega', description: 'Pago realizado en el momento de la entrega del producto. Adecuado para pedidos con entrega física.', color: 'green' },
    { value: 'PREPAY', label: 'PREPAY - Pago anticipado', description: 'Pago total o parcial realizado antes del envío del producto. Asegura la confirmación del pedido.', color: 'emerald' },
    { value: 'INSTALLMENTS', label: 'INSTALLMENTS - Pago en plazos', description: 'Pago dividido en varias cuotas, según un plan acordado. Ideal para venta grandes o personalizadas.', color: 'teal' },
    { value: 'EOM', label: 'EOM - Pago fin de mes', description: 'Pago realizado al final del mes en el que se emite la factura. Útil para la conciliación de cuentas mensuales.', color: 'violet' },
    { value: 'EARLY_PAYMENT_DISCOUNT', label: 'Descuento por pronto pago', description: 'Descuento ofrecido si el pago se realiza dentro de un plazo específico, por ejemplo, 2/10 Net 30.', color: 'cyan' },
    { value: 'DEFERRED_PAYMENT', label: 'Pago diferido', description: 'Pago realizado en una fecha futura específica, más allá de los plazos estándar. Flexibilidad adicional para el cliente.', color: 'fuchsia' },
  ],
} satisfies Options
