<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Issue>
  onSubmit: (event: FormSubmitEvent<Partial<Issue>>) => void
  openNestedCallback: (data: Partial<Issue>) => void
  title?: string
  disabledFields?: (keyof Issue | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Issue>>(props.data)

const issues$ = useIssues()
const utils = useIssuesUtils({ })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: 'i-mdi-checkbox-marked-outline',
      defaultOpen: true,
    },
  ]
})

if (user.value?.id) state.value.userId ||= user.value?.id

const loading = ref(false)

const onSubmitIssue = async (event: FormSubmitEvent<Partial<Issue>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.issues"
      :validate-on="['submit']"
      :schema="issues$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitIssue"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(issues$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(issues$.schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              autoresize
              placeholder="Escribe una descripción..."
              :disabled="disabledFields?.includes('description')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(issues$.schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(issues$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="issues"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.issues"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
