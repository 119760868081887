import { AppPricelistsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { PricelistWithRelations } from '#netzo/utils/app/pricelists'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppPricelistsSlideoverProps = ComponentProps<typeof AppPricelistsSlideover>

export type PricelistsUtils = ReturnType<typeof usePricelistsUtils>

export const usePricelists = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Pricelist>('pricelists')

  const slideoverOpenCreate = (props: AppPricelistsSlideoverProps) => {
    return new Promise<Pricelist>((resolve, reject) => {
      const data = getDefaultPricelist(props.data)
      slideover.open(AppPricelistsSlideover, {
        title: 'Crear lista de precios',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
        navigateToSubpage: true,
      })
    })
  }

  const slideoverOpenEdit = (props: AppPricelistsSlideoverProps) => {
    return new Promise<Pricelist>((resolve, reject) => {
      const data = getDefaultPricelist(props.data)
      slideover.open(AppPricelistsSlideover, {
        title: 'Editar lista de precios',
        ...props,
        data,
        disabledFields: ['type'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    date: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Pricelist, refresh: () => Promise<void>, utils: PricelistsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'pricelists', row, refresh, utils),
      getDropdownItem('filePdf', 'pricelists', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'pricelists', row, refresh, utils),
      getDropdownItem('copyUid', 'pricelists', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'pricelists', row, refresh, utils),
      getDropdownItem('files', 'pricelists', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'pricelists', row, refresh, utils),
      getDropdownItem('immutable', 'pricelists', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'pricelists', row, refresh, utils),
      getDropdownItem('delete', 'pricelists', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const createPdf = async (data: PricelistWithRelations, utils: PricelistsUtils) => {
    const [categories, productitems, serviceitems] = await Promise.all([
      $fetch<Category[]>(`/api/db/categories`, {
        query: { $columns: ['id', 'name'] },
      }),
      $fetch<(Productitem & { product: Product })[]>(`/api/db/productitems`, {
        query: { $with: { product: true } },
      }),
      $fetch<(Serviceitem & { service: Service })[]>(`/api/db/serviceitems`, {
        query: { $with: { service: true } },
      }),
    ])

    const items = data.type === 'products' ? productitems : serviceitems
    const documentDefinition = await createPdfmakeDocumentDefinitionPricelists(data, categories, items, utils)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the pricelist.files array (to be used after POST/PATCH)
  async function createFilePdf(data: Partial<Pricelist>, utils: PricelistsUtils) {
    const { uploadFile } = useApiBlob('pricelists', data.id)
    const [existingData] = await $fetch<PricelistWithRelations[]>(`/api/db/pricelists`, {
      query: { id: data.id, $with: relationsPricelists },
    })
    const newData = { ...existingData, ...data } as PricelistWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'pricelist.pdf', { type: 'application/pdf' })
    return uploadFile('filePdf', file)
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
    createPdf,
    createFilePdf,
  }
}

export const usePricelistsUtils = ({ $productitems, $serviceitems }: {
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsPricelists,
    productitems: computed(() => $productitems?.data.value.map(toOption)),
    serviceitems: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsPricelists.type, 'value'),
    status: toMapByKey(optionsPricelists.status, 'value'),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  }

  return { options, maps }
}
