<script setup lang="ts">
import type { DashboardSidebarLink } from '#ui-pro/types'

const runtimeConfig = useRuntimeConfig()
const appConfig = useAppConfig()
const { user } = useUserSession()
const { modules, moduleId } = useModules()

// const $usernotifications = await useFetch<Notification[]>('/api/db/usernotifications', {
//   // key: `usernotifications-${user.value!.id}`, // syncs with $usernotifications in /notifications page (on CRUD)
//   query: { userId: user.value!.id, $with: { notification: true } },
//   default: () => [],
// })

const {
  // moduleId,
  coreModules,
  otherModules,
  activeModule,
  links,
  items,
  isModuleActive,
} = useNavigation(modules)

// DISABLED: UDashboardSearchButton and LazyUDashboardSearch disabled until v1 ready
// const groups = computed(() => [
//   {
//     key: 'links',
//     label: 'Módulos',
//     commands: links.value.flat().map(link => ({
//       ...link,
//       shortcuts: link.tooltip?.shortcuts,
//     })),
//   },
// ])

const footerLinks = computed<DashboardSidebarLink[]>(() => [
  {
    label: 'Tareas',
    icon: 'i-mdi-checkbox-marked-outline',
    to: '/tasks',
    tooltip: { text: 'Tareas' },
  },
  // {
  //   label: 'Notificaciones',
  //   icon: 'i-mdi-bell',
  //   to: '/notifications',
  //   tooltip: { text: 'Notificaciones' },
  //   ...($usernotifications.data.value?.length && {
  //     badge: {
  //       label: String($usernotifications.data.value.length),
  //       color: 'red',
  //       size: 'xs',
  //     },
  //   }),
  // },
  // DISABLED: until v1 ready
  // {
  //   label: 'Asistente',
  //   icon: 'i-mdi-robot',
  //   to: '/assistant',
  //   tooltip: { text: 'Asistente' },
  //   badge: {
  //     label: 'pronto',
  //     color: 'primary',
  //     size: 'xs',
  //   },
  // },
])

const logo = useTemplateRef('logo')
const isHoveredLogo = useElementHover(logo)
</script>

<template>
  <UDashboardPanel
    :width="250"
    :resizable="{ min: 250, max: 350, storage: 'local' }"
    collapsible
  >
    <UDashboardNavbar :ui="{ wrapper: 'bg-[rgb(var(--ui-backgroundElevated))]' }">
      <template #left>
        <UButton
          ref="logo"
          variant="ghost"
          to="/"
          square
        >
          <UAvatar
            v-bind="isHoveredLogo && moduleId
              ? { icon: 'i-mdi-arrow-left' }
              : { src: appConfig.netzo.favicon }
            "
            title="Inicio"
            :ui="{ rounded: 'rounded-none' }"
            size="xs"
          />
        </UButton>
        <h2
          v-if="!isModuleActive"
          class="text-gray-900 dark:text-white text-lg font-semibold truncate px-2.5 py-1.5"
        >
          {{ appConfig.netzo.title }}
        </h2>
        <ModulesDropdown v-else />
      </template>
    </UDashboardNavbar>

    <UDashboardSidebar>
      <template #header>
        <!-- <UDashboardSearchButton label="Buscar..." /> -->
        <div teleport-dashboard-sidebar-header />
      </template>

      <template
        v-for="(linksGroup, i) in links"
        :key="`group-${i}`"
      >
        <UDashboardSidebarLinks :links="linksGroup">
          <template #divider>
            <UDivider />
          </template>
        </UDashboardSidebarLinks>
        <UDivider v-if="i < links.length - 1" />
      </template>

      <div class="flex-1" />

      <UDashboardSidebarLinks :links="footerLinks" />

      <UDivider class="sticky bottom-0" />

      <template #footer>
        <footer class="w-full">
          <UserDropdown />
          <!-- © yyyy Netzo | Version x.y.z -->
          <!-- <span class="block text-[10px] text-gray-500 dark:text-gray-400 text-center mt-2 -mb-1">
              © {{ new Date().getFullYear() }} Netzo | Versión {{ runtimeConfig.public?.version }}
            </span> -->
        </footer>
      </template>
    </UDashboardSidebar>
  </UDashboardPanel>
</template>
