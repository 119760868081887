import { AppFinancialaccountsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppFinancialaccountsSlideoverProps = ComponentProps<typeof AppFinancialaccountsSlideover>

export type FinancialaccountsUtils = ReturnType<typeof useFinancialaccountsUtils>

export const useFinancialaccounts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Financialaccount>('financialaccounts')

  const slideoverOpenCreate = (props: AppFinancialaccountsSlideoverProps) => {
    return new Promise<Financialaccount>((resolve, reject) => {
      const data = getDefaultFinancialaccount(props.data)
      slideover.open(AppFinancialaccountsSlideover, {
        title: 'Crear cuenta financiera',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppFinancialaccountsSlideoverProps) => {
    return new Promise<Financialaccount>((resolve, reject) => {
      const data = getDefaultFinancialaccount(props.data)
      slideover.open(AppFinancialaccountsSlideover, {
        title: 'Editar cuenta financiera',
        ...props,
        disabledFields: ['type'],
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    subtype: z.string(),
    name: z.string(),
    currency: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Financialaccount, refresh: () => Promise<void>, utils: FinancialaccountsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'financialaccounts', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'financialaccounts', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'financialaccounts', row, refresh, utils),
      getDropdownItem('files', 'financialaccounts', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ], [
      getDropdownItem('info', 'financialaccounts', row, refresh, utils),
      getDropdownItem('immutable', 'financialaccounts', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'financialaccounts', row, refresh, utils),
      getDropdownItem('delete', 'financialaccounts', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useFinancialaccountsUtils = ({ $banks, $users }: {
  $banks?: Awaited<ReturnType<typeof useFetch<Bank[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsFinancialaccounts,
    bankId: computed(() => $banks?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsFinancialaccounts.type, 'value'),
    subtype: toMapByKeyFromObject(optionsFinancialaccounts.subtype, 'value'),
    creditType: toMapByKey(optionsFinancialaccounts.creditType, 'value'),
    interestRateType: toMapByKey(optionsFinancialaccounts.interestRateType, 'value'),
    paymentInterval: toMapByKey(optionsFinancialaccounts.paymentInterval, 'value'),
    currency: toMapByKey(optionsSAT.currency, 'value'),
    bankId: computed(() => toMapByKey($banks?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
  }

  return { options, maps }
}
