<script setup lang="ts">
import { z } from '#netzo/shared/utils/zod'
import type { FormSubmitEvent } from '#ui/types'

const email = useRouteQuery<string>('email')

const otpSchema = z.object({
  email: z.string().email('Email inválido'),
})

const passwordSchema = z.object({
  email: z.string().email('Email inválido'),
  password: z.string().min(8, 'Tiene que tener al menos 8 caracteres'),
})

const schema = z.union([otpSchema, passwordSchema])

const { fetch: refreshSession } = useUserSession()

// avoid 'undefined' and falsey values for route.query.email
const state = reactive({
  email: email.value,
  password: undefined,
})
watchDebounced(state, () => {
  email.value = state.email
}, { debounce: 50 })

const loading = ref(false)

async function onSubmitPassword(event: FormSubmitEvent<typeof state>) {
  try {
    loading.value = true
    const { email, password } = event.data
    await $fetch('/api/auth/login-with-password', {
      method: 'POST',
      body: { email, password },
    })
    await refreshSession()
    useToastAlert().success(ALERTS().loggedIn())
    navigateTo('/')
  }
  catch (error) {
    loading.value = false
    useToastAlert().error(ALERTS().error(error.data?.statusMessage))
  }
}

async function onSubmitOTP(event: FormSubmitEvent<typeof state>) {
  try {
    loading.value = true
    const { email } = event.data
    await $fetch('/api/auth/login-with-otp', {
      method: 'POST',
      body: { email },
    })
    useToastAlert().success(ALERTS().otpSent())
    navigateTo(`/auth/verify-otp?email=${encodeURIComponent(event.data.email!)}&type=login`)
  }
  catch (error) {
    loading.value = false
    useToastAlert().error(ALERTS().error(error.data?.statusMessage))
  }
}

async function onSubmit(event: FormSubmitEvent<typeof state>) {
  if (event.data.password) return onSubmitPassword(event)
  return onSubmitOTP(event)
}

// WORKAROUND: UInput variant set to 'outline' on errored state which
// inherits 'bg-transparent' so we need to override to preserve colors
const UI_INPUT = { variant: { outline: 'bg-white dark:bg-gray-900' } }
</script>

<template>
  <UForm
    :schema="state.password ? passwordSchema : otpSchema"
    :state="state"
    class="space-y-2 max-w-sm"
    @submit="onSubmit"
  >
    <UFormGroup
      label="Email"
      name="email"
      size="lg"
    >
      <InputText
        v-model.lowercase="state.email"
        autocomplete="email"
        :ui="UI_INPUT"
      />
    </UFormGroup>
    <UFormGroup
      label="Contraseña"
      name="password"
      size="lg"
    >
      <InputPassword
        v-model="state.password"
        autocomplete="current-password"
        :placeholder="state.password ? undefined : 'Opcional'"
        :ui="UI_INPUT"
      />
      <template #hint>
        <UButton
          variant="link"
          :padded="false"
          :to="REGEX.email.test(state.email)
            ? `/auth/forgot-password?email=${encodeURIComponent(state.email)}`
            : undefined
          "
          size="xs"
          color="gray"
        >
          ¿Olvidaste tu contraseña?
        </UButton>
      </template>
    </UFormGroup>
    <UButton
      color="primary"
      type="submit"
      size="lg"
      block
      :icon="state.password ? undefined : 'i-mdi-email'"
      :loading="loading"
      :disabled="!REGEX.email.test(state.email) || loading"
      class="!mt-4"
    >
      {{ state.password ? 'Iniciar sesión' : 'Enviar link de acceso' }}
    </UButton>
  </UForm>
</template>
