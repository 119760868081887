<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Productionorderitem>
  onSubmit: (event: FormSubmitEvent<Partial<Productionorderitem>>) => void
  openNestedCallback: (data: Partial<Productionorderitem>) => void
  title?: string
  disabledFields?: (keyof Productionorderitem | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Productionorderitem>>(getDefaultProductionorderitem(props.data))

const productionorderitems$ = useProductionorderitems()

const $productionorders = await useFetch<ProductionorderWithRelations[]>('/api/db/productionorders', {
  query: { id: props.data.productionorderId!, $with: relationsProductionorders },
  default: () => [],
})
const $productitems = await useFetch<ProductitemWithRelations[]>('/api/db/productitems', {
  query: { $with: relationsProductitems },
  default: () => [],
})
const $salesorderitems = await useFetch<Salesorderitem[]>('/api/db/salesorderitems', {
  query: { type: ['products'], salesorderId: $productionorders.data.value?.[0]?.salesorderId },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useProductionorderitemsUtils({ $productitems, $salesorderitems, $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.productionorderitems,
      defaultOpen: true,
    },
    {
      slot: 'approval',
      label: 'Aprobación',
      icon: 'i-mdi-check-decagram',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const selectedTab = ref(!state.value.salesorderitemId ? 0 : 1)
const tabs = [
  {
    label: 'Nuevo',
    icon: 'i-mdi-plus-circle',
  },
  {
    label: 'Orden de Venta',
    icon: ICONS.salesorders,
  },
]

const internal = computed(() => ['internal'].includes(state.value?.productionorder?.type))

const onResetModel = () => {
  state.value = getDefaultProductionorderitem(state.value)
}

const onUpdateProductitemId = (id: string) => {
  if (!id) return
  const productitem = utils.maps.productitemId?.value.get(id) as ProductitemWithRelations
  if (!productitem) return
  state.value.type = productitem!.product!.type
  state.value.name = productitem!.name.toUpperCase()
}

const onUpdateSalesorderitemId = (id: string) => {
  const salesorderitem = utils.maps.salesorderitemId?.value.get(id)
  if (!salesorderitem) return
  state.value.productitemId = salesorderitem!.productitemId
  state.value.quantityPlanned = salesorderitem!.quantity
  onUpdateProductitemId(salesorderitem!.productitemId as string)
  state.value.name = salesorderitem!.name.toUpperCase()
}

const loading = ref(false)

const onSubmitProductionorderitem = async (event: FormSubmitEvent<Partial<Productionorderitem>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.productionorderitems"
      :validate-on="['submit']"
      :schema="productionorderitems$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProductionorderitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UTabs
            v-if="!internal"
            v-model="selectedTab"
            :items="tabs"
            @update:model-value="onResetModel"
          />

          <UFormGroup
            v-if="!internal && selectedTab === 1"
            label="Item de Orden de Venta"
            name="salesorderitemId"
            :required="isRequired(productionorderitems$.schema, 'salesorderitemId') || selectedTab === 1"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'salesorderitems',
                idField: 'salesorderitemId',
                utils: utils,
                options: utils.options.salesorderitemId.value ?? [],
                disabled: disabledFields?.includes('salesorderitemId'),
                onClickUpdate: onUpdateSalesorderitemId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Tipo de producto`"
            name="type"
            :required="isRequired(productionorderitems$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type ?? []"
              :disabled="disabledFields?.includes('type') || selectedTab === 1"
            />
          </UFormGroup>

          <UFormGroup
            label="Item"
            name="productitemId"
            :required="isRequired(productionorderitems$.schema, 'productitemId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'productitems',
                idField: 'productitemId',
                utils: utils,
                options: utils.maps.productitemsByProductType?.value.get(state.type)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('productitemId') || selectedTab === 1,
                onClickUpdate: onUpdateProductitemId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(productionorderitems$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <div :class="FIELDSET_FULL_2">
            <UFormGroup
              label="Cantidad (plan)"
              name="quantityPlanned"
              :required="isRequired(productionorderitems$.schema, 'quantityPlanned')"
            >
              <UInput
                v-model="state.quantityPlanned"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityPlanned')"
              />
            </UFormGroup>

            <UFormGroup
              label="Cantidad (real)"
              name="quantityActual"
              :required="isRequired(productionorderitems$.schema, 'quantityActual')"
            >
              <UInput
                v-model="state.quantityActual"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityActual')"
              />
            </UFormGroup>
          </div>

          <UFormGroup
            label="Imágenes"
            name="images"
            :required="isRequired(productionorderitems$.schema, 'images')"
          >
            <InputImages
              v-model="state.images"
              table-name="productionorderitems"
              :record-id="state.id"
              :disabled="disabledFields?.includes('images')"
            />
          </UFormGroup>

          <UFormGroup
            label="Medidas"
            name="attributes.measurements"
            :required="isRequired(productionorderitems$.schema, 'attributes.measurements')"
          >
            <InputText
              v-model.defaultcase="state.attributes.measurements"
              :disabled="disabledFields?.includes('attributes.measurements')"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="productionorderitems"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Especificaciones"
            name="text"
            :required="isRequired(productionorderitems$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UTabs
            v-if="!internal"
            v-model="selectedTab"
            :items="tabs"
            @update:model-value="onResetModel"
          />

          <UFormGroup
            v-if="!internal && selectedTab === 1"
            label="Item de Orden de Venta"
            name="salesorderitemId"
            :required="isRequired(productionorderitems$.schema, 'salesorderitemId') || selectedTab === 1"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'salesorderitems',
                idField: 'salesorderitemId',
                utils: utils,
                options: utils.options.salesorderitemId.value ?? [],
                disabled: disabledFields?.includes('salesorderitemId'),
                onClickUpdate: onUpdateSalesorderitemId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Tipo de producto`"
            name="type"
            :required="isRequired(productionorderitems$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type ?? []"
              :disabled="disabledFields?.includes('type') || selectedTab === 1"
            />
          </UFormGroup>

          <UFormGroup
            label="Item"
            name="productitemId"
            :required="isRequired(productionorderitems$.schema, 'productitemId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'productitems',
                idField: 'productitemId',
                utils: utils,
                options: utils.maps.productitemsByProductType?.value.get(state.type)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('productitemId') || selectedTab === 1,
                onClickUpdate: onUpdateProductitemId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(productionorderitems$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(productionorderitems$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(productionorderitems$.schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(productionorderitems$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <div :class="FIELDSET_FULL_2">
            <UFormGroup
              label="Cantidad (plan)"
              name="quantityPlanned"
              :required="isRequired(productionorderitems$.schema, 'quantityPlanned')"
            >
              <UInput
                v-model="state.quantityPlanned"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityPlanned')"
              />
            </UFormGroup>

            <UFormGroup
              label="Cantidad (real)"
              name="quantityActual"
              :required="isRequired(productionorderitems$.schema, 'quantityActual')"
            >
              <UInput
                v-model="state.quantityActual"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantityActual')"
              />
            </UFormGroup>
          </div>

          <UFormGroup
            label="Imágenes"
            name="images"
            :required="isRequired(productionorderitems$.schema, 'images')"
          >
            <InputImages
              v-model="state.images"
              table-name="productionorderitems"
              :record-id="state.id"
              :disabled="disabledFields?.includes('images')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="productionorderitems"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Medidas"
            name="attributes.measurements"
            :required="isRequired(productionorderitems$.schema, 'attributes.measurements')"
          >
            <InputText
              v-model.defaultcase="state.attributes.measurements"
              :disabled="disabledFields?.includes('attributes.measurements')"
            />
          </UFormGroup>

          <UFormGroup
            label="Especificaciones"
            name="text"
            :required="isRequired(productionorderitems$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #approval>
          <AppProductionorderitemsFieldsetApproval v-model="state" :utils="utils" />
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.productionorderitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
