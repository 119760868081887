<script setup lang="ts">
import type { BlobObject } from '@nuxthub/core'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  file: BlobObject
  compact?: boolean
}>()

const fileProps = computed(() => getFileProps(props.file))
</script>

<template>
  <div
    class="grid place-items-center"
    :style="{ '--dynamic-color': getFileProps(file)?.color }"
  >
    <img
      v-if="file.contentType?.startsWith('image/')"
      class="contain"
      :class="[compact ? 'h-8' : 'h-24']"
      :src="`/api/blob/${file.pathname}`"
      v-bind="$attrs"
    >
    <video
      v-else-if="file.contentType?.startsWith('video/')"
      class="contain"
      :class="[compact ? 'h-8' : 'h-24']"
      :src="`/api/blob/${file.pathname}`"
      v-bind="$attrs"
    />
    <!-- NOTE: same compact/normal height as ViewRendererImage (2rem === h-8 and 6rem === h-24) -->
    <UIcon
      v-else-if="file.pathname"
      :name="getFileProps(file)?.icon"
      :color="getFileProps(file)?.color"
      class="text-[var(--dynamic-color)]"
      :class="[compact ? 'text-[2rem]' : 'text-[6rem]']"
      v-bind="$attrs"
    />
    <ViewPlaceholder
      v-else
      icon="i-mdi-file"
      label="No hay un archivo para mostrar."
    />
  </div>
</template>
