<script setup lang="ts">
import { parseISO } from 'date-fns'

const props = defineProps<{
  title?: string
  datetime?: string // date or datetime format
  exact?: boolean
  displayHours?: boolean
  locale?: 'es-MX' | 'en-US'
}>()

const date = computed(() => {
  if (!props.datetime) return

  const localDate = parseISO(props.datetime)

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  const formatted = !props.displayHours
    ? localDate.toLocaleDateString(props.locale ?? 'es-MX', options)
    : localDate.toLocaleString(props.locale ?? 'es-MX')

  const timeAgoLocale = props.locale === 'en-US' ? 'en' : 'es'

  return {
    formatted,
    timeAgo: useLocaleTimeAgo(timeAgoLocale, localDate),
  }
})
</script>

<template>
  <time v-if="datetime" :title="title || 'Fecha'">
    {{ exact ? date?.formatted : date?.timeAgo }}
  </time>
</template>
