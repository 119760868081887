import type { TableName } from '#netzo/shared/types/db'

export const useTable = (tableName: TableName) => {
  switch (tableName) {
    case 'accountgroups': return useAccountgroups()
    case 'accounts': return useAccounts()
    case 'activities': return useActivities()
    case 'apikeys': return useApikeys()
    case 'applications': return useApplications()
    case 'auditlogs': return useAuditlogs()
    // case 'authcredentials': return useAuthcredentials()
    // case 'authemailverificationcodes': return useAuthemailverificationcodes()
    // case 'authotps': return useAuthotps()
    // case 'authpasswordresettokens': return useAuthpasswordresettokens()
    case 'banks': return useBanks()
    case 'branches': return useBranches()
    case 'campaigns': return useCampaigns()
    case 'categories': return useCategories()
    // case 'cfdis': return useCfdis()
    case 'contacts': return useContacts()
    case 'deals': return useDeals()
    case 'files': return useFiles()
    case 'financialaccounts': return useFinancialaccounts()
    case 'industries': return useIndustries()
    case 'issues': return useIssues()
    case 'notes': return useNotes()
    // case 'notifications': return useNotifications()
    case 'pricelistitems': return usePricelistitems()
    case 'pricelists': return usePricelists()
    case 'productionorderitems': return useProductionorderitems()
    case 'productionorders': return useProductionorders()
    case 'productitems': return useProductitems()
    case 'products': return useProducts()
    case 'quoteitems': return useQuoteitems()
    case 'quotes': return useQuotes()
    case 'salesorderitems': return useSalesorderitems()
    case 'salesorders': return useSalesorders()
    case 'serviceitems': return useServiceitems()
    case 'services': return useServices()
    case 'suppliers': return useSuppliers()
    case 'tasks': return useTasks()
    case 'transactions': return useTransactions()
    case 'usernotifications': return useUsernotifications()
    case 'users': return useUsers()
    default: throw new Error(`Table "${tableName}" not found`)
  }
}

export const useTableUtils = (tableName: TableName) => {
  switch (tableName) {
    case 'accountgroups': return useAccountgroupsUtils()
    case 'accounts': return useAccountsUtils()
    case 'activities': return useActivitiesUtils()
    case 'apikeys': return useApikeysUtils()
    case 'applications': return useApplicationsUtils()
    case 'auditlogs': return useAuditlogsUtils()
    // case 'authcredentials': return useAuthcredentialsUtils()
    // case 'authemailverificationcodes': return useAuthemailverificationcodesUtils()
    // case 'authotps': return useAuthotpsUtils()
    // case 'authpasswordresettokens': return useAuthpasswordresettokensUtils()
    case 'banks': return useBanksUtils()
    case 'branches': return useBranchesUtils()
    case 'campaigns': return useCampaignsUtils()
    case 'categories': return useCategoriesUtils()
    // case 'cfdis': return useCfdisUtils()
    case 'contacts': return useContactsUtils()
    case 'deals': return useDealsUtils()
    case 'files': return useFilesUtils()
    case 'financialaccounts': return useFinancialaccountsUtils()
    case 'industries': return useIndustriesUtils()
    case 'issues': return useIssuesUtils()
    case 'notes': return useNotesUtils()
    // case 'notifications': return useNotificationsUtils()
    case 'pricelistitems': return usePricelistitemsUtils()
    case 'pricelists': return usePricelistsUtils()
    case 'productionorderitems': return useProductionorderitemsUtils()
    case 'productionorders': return useProductionordersUtils()
    case 'productitems': return useProductitemsUtils()
    case 'products': return useProductsUtils()
    case 'quoteitems': return useQuoteitemsUtils()
    case 'quotes': return useQuotesUtils()
    case 'salesorderitems': return useSalesorderitemsUtils()
    case 'salesorders': return useSalesordersUtils()
    case 'serviceitems': return useServiceitemsUtils()
    case 'services': return useServicesUtils()
    case 'suppliers': return useSuppliersUtils()
    case 'tasks': return useTasksUtils()
    case 'transactions': return useTransactionsUtils()
    case 'usernotifications': return useUsernotificationsUtils()
    case 'users': return useUsersUtils()
    default: throw new Error(`Table "${tableName}" not found`)
  }
}

export const useTableOptions = (tableName: TableName) => {
  switch (tableName) {
    case 'accountgroups': return optionsAccountgroups
    case 'accounts': return optionsAccounts
    case 'activities': return optionsActivities
    case 'apikeys': return optionsApikeys
    case 'applications': return optionsApplications
    case 'auditlogs': return optionsAuditlogs
    // case 'authcredentials': return optionsAuthcredentials
    // case 'authemailverificationcodes': return optionsAuthemailverificationcodes
    // case 'authotps': return optionsAuthotps
    // case 'authpasswordresettokens': return optionsAuthpasswordresettokens
    case 'banks': return optionsBanks
    case 'branches': return optionsBranches
    case 'campaigns': return optionsCampaigns
    case 'categories': return optionsCategories
    // case 'cfdis': return optionsCfdis
    case 'contacts': return optionsContacts
    case 'deals': return optionsDeals
    case 'files': return optionsFiles
    case 'financialaccounts': return optionsFinancialaccounts
    case 'industries': return optionsIndustries
    case 'issues': return optionsIssues
    case 'notes': return optionsNotes
    // case 'notifications': return optionsNotifications
    case 'pricelistitems': return optionsPricelistitems
    case 'pricelists': return optionsPricelists
    case 'productionorderitems': return optionsProductionorderitems
    case 'productionorders': return optionsProductionorders
    case 'productitems': return optionsProductitems
    case 'products': return optionsProducts
    case 'quoteitems': return optionsQuoteitems
    case 'quotes': return optionsQuotes
    case 'salesorderitems': return optionsSalesorderitems
    case 'salesorders': return optionsSalesorders
    case 'serviceitems': return optionsServiceitems
    case 'services': return optionsServices
    case 'suppliers': return optionsSuppliers
    case 'tasks': return optionsTasks
    case 'transactions': return optionsTransactions
    case 'usernotifications': return optionsUsernotifications
    case 'users': return optionsUsers
    default: throw new Error(`Table "${tableName}" not found`)
  }
}

export const useTableRelations = (tableName: TableName) => {
  switch (tableName) {
    case 'accountgroups': return relationsAccountgroups
    case 'accounts': return relationsAccounts
    case 'activities': return relationsActivities
    case 'apikeys': return relationsApikeys
    case 'applications': return relationsApplications
    case 'auditlogs': return relationsAuditlogs
    // case 'authcredentials': return relationsAuthcredentials
    // case 'authemailverificationcodes': return relationsAuthemailverificationcodes
    // case 'authotps': return relationsAuthotps
    // case 'authpasswordresettokens': return relationsAuthpasswordresettokens
    case 'banks': return relationsBanks
    case 'branches': return relationsBranches
    case 'campaigns': return relationsCampaigns
    case 'categories': return relationsCategories
    // case 'cfdis': return relationsCfdis
    case 'contacts': return relationsContacts
    case 'deals': return relationsDeals
    case 'files': return relationsFiles
    case 'financialaccounts': return relationsFinancialaccounts
    case 'industries': return relationsIndustries
    case 'issues': return relationsIssues
    case 'notes': return relationsNotes
    // case 'notifications': return relationsNotifications
    case 'pricelistitems': return relationsPricelistitems
    case 'pricelists': return relationsPricelists
    case 'productionorderitems': return relationsProductionorderitems
    case 'productionorders': return relationsProductionorders
    case 'productitems': return relationsProductitems
    case 'products': return relationsProducts
    case 'quoteitems': return relationsQuoteitems
    case 'quotes': return relationsQuotes
    case 'salesorderitems': return relationsSalesorderitems
    case 'salesorders': return relationsSalesorders
    case 'serviceitems': return relationsServiceitems
    case 'services': return relationsServices
    case 'suppliers': return relationsSuppliers
    case 'tasks': return relationsTasks
    case 'transactions': return relationsTransactions
    case 'usernotifications': return relationsUsernotifications
    case 'users': return relationsUsers
    default: throw new Error(`Table "${tableName}" not found`)
  }
}

export const useTableOptionsMap = (tableName: TableName) => {
  switch (tableName) {
    case 'accountgroups': return optionsAccountgroupsMap
    case 'accounts': return optionsAccountsMap
    case 'activities': return optionsActivitiesMap
    case 'apikeys': return optionsApikeysMap
    case 'applications': return optionsApplicationsMap
    case 'auditlogs': return optionsAuditlogsMap
    // case 'authcredentials': return optionsAuthcredentialsMap
    // case 'authemailverificationcodes': return optionsAuthemailverificationcodesMap
    // case 'authotps': return optionsAuthotpsMap
    // case 'authpasswordresettokens': return optionsAuthpasswordresettokensMap
    case 'banks': return optionsBanksMap
    case 'branches': return optionsBranchesMap
    case 'campaigns': return optionsCampaignsMap
    case 'categories': return optionsCategoriesMap
    // case 'cfdis': return optionsCfdisMap
    case 'contacts': return optionsContactsMap
    case 'deals': return optionsDealsMap
    case 'files': return optionsFilesMap
    case 'financialaccounts': return optionsFinancialaccountsMap
    case 'industries': return optionsIndustriesMap
    case 'issues': return optionsIssuesMap
    case 'notes': return optionsNotesMap
    // case 'notifications': return optionsNotificationsMap
    case 'pricelistitems': return optionsPricelistitemsMap
    case 'pricelists': return optionsPricelistsMap
    case 'productionorderitems': return optionsProductionorderitemsMap
    case 'productionorders': return optionsProductionordersMap
    case 'productitems': return optionsProductitemsMap
    case 'products': return optionsProductsMap
    case 'quoteitems': return optionsQuoteitemsMap
    case 'quotes': return optionsQuotesMap
    case 'salesorderitems': return optionsSalesorderitemsMap
    case 'salesorders': return optionsSalesordersMap
    case 'serviceitems': return optionsServiceitemsMap
    case 'services': return optionsServicesMap
    case 'suppliers': return optionsSuppliersMap
    case 'tasks': return optionsTasksMap
    case 'transactions': return optionsTransactionsMap
    case 'usernotifications': return optionsUsernotificationsMap
    case 'users': return optionsUsersMap
    default: throw new Error(`Table "${tableName}" not found`)
  }
}

export const useTableInformation = (tableName: TableName, data: Partial<Row>) => {
  switch (tableName) {
    case 'accountgroups': return getAccountgroupInformation(data)
    case 'accounts': return getAccountInformation(data)
    case 'activities': return getActivityInformation(data)
    // case 'apikeys': return getApikeyInformation
    // case 'applications': return getApplicationInformation
    // case 'auditlogs': return getAuditlogInformation
    // case 'authcredentials': return getAuthcredentialInformation
    // case 'authemailverificationcodes': return getAuthemailverificationcodeInformation
    // case 'authotps': return getAuthotpInformation
    // case 'authpasswordresettokens': return getAuthpasswordresettokensInformation
    case 'banks': return getBankInformation(data)
    case 'branches': return getBranchInformation(data)
    case 'campaigns': return getCampaignInformation(data)
    case 'categories': return getCategoryInformation(data)
    // case 'cfdis': return getCfdiInformation
    case 'contacts': return getContactInformation(data)
    case 'deals': return getDealInformation(data)
    // case 'financialaccounts': return getFinancialaccountInformation
    case 'industries': return getIndustryInformation(data)
    // case 'issues': return getIssueInformation
    // case 'notes': return getNoteInformation
    // case 'notifications': return getNotificationInformation
    // case 'pricelistitems': return getPricelistitemInformation
    case 'pricelists': return getPricelistInformation(data)
    // case 'productionorderitems': return getProductionorderitemInformation
    case 'productionorders': return getProductionorderInformation(data)
    case 'productitems': return getProductitemInformation(data)
    case 'products': return getProductInformation(data)
    // case 'quoteitems': return getQuoteitemInformation
    case 'quotes': return getQuoteInformation(data)
    // case 'salesorderitems': return getSalesorderitemInformation
    case 'salesorders': return getSalesorderInformation(data)
    case 'serviceitems': return getServiceitemInformation(data)
    case 'services': return getServiceInformation(data)
    case 'suppliers': return getSupplierInformation(data)
    // case 'tasks': return getTaskInformation
    // case 'transactions': return getTransactionInformation
    // case 'usernotifications': return getUsernotificationInformation
    case 'users': return getUserInformation(data)
    default: return []
  }
}
