import type { File } from '#netzo/shared/types/db'
import type { DropdownItem } from '#ui/types'

export const getDropdownItem = (
  key: string,
  tableName: TableName,
  row: Row,
  refresh: () => Promise<void>,
  utils: RowUtils,
): DropdownItem => {
  const route = useRoute()
  const router = useRouter()
  const { user } = useUserSession()
  const { moduleId } = useModules()

  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenEdit,
  } = useTable(tableName)

  const DROPDOWN_ITEMS: Record<string, DropdownItem> = {
    editOrView: {
      label: row.immutable ? 'Ver' : 'Editar',
      icon: row.immutable ? 'i-mdi-eye' : 'i-mdi-pencil',
      click: () => { slideoverOpenEdit({ data: row }).then(refresh) },
      // shortcuts: row.immutable ? ['v'] : ['e'],
    },
    shareFile: {
      label: 'Compartir',
      icon: 'i-mdi-share-variant',
      click: () => { modalOpenShareFile({ data: row }).then(refresh) },
      // shortcuts: ['s'],
    },
    toggleFileVisibility: {
      label: row.public ? 'Hacer privado' : 'Hacer público',
      icon: row.public ? 'i-mdi-web' : 'i-mdi-web-off',
      disabled: row.immutable === true || !['admin'].includes(user.value?.modules?.[moduleId.value]?.role),
      click: async () => {
        await onSubmitUpdate({ id: row.id, public: !row.public })
        await refresh()
      },
    },
    copyId: {
      label: 'Copiar ID',
      icon: 'i-mdi-content-copy',
      click: () => { copyToClipboard(row.id) },
      // shortcuts: ['C'],
    },
    copyUid: {
      label: 'Copiar Referencia',
      icon: 'i-mdi-content-copy',
      click: () => { copyToClipboard(row.uid) },
    },
    archive: {
      label: row.archivedAt ? 'Desarchivar' : 'Archivar',
      icon: row.archivedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
      disabled: !['admin', 'edit'].includes(user.value?.modules?.[moduleId.value]?.role),
      click: async () => {
        const archivedAt = row?.archivedAt ? null : new Date().toISOString()
        await onSubmitUpdate({ ...row, archivedAt }) // soft delete
        await refresh()
      },
    },
    notes: {
      label: 'Abrir notas',
      icon: 'i-mdi-text',
      click: () => {
        const notes$ = useNotes()
        notes$.slideoverOpenList({
          tableName,
          recordId: row.id,
          data: getDefaultNote({ tableName, recordId: row.id }),
        }).then(refresh)
      },
      // shortcuts: ['n'],
    },
    files: {
      label: 'Ver archivos',
      icon: 'i-mdi-file-multiple',
      click: () => {
        const files$ = useFiles()
        files$.slideoverOpenList({ tableName, recordId: row.id, type: 'files' }).then(refresh)
      },
      // shortcuts: ['f'],
    },
    filePdf: {
      label: 'Ver PDF',
      icon: 'i-mdi-file-pdf',
      variant: 'outline',
      click: () => slideoverOpenFilePreviewPdf(row, 'filePdfId', refresh),
      // shortcuts: ['p'],
    },
    email: {
      label: 'Enviar correo',
      icon: 'i-mdi-email',
      variant: 'outline',
      disabled: !row.contact?.email,
      to: `mailto:${row.contact?.email}`,
      hideLabel: true,
    },
    phone: {
      label: 'Llamar',
      icon: 'i-mdi-phone',
      variant: 'outline',
      disabled: !toPhoneString(row.contact?.phone),
      to: `tel:${toPhoneLinkString(row.contact?.phone)}`,
      hideLabel: true,
    },
    info: {
      label: 'Información',
      icon: 'i-mdi-information',
      click: () => { slideoverOpenInformation(tableName, row) },
      // shortcuts: ['i'],
    },
    immutable: {
      label: row.immutable ? 'Desbloquear' : 'Bloquear',
      icon: row.immutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
      disabled: !['admin', 'edit'].includes(user.value?.modules?.[moduleId.value]?.role),
      click: async () => {
        await onSubmitUpdate({ ...row, immutable: !row.immutable })
        await refresh()
      },
      // shortcuts: ['b'],
    },
    delete: {
      label: 'Eliminar',
      icon: 'i-mdi-delete',
      iconClass: 'bg-red-500',
      labelClass: 'text-red-500',
      disabled: !['admin'].includes(user.value?.modules?.[moduleId.value]?.role),
      click: () => {
        modalOpenDelete({
          onSubmit: async (_event) => {
            if (['files'].includes(tableName)) {
              const { removeFile } = useApiBlob(tableName, row.id)
              await removeFile(row as File)
            }
            else await onSubmitDelete([row])
            modal.close()
            if (route.params.id === row.id) router.push(route.path.split(`/${row.id}`)?.[0])
            await refresh() // must come after router.push navigation
          },
        })
      },
      // shortcuts: ['del'],
    },
  }

  // FIXME: not working maybe since we should call this after dropdownItems
  // are instantiated and within the comopnent lifecycle (not here)
  // defineShortcuts(Object.fromEntries(
  //   Object.values(DROPDOWN_ITEMS)
  //     .filter(item => item.shortcuts?.length)
  //     .map(item => [item.shortcuts![0], () => item.click()]),
  // ))

  return DROPDOWN_ITEMS?.[key]
}
