import type { QuotesUtils } from '#imports'
import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId, createUid } from '#netzo/utils/core/db'
import { toCurrency } from '#netzo/utils/formatting'
import type { CalendarEvent } from '@schedule-x/calendar'
import { addDays, format, parseISO } from 'date-fns'
import { merge } from 'es-toolkit/compat'
import type { Content, ContentColumns, ContentText, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultQuote = (data: Partial<Quote>) => {
  const uid = createUid(new Date(), 'COT') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'totalized',
    status: 'draft',
    uid: uid,
    name: uid,
    receiver: {
      taxCountryCode: 'MX',
      address: {
        country: 'Mexico',
      },
    },
    issuer: {
      taxId: defaultLegalInformation.taxId,
      taxCountryCode: defaultLegalInformation.taxCountryCode,
      legalName: defaultLegalInformation.legalName,
      satFiscalRegime: defaultLegalInformation.satFiscalRegime,
      phone: defaultLegalInformation.phone,
      email: defaultLegalInformation.email,
      issuedIn: defaultLegalInformation.address.postalCode + ' ' + defaultLegalInformation.address.state,
      cfdiType: 'I',
      address: defaultLegalInformation.address,
    },
    message: {
      enabled: true,
      subject: 'Estimado cliente,',
      body: 'En seguimiento a su solicitud de nuestros productos y servicios, presentamos nuestra cotización con las características que se encontrarán a continuación. Agradecemos su tiempo e interés. Por favor, cualquier duda y/o aclaración, háganoslo saber.',
    },
    isDatetime: false,
    issueDate: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    expirationDate: new Date(`${format(addDays(new Date(), 14), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    delivery: {
      incoterm: 'pending',
      schedule: 'pending',
      isDatetime: false,
      useAccountAddress: true,
      address: {
        country: 'Mexico',
      },
    },
    payment: merge(defaultPayment, data?.payment),
    paymentAccounts: [],
    notes: [],
    filePdf: {},
    pdfSettings: defaultPdfSettings,
    tags: [],
    data: getDefaultQuoteData(data?.data ?? {}),
  }, data)
}

export const getQuoteInformation = (data: Partial<QuoteWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsQuotes.type, 'value'),
    status: toMapByKey(optionsQuotes.status, 'value'),
    schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
  }

  const deliverySchedule = data.delivery?.schedule
  const deliveryDate = data.delivery?.date
  const isDatetime = data.delivery?.isDatetime
  const delivery = deliverySchedule !== 'scheduled'
    ? maps.schedule.get(deliverySchedule)?.label
    : deliveryDate
      ? !isDatetime
          ? parseISO(deliveryDate).toLocaleDateString()
          : parseISO(deliveryDate).toLocaleString()
      : null

  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Responsable', data.user?.name],
    ['Cliente', data.account?.name],
    ['Terminos de entrega', maps.incoterm.get(data.delivery?.incoterm)?.label],
    ['Entrega', delivery],
    ['Sucursal', data.branch?.name],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsQuotes = {
  type: [
    { value: 'totalized', label: 'Estándar', description: 'La cotización muestra la suma total de todos los conceptos.', color: 'blue' },
    { value: 'itemized', label: 'Lista de precios', description: 'La cotización solamente muestra los precios de cada concepto.', color: 'green' },
  ], // CANT CHANGE
  status: [
    { value: 'draft', label: 'Borrador', description: 'La cotización está en proceso de creación y no esta finalizada.', icon: 'i-mdi-circle-outline', color: 'gray' },
    { value: 'approval', label: 'Aprobación', description: 'Cotización en estado de aprobación interna antes de ser enviada al cliente.', icon: 'i-mdi-check-circle', color: 'amber' },
    { value: 'send', label: 'Envio', description: 'Cotización enviada al cliente para su revisión.', icon: 'i-mdi-send', color: 'blue' },
    { value: 'accepted', label: 'Aceptada', description: 'Cotización aceptada por el cliente.', icon: 'i-mdi-thumb-up', color: 'green' },
    { value: 'rejected', label: 'Rechazada', description: 'Cotización rechazada por el cliente.', icon: 'i-mdi-thumb-down', color: 'red' },
    { value: 'canceled', label: 'Cancelada', description: 'Cotización cancelada por cualquier motivo que no sea aceptación o rechazo.', icon: 'i-mdi-close-circle-outline', color: 'red' },
  ],
  delivery: {
    incoterm: optionsDeliveries.incoterm,
  },
} satisfies Options

export const optionsQuotesMap = {
  Tipo: optionsQuotes.type,
  Estado: optionsQuotes.status,
  Entrega: optionsDeliveriesMap,
}

export const relationsQuotes: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  deal: true,
  quoteitems: true,
  salesorders: true,
  user: true,
  filePdf: true,
  files: true,
  // options: true,
}

export type QuoteWithRelations = Quote & {
  account?: Account
  branch?: Branch
  contact?: Contact
  deal?: Deal
  quoteitems?: Quoteitem[]
  salesorder?: Salesorder
  user?: User
  files: File[]
  options: Option[]
}

export const quoteToViewCalendarEvent = (calendarId: keyof Quote, utils: QuotesUtils) => {
  return (row: Quote): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.issueDate), dateFormat),
      end: format(new Date(row.expirationDate), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionQuotes = async (
  data: QuoteWithRelations,
  utils: QuotesUtils,
): Promise<TDocumentDefinitions> => {
  data.data ??= {}
  data.delivery ??= {}
  data.payment ??= {}
  data.paymentAccounts ??= []
  data.quoteitems ??= []

  const maps = {
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
  }

  const exchangeRateValue = data.payment.exchangeRate?.value ?? 1
  const exchangeRateDate = (data.payment.exchangeRate?.date ?? new Date().toISOString())?.split('T')[0]

  const amount = data.quoteitems.reduce((acc: number, quoteitem: Quoteitem) => acc + quoteitem.amount, 0) * exchangeRateValue
  const vat = data.quoteitems.reduce((acc: number, quoteitem: Quoteitem) => {
    const { transfers = [] } = quoteitem.taxes ?? {}
    return acc + transfers.reduce((acc, tax) => acc + tax.amount, 0)
  }, 0) * exchangeRateValue
  const total = amount + vat

  // sections:

  const info: TDocumentInformation = {
    title: `${data.name && data.uid && (data.name !== data.uid) ? `${data.name} (${data.uid})` : data.uid}`,
    subject: 'Cotización La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Cotización', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Fecha de emisión: ', bold: true }, new Date(data.issueDate).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Fecha de vencimiento: ', bold: true }, new Date(data.expirationDate).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Responsable: ', bold: true }, { text: `${data.user?.name}` }], alignment: 'right' },
          { text: [{ text: 'Lugar de expedición: ', bold: true }, `${data.issuer?.address.postalCode} ${data.issuer?.address.state?.toUpperCase()}`], alignment: 'right' },
          ...(data.contact
            ? [
                { text: [{ text: 'Contacto: ', bold: true }, { text: `${data.contact?.name ?? 'Por definir'}` }], alignment: 'right' as const },
                { text: [{ text: 'Teléfono: ', bold: true }, { text: `${toPhoneString(data.contact?.phone) ?? 'Por definir'}` }], alignment: 'right' as const },
              ]
            : []),
        ],
        alignment: 'right',
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionMessage: Content[] = data.message.enabled
    ? [
        data.message.subject && { text: data.message.subject, bold: true, fontSize: 10, margin: [0, 10, 0, 5] },
        data.message.body && { text: data.message.body, fontSize: 9, margin: [0, 0, 0, 5], color: '#555555', lineHeight: 1.2 },
      ]
        .filter(Boolean) as Content[]// Filters out falsy values, like undefined
    : []

  const showCurrency = data.payment.currency !== defaultAppVariables.currency

  const sectionClientInfo: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Emisor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.issuer?.legalName || '', margin: [0, 0, 0, 2] },
          { text: data.issuer?.taxId || '', margin: [0, 0, 0, 2] },
          { text: toAddressString(data.issuer?.address) || '', margin: [0, 0, 0, 2] },
          {
            text: [
              { text: 'Regimen fiscal: ', bold: true },
              utils.maps.billing?.satFiscalRegime.get(data.issuer?.satFiscalRegime)?.label || '',
            ].filter(item => item !== undefined) as Content[], // Filter out undefined and cast to Content[]
          },
        ],
        width: showCurrency ? '25%' : '33%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Receptor', style: 'header', margin: [0, 0, 0, 5] },
          { text: data.receiver?.legalName || 'Por definir', margin: [0, 0, 0, 2] },
          { text: data.receiver?.taxId || 'Por definir', margin: [0, 0, 0, 2] },
          { text: toAddressString(data.receiver?.address) || '', margin: [0, 0, 0, 2] },
          { text: [{ text: 'Regimen fiscal: ', bold: true }, utils.maps.billing?.satFiscalRegime.get(data.receiver?.satFiscalRegime)?.label || 'Por definir'] },
        ],
        width: showCurrency ? '25%' : '33%',
        margin: [2, 10],
      },
      {
        stack: [
          { text: 'Entrega', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Incoterm: ', bold: true }, utils.maps.delivery.incoterm.get(data.delivery.incoterm)?.label || ''], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Tiempo: ', bold: true }, utils.maps.delivery.schedule.get(data.delivery.schedule)?.label || ''], margin: [0, 0, 0, 2] },
          ...(['scheduled'].includes(data.delivery.schedule)
            ? [
                { text: [{ text: 'Fecha: ', bold: true }, format(new Date(data.delivery?.date), 'yyyy-MM-dd')], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : []),
          ...(!['pending', 'EXW'].includes(data.delivery.incoterm)
            ? [
                (data.branchId && { text: [{ text: 'Sucursal: ', bold: true }, data.branch?.name || ''], margin: [0, 0, 0, 2] }),
                { text: [{ text: 'Dirección: ', bold: true }, toAddressString(data.delivery.address) || ''], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : []),
        ] as Content[], // Ensuring the stack is explicitly typed as Content[]
        width: showCurrency ? '25%' : '33%',
        margin: [2, 10],
      },
      {
        stack: showCurrency
          ? [
              { text: 'Moneda', style: 'header', margin: [0, 0, 0, 5] as [number, number, number, number] },
              { text: [{ text: 'Precios base: ', bold: true }, utils.maps.payment.currency.get(defaultAppVariables.currency)?.label || ''] },
              { text: [{ text: 'Moneda: ', bold: true }, utils.maps.payment.currency.get(data.payment.currency)?.label || ''] },
              { text: [{ text: 'Tipo de cambio (referencia): ', bold: true }, toCurrency(exchangeRateValue, data.payment.currency) || ''] },
              { text: [{ text: 'Fecha referencia: ', bold: true }, exchangeRateDate || ''] },
            ]
          : [], // Default to an empty array when not showing currency
        width: '25%',
        margin: [0, 10] as [number, number],
      },
    ],
  }

  type Tax = Quoteitem['taxes']['transfers'][number] | Quoteitem['taxes']['retentions'][number]

  const getQuoteitemTaxes = (tax: Tax) => {
    if (!tax.enabled) return null
    const taxType = optionsSAT.taxTypes.find(option => option.value === tax.tax)?.label
    const satFactorType = optionsSAT.taxFactor.find(option => option.value === tax.satFactorType)?.label
    const base = toCurrency(Number(tax.base) * Number(exchangeRateValue), data.payment.currency)
    const satRateOrQuota = tax.satRateOrQuota
    const amount = toCurrency(Number(tax.amount) * Number(exchangeRateValue), data.payment.currency)
    return `${taxType}, Base: ${base}, ${satFactorType}: ${satRateOrQuota}, Importe: ${amount}`
  }

  const sectionQuoteitemsTax: (quoteitem: Quoteitem) => Content[] = (quoteitem) => {
    const { taxes = {} } = quoteitem

    const transfers = taxes.transfers?.map(item => getQuoteitemTaxes(item)).filter(Boolean)
    const retentions = taxes.retentions?.map(item => getQuoteitemTaxes(item)).filter(Boolean)

    const result: Content[] = []

    // Add transfer section if available
    if (transfers?.length) {
      result.push({
        text: `Traslados:`,
        bold: true,
        style: 'tableRowDetails',
      })

      // Add each transfer tax item
      transfers.forEach((tax) => {
        result.push({
          text: tax,
          style: 'tableRowDetails',
        })
      })
    }

    // Add retentions if available
    if (retentions?.length) {
      result.push({
        text: `Retenciones:`,
        bold: true,
        style: 'tableRowDetails',
      })

      // Add each transfer tax item
      retentions.forEach((tax) => {
        result.push({
          text: tax,
          style: 'tableRowDetails',
        })
      })
    }

    return result
  }

  const hasDiscounts = data.quoteitems.some(quoteitem => quoteitem.discount?.amount)
  const hasSKUs = data.quoteitems.some(quoteitem => quoteitem.sku)

  const sectionQuoteitems: Content = {
    table: {
      dontBreakRows: true,
      headerRows: 1,
      widths: data.pdfSettings?.images
        ? (hasDiscounts
            ? hasSKUs
              ? ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
              : ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
            : hasSKUs
              ? ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
              : ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
          )
        : (hasDiscounts
            ? hasSKUs
              ? ['auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto']
              : ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto']
            : hasSKUs
              ? ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto']
              : ['auto', 'auto', '*', 'auto', 'auto', 'auto']
          ),
      body: [
        [
          { text: '#', style: 'tableHeader' },
          ...hasSKUs ? [{ text: 'SKU', style: 'tableHeader' }] : [],
          { text: 'Cantidad', style: 'tableHeader' },
          ...data.pdfSettings?.images ? [{ text: 'Imagen', style: 'tableHeader' }] : [],
          { text: 'Item(s)', style: 'tableHeader' },
          { text: 'Unidad', style: 'tableHeader' },
          { text: 'Precio U', style: 'tableHeader' },
          ...(hasDiscounts ? [{ text: 'Descuento', style: 'tableHeader' }] : []),
          { text: 'Subtotal', style: 'tableHeader' },
        ],
        ...(await Promise.all(data.quoteitems.map(async (quoteitem: Quoteitem, index: number) => [
          { text: index + 1, style: 'tableRow' },
          ...(hasSKUs ? [{ text: quoteitem.sku, style: 'tableRow' }] : []),
          { text: quoteitem.quantity?.toString() ?? '', alignment: 'center', style: 'tableRow' },
          ...(data.pdfSettings?.images ? [quoteitem.image ? { image: await getBase64ImageFromURL(quoteitem.image), width: 40, height: 40, margin: [5, 5, 5, 5] } : { text: '' }] : []),
          {
            stack: [
              { text: quoteitem.name, fontSize: 8, style: 'tableRow' },
              quoteitem.description && { text: quoteitem.description, style: 'tableRowDetails' },
              ...data.pdfSettings?.details
                ? [
                    { text: utils.maps.billing?.satTaxObject.get(quoteitem.fiscalData.satTaxObject!)?.label ?? null, bold: true, style: 'tableRowDetails' },
                    ...sectionQuoteitemsTax(quoteitem),
                  ]
                : [],
            ].filter(Boolean),
          },
          { text: maps.fiscalData.satUnitKey.get(quoteitem.fiscalData.satUnitKey!)?.label || '', style: 'tableRow' },
          { text: toCurrency(Number(quoteitem.unitPrice) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' },
          ...(hasDiscounts ? [{ text: toCurrency(Number(quoteitem.discount?.amount ?? 0) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' }] : []),
          { text: toCurrency(Number(quoteitem.amount) * Number(exchangeRateValue), data.payment.currency), alignment: 'right', style: 'tableRow' },
        ]))),
      ].filter(Boolean),
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0 : 0.1
      },
      vLineWidth: function (i) { return 0 },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? 'white' : 'gray'
      },
      vLineColor: function (i) { return 'white' },
    },
  }

  const toWords = toSpelledCurrency(data.payment.currency)

  const sectionTotalizedContent: Content[] = data.type === 'totalized'
    ? [
        {
          unbreakable: true,
          columns: [
            {
              margin: [0, 5],
              alignment: 'right',
              table: {
                dontBreakRows: true,
                widths: ['*', 'auto', 'auto'],
                body: [
                  [{ text: '' }, { text: 'Subtotal:', alignment: 'left' }, { text: `${toCurrency(amount, data.payment.currency)}`, alignment: 'right' }],
                  [{ text: '' }, { text: 'IVA:', alignment: 'left' }, { text: `${toCurrency(vat, data.payment.currency)}`, alignment: 'right' }],
                  [{ text: `${toWords.convert(total)?.toUpperCase()}`, alignment: 'right' }, { text: 'Total:', bold: true, alignment: 'left' }, { text: `${toCurrency(total, data.payment.currency)}`, bold: true, alignment: 'right' }],
                ],
              },
              layout: 'noBorders',
            },
          ],
        },
      ]
    : []

  const sectionPaymentInformation: Content = {
    unbreakable: true,
    columns: [
      {
        stack: [
          { text: 'Moneda', style: 'header' },
          { text: `${utils.maps.payment.currency.get(data.payment.currency)?.label}` },
        ],
        width: 100,
      },
      {
        stack: [
          { text: 'Forma de pago', style: 'header' },
          { text: `${utils.maps.payment.form.get(data.payment.satPaymentForm)?.label}` },
        ],
        width: 100,
      },
      {
        stack: [
          { text: 'Método de pago', style: 'header' },
          { text: `${utils.maps.payment.method.get(data.payment.satPaymentMethod)?.label}` },
        ],
      },
      {
        stack: [
          { text: 'Condiciones de pago', style: 'header', margin: [0, 0, 0, 5], alignment: 'right' },
          { text: `${utils.maps.payment.condition.get(data.payment.conditions)?.label}`, alignment: 'right' },
        ],
      },
    ],
    margin: [0, 10],
  }

  const sectionNotes: Content[] = data.notes.length
    ? [
        { text: 'Observaciones', style: 'header', margin: [0, 10, 0, 5] },
        { ul: data.notes.map(note => ({ text: note, color: '#595959', margin: [0, 0, 0, 2], alignment: 'justify' })) },
      ]
    : []

  const sectionPaymentAccounts: Content[] = data.paymentAccounts.length
    ? [
        {
          unbreakable: true, // Ensures the entire section stays together (title + table)
          stack: [
            { text: 'Cuentas bancarias', style: 'header', margin: [0, 10, 0, 5] },
            { text: 'Le solicitamos amablemente incluir el número de referencia, ubicado en la parte superior de este documento, al realizar el pago.', color: '#595959', margin: [0, 0, 0, 5] },
            {
              table: {
                dontBreakRows: true, // Prevents rows from breaking across pages
                headerRows: 1, // Ensures header stays at the top if the table breaks
                widths: ['auto', '*', '*', '*', '*'],
                body: [
                  [
                    { text: '#', style: 'tableHeader' },
                    { text: 'Banco', style: 'tableHeader' },
                    { text: 'Moneda', style: 'tableHeader' },
                    { text: 'No. Cuenta', style: 'tableHeader' },
                    { text: 'CLABE', style: 'tableHeader' },
                  ],
                  ...data.paymentAccounts.map((account, index) => [
                    { text: index + 1, style: 'tableRow' },
                    { text: account.name, style: 'tableRow' },
                    { text: utils.maps.payment.currency.get(account.currency)?.label, style: 'tableRow' },
                    { text: account.accountNumber, style: 'tableRow' },
                    { text: account.clabe, style: 'tableRow' },
                  ]),
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 0 : 0.1
                },
                vLineWidth: function (i) { return 0 },
                hLineColor: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 'white' : 'gray'
                },
                vLineColor: function (i) { return 'white' },
              },
              margin: [0, 10],
            },
          ],
        },
      ]
    : []

  const sectionTermsAndConditions: Content[] = termsAndConditions.length
    ? [
        { text: 'Términos y Condiciones Generales', style: 'header', color: '#595959', margin: [0, 10, 0, 5] },
        ...termsAndConditions.map((item): ContentText => ({ text: item, color: '#595959', margin: [0, 0, 0, 2], alignment: 'justify' })),
      ]
    : []

  return {
    language: 'es-MX',
    compress: true,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          color: 'gray',
          opacity: 0.1,
          fontSize: 24,
          angle: 0,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      ...sectionMessage,
      sectionClientInfo,
      sectionQuoteitems,
      ...sectionTotalizedContent,
      sectionPaymentInformation,
      ...sectionNotes,
      ...sectionPaymentAccounts,
      ...sectionTermsAndConditions,
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            { text: [
              { text: 'Generado con ' },
              { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              { text: ' ' },
              { text: `el ${new Date().toLocaleDateString()}` }],
            }],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
      tableRowDetails: { fontSize: 6, margin: [5, 1, 5, 1] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: data.pdfSettings?.pageSize || 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsQuotes: ViewSettings = {
  tableName: 'quotes',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'deal.name',
      label: 'Negocio',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'issueDate',
      label: 'Emisión',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'expirationDate',
      label: 'Expiración',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'account.name',
      label: 'Cliente',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contact.name',
      label: 'Contacto de Cotización',
      sortable: true,
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'delivery.incoterm',
      label: 'Incoterm',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'delivery.schedule',
      label: 'Tiempo de entrega',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branch.name',
      label: 'Sucursal de entrega',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'delivery.address',
      label: 'Dirección de entrega',
      class: 'min-w-[200px]', // for <th>
    },
    {
      key: 'delivery.text',
      label: 'Detalles de entrega',
      class: 'min-w-[300px]',
    },
    {
      key: 'payment.satPaymentMethod',
      label: 'Método de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.conditions',
      label: 'Condiciones de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.exchangeRate.value',
      label: 'Tipo cambio',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.amount',
      label: 'Subtotal',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.total',
      label: 'Total',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsQuotes.type },
    { label: 'Estado', value: 'status', options: optionsQuotes.status },
  ],
}
