<script setup lang="ts">
import { useQRCode } from '@vueuse/integrations/useQRCode'

const model = defineModel<boolean>()

const { copy } = useClipboard()

const props = defineProps<{
  title?: string
  description?: string
  data: File
}>()

const { share } = useShare()

const fileUrl = computed(() => new URL(`/api/blob/${props.data.pathname}`, window.location.origin).href)

const qrcode = useQRCode(fileUrl)

const onClickCopy = () => {
  copy(fileUrl.value.href)
  useToastAlert().success('URL copiada al portapapeles')
}

const onClickShare = () => {
  share({
    title: 'Compartir registro',
    text: 'Compartir registro',
    url: fileUrl.value.href,
  })
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title="title || 'Compartir archivo'"
    :description="description ?? 'Comparte este archivo utilizando el siguiente URL.'"
  >
    <div class="grid place-items-center">
      <img
        :src="qrcode"
        alt="QR Code"
        class="h-64 w-64"
      >
    </div>

    <template #footer>
      <UButton
        variant="outline"
        color="gray"
        label="Copiar URL"
        class="w-1/2 justify-center"
        @click="onClickCopy"
      />
      <UButton
        variant="outline"
        label="Compartir"
        class="w-1/2 justify-center"
        @click="onClickShare"
      />
    </template>
  </UDashboardModal>
</template>
