<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  note: Partial<Note> & Pick<Note, 'tableName' | 'recordId'>
  refresh: () => Promise<void>
}>()

const { user } = useUserSession()

// notes:

const state = ref({ ...props.note, userId: user.value!.id })

const { onSubmitCreate, schema } = useNotes()

const loading = ref(false)

const onSubmitNote = async (event: FormSubmitEvent<Partial<Note>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await onSubmitCreate(event.data)
  await props.refresh()
  loading.value = false
  state.value = { ...props.note, userId: user.value!.id }
}
</script>

<template>
  <UForm
    id="form.notes"
    :validate-on="['submit']"
    :schema="schema"
    :state="state"
    @error="onFormError"
    @submit="onSubmitNote"
  >
    <UFormGroup name="text">
      <UTextarea
        v-model="state.text"
        :rows="3"
        resize
        placeholder="Escribe una nota..."
      />
      <UButton
        form="form.notes"
        block
        type="submit"
        :loading="loading"
        :disabled="!state.text"
        class="my-3"
      >
        Agregar nota
      </UButton>
    </UFormGroup>
  </UForm>
</template>
