import type { Payment, PdfSettings } from '#imports'
import { format } from 'date-fns'

export const defaultAppVariables = {
  currency: 'USD', // used for price lists
}

export const defaultPayment: Payment = {
  satCfdiUse: 'G03',
  satPaymentMethod: 'PUE',
  satPaymentForm: '03',
  conditions: 'NET0',
  currency: 'USD',
  exchangeRate: {
    value: 1,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  },
}

export const defaultLegalInformation = {
  taxId: 'EGR890713BK5',
  taxCountryCode: 'MX',
  legalName: 'ESPECIALIDADES DE GRAFITO',
  satFiscalRegime: '626',
  phone: '8183810381',
  email: 'ventas@esgraf.com.mx',
  address: {
    street: 'TRIANGULOS',
    numberExterior: '140',
    neighborhood: 'ARCO VIAL',
    city: 'GARCÍA',
    state: 'NUEVO LEON',
    country: 'Mexico',
    postalCode: '66023',
  },
}

export const defaultPdfSettings: PdfSettings = {
  watermark: {
    enabled: false,
    text: 'ESGRAF',
    bold: true,
    italic: false,
  },
  images: false,
  details: true,
  pageSize: 'LETTER',
}
