<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const model = defineModel<string | number | boolean | object | any[] | undefined | null>({ required: true })

const props = defineProps<{
  disabled?: boolean
  multiple?: boolean
  clearable?: boolean
  options?: Record<string, unknown>[]
}>()

const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const search = async (q: string) => {
  const filtered = props.options.filter((option) => {
    const cleanedLabel = option.label ? removeAccents(option.label.toLowerCase()) : ''
    const cleanedQuery = removeAccents(q.toLowerCase())

    return option.value?.toString().includes(q)
      || cleanedLabel.includes(cleanedQuery)
      || option.uid?.toString().includes(q)
  })

  return filtered
}
</script>

<template>
  <USelectMenu
    v-model="model"
    :options="options"
    :searchable="search"
    searchable-placeholder="Filtrar..."
    :disabled="disabled"
    :multiple="multiple"
    option-attribute="label"
    value-attribute="value"
    class="min-w-0"
    :ui-menu="{
      ...$attrs?.uiMenu ?? {},
      width: `min-w-max ${$attrs?.uiMenu?.width}`,
      label: 'text-clip',
    }"
    v-bind="$attrs"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template #label="slotProps">
      <slot name="label" v-bind="slotProps">
        <template v-if="Array.isArray(model) ? model.length : model">
          <SelectMenuSlotLabel
            v-model="model"
            v-bind="{ slotProps, disabled, multiple, clearable }"
          />
        </template>
      </slot>
    </template>
  </USelectMenu>
</template>
