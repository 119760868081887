import { AppTasksSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppTasksSlideoverProps = ComponentProps<typeof AppTasksSlideover>

export type TasksUtils = ReturnType<typeof useTasksUtils>

export const useTasks = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Task>('tasks')

  const slideoverOpenCreate = (props: AppTasksSlideoverProps) => {
    return new Promise<Task>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultTask({userId: user.value.id, ...props.data})
      slideover.open(AppTasksSlideover, {
        title: 'Crear tarea',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppTasksSlideoverProps) => {
    return new Promise<Task>((resolve, reject) => {
      const data = getDefaultTask(props.data)
      slideover.open(AppTasksSlideover, {
        title: 'Editar tarea',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Task, refresh: () => Promise<void>, utils: TasksUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'tasks', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'tasks', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'tasks', row, refresh, utils),
      getDropdownItem('files', 'tasks', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'tasks', row, refresh, utils),
      getDropdownItem('immutable', 'tasks', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'tasks', row, refresh, utils),
      getDropdownItem('delete', 'tasks', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useTasksUtils = () => {
  const options = {
    ...optionsTasks,
  }

  const maps = {
    status: toMapByKey(optionsTasks.status, 'value'),
    priority: toMapByKey(optionsTasks.priority, 'value'),
  }

  return { options, maps }
}
