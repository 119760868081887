import validate from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/mrk/Documents/netzo/middleware/auth.global.ts";
import manifest_45route_45rule from "/Users/mrk/Documents/esgraf-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-otp": () => import("/Users/mrk/Documents/netzo/middleware/auth-otp.ts"),
  "auth-register": () => import("/Users/mrk/Documents/netzo/middleware/auth-register.ts"),
  "auth-reset-password": () => import("/Users/mrk/Documents/netzo/middleware/auth-reset-password.ts")
}