<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<File>
  onSubmit: (event: FormSubmitEvent<Partial<File>>) => void
  openNestedCallback: (data: Partial<File>) => void
  title?: string
  disabledFields?: (keyof File | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<File>>(props.data)

const files$ = useFiles()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'domain', 'userId'] },
  default: () => [],
})

const utils = useFilesUtils({ $accounts })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.files,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const onSubmitFile = async (event: FormSubmitEvent<Partial<File>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.files"
      :validate-on="['submit']"
      :schema="files$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitFile"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(files$.schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(files$.schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo de Contenido"
            name="contentType"
            :required="isRequired(files$.schema, 'contentType')"
          >
            <InputReadOnly :model-value="state.contentType" />
          </UFormGroup>

          <UFormGroup
            label="Tamaño"
            name="size"
            :required="isRequired(files$.schema, 'size')"
          >
            <InputReadOnly :model-value="toFileSize(state.size)" />
          </UFormGroup>

          <UFormGroup
            label="Enlace al archivo"
            name="pathname"
          >
            <InputReadOnly :model-value="`/api/db/${state.pathname}`" />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Visibilidad"
            name="public"
            :required="isRequired(files$.schema, 'public')"
          >
            <div class="flex items-center gap-2">
              <UToggle
                v-model="state.public"
                on-icon="i-mdi-web"
                off-icon="i-mdi-lock"
                :disabled="disabledFields?.includes('public')"
              />
              {{ state.public ? 'El archivo es público, cualquiera con el enlace puede verlo.' : 'El archivo es privado, solo los usuarios autorizados pueden verlo.' }}
            </div>
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.files"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
