export function joinValues(array, separator = " | ") {
  return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
}
export function jsonStringifyReplacer(_, value) {
  if (typeof value === "bigint") {
    return value.toString();
  }
  return value;
}
export function isRecord(value) {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  for (const key in value) {
    if (!Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}
export function getKeyAndValues(param, defaultKey, i18n) {
  const { t } = i18n;
  if (typeof param === "string") {
    return { key: param, values: {} };
  }
  if (isRecord(param)) {
    const key = "key" in param && typeof param.key === "string" ? param.key : defaultKey;
    const values = "values" in param && isRecord(param.values) ? Object.entries(param.values).reduce((acc, [key2, value]) => {
      acc = { ...acc, [key2]: t(value) };
      return acc;
    }, {}) : {};
    return { key, values };
  }
  return { key: defaultKey, values: {} };
}
