<script setup lang="ts">
// from https://github.com/ra-jeev/hub-chat?tab=readme-ov-file
// see https://rajeev.dev/create-cloudflare-workers-ai-llm-playground-using-nuxthub-and-nuxtui
import { parseMarkdown } from '#imports'

const props = defineProps<{
  value: string | undefined | null
}>()

const id = useId()

const { data: ast, refresh } = await useAsyncData(id!, () => parseMarkdown(props.value!), {
  immediate: !!props.value,
})

watch(() => props.value, () => refresh())
</script>

<template>
  <MDCRenderer
    v-if="value && ast"
    class="contents flex-1 prose dark:prose-invert whitespace-pre-line p-1 text-sm"
    :body="ast?.body"
  />
</template>
