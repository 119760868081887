import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

// TODO: Fix edit tax salesorderitems must also be considered
export const getDefaultSalesorderitem = (data: Partial<Salesorderitem>): Salesorderitem => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'products',
    subtype: 'finished-goods',
    discount: { type: 'percent' },
    fiscalData: {
      satTaxObject: '02',
    },
    taxes: {
      transfers: [
        {
          enabled: true,
          tax: '002',
          satFactorType: 'rate',
          satRateOrQuota: 0.16000,
          base: 0,
          amount: 0,
        },
        {
          enabled: false,
          tax: '003',
          satFactorType: 'rate',
          satRateOrQuota: 0.08000,
          base: 0,
          amount: 0,
        },
      ],
      retentions: [
        {
          enabled: false,
          tax: '002',
          satFactorType: 'rate',
          satRateOrQuota: 0.10000,
          base: 0,
          amount: 0,
        },
        {
          enabled: false,
          tax: '001',
          satFactorType: 'rate',
          satRateOrQuota: 0.10000,
          base: 0,
          amount: 0,
        },
      ],
    },
    tags: [],
    data: getDefaultSalesorderitemData(data?.data ?? {}),
  }, data)
}

export const sumSalesorderitems = (salesorder: SalesorderWithRelations) => {
  const amount = sumByKey(salesorder.salesorderitems as Salesorderitem[], item => item.amount)
  const transfers = sumByKey(salesorder.salesorderitems as Salesorderitem[], item =>
    sumByKey(item.taxes.transfers, transfer => transfer.amount),
  )
  const retentions = sumByKey(salesorder.salesorderitems as Salesorderitem[], item =>
    sumByKey(item.taxes.retentions, retention => retention.amount),
  )
  const total = amount + transfers + retentions
  return {
    amount,
    transfers,
    retentions,
    total,
  }
}

export const optionsSalesorderitems = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Los productos son bienes, tangibles (p.ej. computadoras), que forman parte de las actividades principales de una empresa.' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'purple', description: 'Los servicios son bienes, intangibles (p.ej. consultoría, envío de paquetes).' },
  ],
  subtype: {
    products: optionsProducts.type,
    services: optionsServices.type,
  },
  discount: [
    { value: 'percent', label: '%' },
    { value: 'number', label: '$' },
  ],
} satisfies Options

export const optionsSalesorderitemsMap = {
  Tipo: optionsSalesorderitems.type,
  Subtipo: {
    Productos: optionsSalesorderitems.subtype.products,
    Servicios: optionsSalesorderitems.subtype.services,
  },
}

export const relationsSalesorderitems: Record<string, boolean> = {
  productitem: true,
  quoteitem: true,
  salesorder: true,
  serviceitem: true,
  files: true,
  // options: true,
}

export type SalesorderitemWithRelations = Salesorderitem & {
  productitem: Productitem
  salesorder: Salesorder
  serviceitem: Serviceitem
  files: File[]
  options: Option[]
}

export const viewSettingsSalesorderitems: ViewSettings = {
  tableName: 'salesorderitems',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    // {
    //   key: 'fiscalData.satProductServiceKey',
    //   label: 'Clave (SAT)',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantity',
      label: 'Cantidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'unitPrice',
      label: 'Precio unitario',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'discount.amount',
      label: 'Descuento',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'amount',
      label: 'Importe',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'total',
      label: 'Total',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
