<script setup lang="ts">
import type { BlobPutOptions } from '@nuxthub/core'
import { vElementHover } from '@vueuse/components'

const model = defineModel<string>({ default: '' })

const props = defineProps<{
  tableName: TableName
  recordId: string
  type?: string
  options?: BlobPutOptions
  data?: Partial<File>
}>()

// NOTE: only accept PDFMake-supported types PNG/JPEG
// to avoid issues later on when generating PDFs
const ACCEPT = 'image/png,image/jpeg'
const { tableName, recordId, type = 'image' } = props

const { createRenamedFile, uploadFile, removeFile } = useApiBlob(tableName, recordId)

const id = useId()

const rerenderKey = ref(Math.random())

const loading = ref(false)

const onChange = async (fileList: FileList) => {
  loading.value = true
  const file = fileList[0]!
  if (!file) return

  // DISABLED: do not enforce file maxSize limit
  // if (file.size > 1e6) return alert('Imagen excede el tamaño máximo permitido de 1MB')

  if (model.value) await removeFile({ pathname: model.value.replace('/api/blob/', '') }) // remove existing (previous model value)
  const blobObject = await uploadFile(type, file, props.options, props.data)
  model.value = `/api/blob/${blobObject.pathname}` // manually set new model value
  rerenderKey.value = Math.random() // force re-render to update avatar
  loading.value = false
}

const isHovered = ref(false)

const onClickRemove = async () => {
  if (model.value) {
    await removeFile({ pathname: model.value.replace('/api/blob/', '') })
    model.value = null
    rerenderKey.value = Math.random() // force re-render to update avatar
    document.getElementById(id).value = null
  }
}

const avatarProps = computed(() => {
  if (model.value && isHovered.value) return { icon: 'i-mdi-image-remove', class: 'cursor-pointer' }
  else if (model.value && !isHovered.value) return { src: model.value }
  else return { icon: 'i-mdi-image' }
})
</script>

<template>
  <div class="flex items-center gap-3">
    <div class="flex-1 w-full relative flex items-center gap-2">
      <UInput
        :id="id"
        v-bind="$attrs"
        type="file"
        name="file"
        :accept="ACCEPT"
        icon="i-mdi-image"
        class="flex-1 w-full"
        @change="onChange"
      />
    </div>

    <div class="-my-2" @click="onClickRemove">
      <UAvatar
        :key="rerenderKey"
        v-element-hover="(state: boolean) => isHovered = state"
        v-bind="loading ? { icon: 'i-mdi-loading', class: 'animate-spin' } : avatarProps"
        size="md"
      />
    </div>
  </div>
</template>
