export default defineAppConfig({
  ui: {
    primary: 'blue',
    gray: 'neutral',
    variables: {
      light: {
        background: '255 255 255',
        backgroundElevated: '250 250 250',
      },
      dark: {
        background: '20 20 20',
        backgroundElevated: '10 10 10',
      },
      header: {
        height: '49px',
      },
    },
    icons: {
      // @nuxt/ui (https://github.com/nuxt/ui/blob/v3/src/theme/icons.ts):
      arrowLeft: 'i-mdi-arrow-left',
      arrowRight: 'i-mdi-arrow-right',
      check: 'i-mdi-check',
      chevronDoubleLeft: 'i-mdi-chevron-double-left',
      chevronDoubleRight: 'i-mdi-chevron-double-right',
      chevronDown: 'i-mdi-chevron-down',
      chevronLeft: 'i-mdi-chevron-left',
      chevronRight: 'i-mdi-chevron-right',
      chevronUp: 'i-mdi-chevron-up',
      close: 'i-mdi-close',
      ellipsis: 'i-mdi-dots-horizontal',
      // external: 'i-mdi-arrow-up-right',
      loading: 'i-mdi-loading',
      minus: 'i-mdi-minus',
      plus: 'i-mdi-plus',
      search: 'i-mdi-magnify',
      // @nuxt/ui-pro (https://github.com/nuxt/ui-pro/blob/v3/src/theme/icons.ts):
      caution: 'i-mdi-alert-circle',
      copy: 'i-mdi-content-copy',
      copyCheck: 'i-mdi-checkbox-multiple-marked',
      dark: 'i-mdi-weather-night',
      error: 'i-mdi-close-circle',
      external: 'i-mdi-open-in-new',
      file: 'i-mdi-file-document',
      folder: 'i-mdi-folder',
      folderOpen: 'i-mdi-folder-open',
      hash: 'i-mdi-pound',
      info: 'i-mdi-information',
      light: 'i-mdi-white-balance-sunny',
      menu: 'i-mdi-menu',
      success: 'i-mdi-check-circle',
      system: 'i-mdi-monitor',
      tip: 'i-mdi-lightbulb',
      warning: 'i-mdi-alert',
    },
    tooltip: {
      default: {
        openDelay: 500,
        closeDelay: 0,
      },
    },
    badge: {
      default: {
        variant: 'subtle',
      },
    },
    button: {
      default: {
        loadingIcon: 'i-mdi-loading',
      },
    },
    buttonGroup: {
      wrapper: {
        // WORKAROUND: applies trick to avoid overflow within flex containers
        // see https://stackoverflow.com/a/66689926
        horizontal: 'min-w-0',
      },
    },
    input: {
      default: {
        loadingIcon: 'i-mdi-loading',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-mdi-loading',
        trailingIcon: 'i-mdi-chevron-down',
      },
    },
    selectMenu: {
      default: {
        selectedIcon: 'i-mdi-check',
      },
    },
    notification: {
      default: {
        closeButton: {
          icon: 'i-mdi-close',
        },
      },
    },
    notifications: {
      position: 'bottom-0 right-1/2 translate-x-1/2', // bottom-center
    },
    commandPalette: {
      default: {
        icon: 'i-mdi-magnify',
        loadingIcon: 'i-mdi-loading',
        selectedIcon: 'i-mdi-check',
        emptyState: {
          icon: 'i-mdi-magnify',
        },
      },
    },
    table: {
      default: {
        sortAscIcon: 'i-mdi-arrow-up',
        sortDescIcon: 'i-mdi-arrow-down',
        sortButton: {
          icon: 'i-mdi-menu-swap',
        },
        loadingState: {
          icon: 'i-mdi-loading',
        },
        emptyState: {
          icon: 'i-mdi-database',
        },
      },
      wrapper: 'h-full',
      base: 'border-b border-gray-200 dark:border-gray-800',
      divide: 'divide-gray-200 dark:divide-gray-800',
      thead: 'sticky top-0 z-10 bg-[rgb(var(--ui-background))]',
      // tbody: 'divide-y divide-gray-200 dark:divide-gray-800',
      th: { base: 'text-nowrap', padding: 'py-3', color: 'bg-[rgb(var(--ui-background))]', font: 'font-normal' },
      tr: { base: 'divide-x divide-gray-200 dark:divide-gray-800' },
      td: { font: 'text-sm', padding: 'py-1 px-2' },
    },
    pagination: {
      default: {
        firstButton: {
          icon: 'i-mdi-chevron-double-left',
        },
        prevButton: {
          icon: 'i-mdi-chevron-left',
        },
        nextButton: {
          icon: 'i-mdi-chevron-right',
        },
        lastButton: {
          icon: 'i-mdi-chevron-double-right',
        },
      },
    },
    accordion: {
      default: {
        openIcon: 'i-mdi-chevron-down',
      },
    },
    breadcrumb: {
      default: {
        divider: 'i-mdi-chevron-right',
      },
    },
    // ui-pro:
    dashboard: {
      navbar: {
        left: 'flex-1 items-center',
      },
      sidebar: {
        wrapper: 'bg-[rgb(var(--ui-backgroundElevated))]',
        links: {
          trailingIcon: {
            name: 'i-mdi-chevron-down',
          },
          active: 'before:bg-primary-100 dark:before:bg-primary-900',
        },
      },
    },
  },
})

// see https://nuxt.com/docs/guide/directory-structure/app-config#typing-app-config
declare module '@nuxt/schema' {
  interface CustomAppConfig {
    netzo: {
      locale?: 'es' | 'en' // defaults to 'es'
      favicon: '/favicon.svg' // require SVG favicon for @vite-pwa/assets-generator
      title: string
      description: string
      auth: {
        allowUserRegistration?: boolean
      }
      preferences: {
        text: 'upper' | 'lower' | 'capital' | 'snake' | 'pascal' | 'camel' | 'kebab' | 'header' | 'constant' | 'title' | 'sentence'
      }
    }
  }

  interface RuntimeConfig {
    session: {
      maxAge: number
    }
    expectedOrigin: string
    expectedRPID: string
    fromEmail: string
  }

  interface PublicRuntimeConfig {
    baseUrl: string
    locale?: 'es' | 'en'
  }
}

export type ModuleOptions = {
  enabled: boolean
  role: 'admin' | 'edit' | 'view'
}

declare module '#auth-utils' {
  // copied from netzo:users schema in <app>/server/database/schema.ts
  interface User {
    id: string
    type: string
    name: string
    email: string
    emailVerified: boolean
    phone: string
    image: string
    hashedPassword: string
    banned: boolean
    bannedReason: string
    modules: Record<string, ModuleOptions>
    activeAt: string
    accountId: string
    createdAt: string
    updatedAt: string
    archivedAt: string
    createdBy: string
    updatedBy: string
    archivedBy: string
  }

  // interface UserSession { }

  // interface SecureSessionData { }
}
