<script setup lang="ts">
import type { Phone } from '#netzo/shared/types/app'
import { vMaska } from 'maska/vue'

const model = defineModel<Phone>({ default: () => ({ code: '', number: '', ext: '' }) })

const props = defineProps<{
  label?: string
  disabled?: boolean
  countryCode?: string
}>()

const selectedCountry = ref(props.countryCode ?? 'MX')
const selectedOption = computed(() => optionsShared.countryCode.find(o => o.value === selectedCountry.value))
</script>

<template>
  <div v-if="model" class="flex gap-2 items-center">
    <SelectMenuBase
      v-model="selectedCountry"
      :options="optionsShared.countryCode"
      :disabled="disabled"
      value-attribute="value"
      option-attribute="value"
      @update:model-value="model.code = selectedOption?.code"
    >
      <template #option="{ option }">
        <div class="flex items-center">
          <UIcon :name="option.icon" class="w-4 h-4" />
          <span class="mx-3">{{ option.label }}</span>
          <span class="text-gray-500 dark:text-gray-400">
            +{{ option.code }}
          </span>
        </div>
      </template>
    </SelectMenuBase>
    <InputText
      v-model.trim="model.number"
      v-maska="selectedOption?.maskPhone"
      type="tel"
      label="Teléfono"
      :disabled="disabled"
      class="flex-1"
    >
      <!-- <template #leading>
        {{ selectedOption?.code }}
      </template> -->
    </InputText>
    <InputText
      v-model.trim="model.ext"
      type="tel"
      label="Teléfono"
      :disabled="disabled"
      placeholder="ext."
      class="flex-0 w-20"
    />
  </div>
</template>
