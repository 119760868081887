<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Quote>
  onSubmit: (event: FormSubmitEvent<Partial<Quote>>) => void
  title?: string
}>()

// tabs:

const selectedTab = ref(0)

const tabs = [
  { label: 'Productos', icon: ICONS.productitems },
  { label: 'Servicios', icon: ICONS.salesorderitems },
]

const tableName = computed(() => selectedTab.value === 0 ? 'productitems' : 'serviceitems')

const utils = computed(() => useTableUtils(tableName.value))

const query = ref<ViewQuery>({
  userId: [],
  archivedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: view.value.pagination,
  $columns: ['id', 'name', 'image', 'type', 'subtype', 'sku', 'fiscalData.satUnitKey', 'quantity', 'unitPrice', 'discount.amount'],
})
const url = computed(() => `/api/db/${tableName.value}`)
const { data, error, status, refresh } = await useFetch<(Productitem | Serviceitem)[]>(url, {
  query,
  default: () => [],
})

const columns = computed(() => [
  {
    key: 'image',
    label: 'Imágen',
    class: 'w-[150px]',
  },
  {
    key: 'name',
    label: 'Nombre',
    sortable: true,
    class: 'max-w-[300px]',
  },
  {
    key: 'type',
    label: 'Tipo',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'subtype',
    label: 'Subtipo',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'unitPrice',
    label: 'Precio unitario',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
  {
    key: 'amount',
    label: 'Importe',
    sortable: true,
    class: 'text-center', // for <th>
    rowClass: 'text-center', // for <td>
  },
])
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ width: 'min-w-[50vw]', body: { base: 'px-0' } }"
  >
    <UTabs
      v-model="selectedTab"
      :items="tabs"
      :columns="columns"
      class="px-4"
    />

    <!-- <ViewToolbar
      v-model:query="query"
      v-model:is-filtering="isFiltering"
      v-model:selected="selected"
      v-bind="{ view, onUpdateView, refresh, clearFilters, total, rows }"
    >
      <template #filters>
        <ViewFilters v-model:query="query" v-bind="{ data, clearFilters, tableName: 'productitems' }" />
      </template>
    </ViewToolbar> -->

    <div class="flex flex-col h-full gap-3">
      <ViewPlaceholder
        :loading="['pending'].includes(status)"
        :data="data"
        :icon="ICONS[tableName]"
        label="No hay notas para mostrar."
      >
        <!-- <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3"> -->
        <UTable
          v-if="data.length"
          v-bind="{ data, error, status, refresh, utils, columns, rows: data }"
          :loading="['pending'].includes(status)"
          sort-mode="manual"
        >
          <template #loading-state>
            <ViewPlaceholder loading />
          </template>

          <template v-if="$slots.expand" #expand="expandProps">
            <slot v-bind="expandProps" name="expand" />
          </template>

          <template #image-data="{ row }">
            <ViewRendererImage
              v-if="row.image"
              :image="row.image"
              compact
            />
          </template>

          <template #name-data="{ row }">
            <ViewRendererReference
              :row="row"
              hide-avatar
              class="pl-2"
            />
          </template>

          <template #type-data="{ row }">
            <ViewRendererOptionBadge :option="utils.maps?.type?.get(row.type)" />
          </template>
        </UTable>

        <ViewPlaceholder v-else />
        <!-- </div> -->
      </ViewPlaceholder>
    </div>
  </UDashboardSlideover>
</template>
