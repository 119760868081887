<script setup lang="ts">
const model = defineModel<string | string[] | undefined | null>({ required: true, default: () => [] })

const props = defineProps<{
  options: Option[]
  disabled?: boolean
}>()

const q = ref('')

const filteredRows = computed(() => {
  if (!q.value) {
    return props.options
  }

  return props.options.filter((option) => {
    return Object.values(option).some((value) => {
      return String(value).toLowerCase().includes(q.value.toLowerCase())
    })
  })
})
</script>

<template>
  <div class="flex gap-1">
    <SelectMenuBase
      v-model="model"
      :options
      :disabled
      class="w-full"
      v-bind="$attrs"
    />
    <UPopover
      :ui="{ width: 'max-w-[800px]' }"
      :popper="{ placement: 'auto-end' }"
    >
      <UButton
        icon="i-mdi-book-open-variant"
        title="Ver documentación de opciones"
        variant="outline"
        square
        :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
      />
      <template #panel>
        <div
          class="flex px-3 py-3.5 gap-1 border-b border-gray-200 dark:border-gray-700"
        >
          <UInput
            v-model="q"
            placeholder="Buscar..."
            size="xs"
          />
          <UButton
            v-if="q"
            square
            variant="outline"
            color="red"
            title="Reiniciar filtros"
            icon="i-mdi-filter-variant-remove"
            size="xs"
            :disabled="!q"
            @click="q = ''"
          />
        </div>

        <div class="max-h-[500px] max-w-[400px] overflow-y-auto p-4">
          <UTable
            :rows="filteredRows"
            :columns="[
              { key: 'label', label: 'Opción' },
              { key: 'description', label: 'Descripción' },
            ]"
            :ui="{
              th: { base: 'text-nowrap text-pretty', padding: 'py-2 px-3', size: 'text-xs' },
              td: { base: 'text-wrap text-pretty', padding: 'py-2 px-3', size: 'text-xs' },
            }"
          >
            <template #label-data="{ row }">
              <UBadge
                variant="subtle"
                :icon="row.icon"
                :label="row.label"
                :color="row.color ? row.color : 'gray'"
                size="xs"
                class="text-wrap rounded-md"
              />
            </template>
          </UTable>
        </div>
      </template>
    </UPopover>
  </div>
</template>
